export default [
    0.03628870427796987,
    103.5229295,
    -0.6537399085046361,
    0.26666666666666666,
    0.7185112326269266,
    103.51878849999999,
    0.8773791089707732,
    0.3833333333333333,
    -1.449086619494275,
    103.51464750000001,
    -0.20862866520598178,
    0.5,
    -1.4844301955795014,
    106.03939500000001,
    -0.21371716923539597,
    0.5,
    1.431012044827986,
    103.51050649999999,
    -0.9761524681196404,
    0.5,
    -0.44879305335240904,
    103.5063655,
    1.912200506137805,
    0.5,
    -1.06213498679126,
    103.5022245,
    -1.8939424018257665,
    0.26666666666666666,
    2.2421786359735534,
    103.49808349999998,
    0.7382220474978304,
    0.3833333333333333,
    2.2968659197777863,
    106.02242699999998,
    0.7562274632904604,
    0.5,
    -2.3115683817545185,
    103.4939425,
    1.0421815691930807,
    0.3833333333333333,
    9.221154164875488,
    102.9556125,
    5.7041910572354055,
    0.5,
    9.446060364018793,
    105.466725,
    5.843317668387488,
    0.5,
    -4.50622277601768,
    102.8231005,
    11.15928245272961,
    0.26666666666666666,
    -4.616130648603476,
    105.330981,
    11.431460073527893,
    0.5,
    -2.0802002213413124,
    102.7692675,
    12.311622539396458,
    0.5,
    -2.130936812105735,
    105.275835,
    12.611906015967103,
    0.8333333333333333,
    10.54591601202693,
    102.72785749999998,
    7.293598146797514,
    0.3833333333333333,
    10.803133475734905,
    105.23341499999998,
    7.471490784524283,
    0.8333333333333333,
    0.6331983154946026,
    102.7154345,
    12.905975374587738,
    0.26666666666666666,
    12.977533720420743,
    102.70715249999999,
    0.5000688723437255,
    0.5,
    7.555694365292871,
    102.6947295,
    10.68314750343322,
    0.3833333333333333,
    3.5000200380927478,
    102.66160149999999,
    12.87521115120631,
    0.5,
    9.980714767056579,
    102.6408965,
    9.091607306463825,
    0.5,
    -1.1504155946514434,
    102.6284735,
    13.546165362174653,
    0.5,
    13.455173996473837,
    102.62019149999999,
    2.3409857385173938,
    0.3833333333333333,
    6.371950008342473,
    102.6077685,
    12.18481520342398,
    0.5,
    6.527363423180095,
    105.110397,
    12.482005818141639,
    0.6666666666666666,
    12.049438409771335,
    102.58706349999999,
    6.937655328430271,
    0.3833333333333333,
    1.837613134138793,
    102.57464049999999,
    13.87414607336258,
    0.3833333333333333,
    14.042185235301375,
    102.56635849999999,
    -0.6202925656192554,
    0.5,
    9.094162352776435,
    102.55393549999998,
    10.835688698148564,
    0.26666666666666666,
    11.513674657511402,
    102.5001025,
    8.865094944244763,
    0.5,
    11.794495990621437,
    105.00010499999999,
    9.081316772153173,
    0.8333333333333333,
    -0.0301591475786629,
    102.48767949999998,
    14.61849055583706,
    0.5,
    14.617594155584827,
    102.47939749999999,
    1.313260880254244,
    0.26666666666666666,
    7.897975442427223,
    102.46697449999999,
    12.47263988605324,
    0.26666666666666666,
    8.090608989803496,
    104.966169,
    12.776850614981369,
    0.6666666666666666,
    3.188664568856656,
    102.4338465,
    14.648042001638164,
    0.3833333333333333,
    10.651580028775953,
    102.41314150000001,
    10.747926125144264,
    0.26666666666666666,
    14.892051381571838,
    102.39243649999999,
    3.38251064182998,
    0.5,
    15.25527214697603,
    104.88981299999999,
    3.465010901386809,
    0.6666666666666666,
    14.510861578065018,
    102.37173149999998,
    -5.185471141000066,
    0.26666666666666666,
    13.01330376711841,
    102.3593085,
    8.405445465085776,
    0.5,
    -4.204869836019527,
    102.31375749999998,
    15.219714875559502,
    0.5,
    14.844017798687904,
    102.3054755,
    5.538090222372349,
    0.5,
    15.206067013290049,
    104.800731,
    5.673165593649724,
    0.6666666666666666,
    16.10137820115032,
    102.1646815,
    4.533111406868313,
    0.26666666666666666,
    16.494094742641792,
    104.656503,
    4.6436750997187595,
    0.6666666666666666,
    16.139306117574048,
    102.1439765,
    -4.853502633221191,
    0.5,
    -5.42130179502976,
    102.08600249999999,
    16.324313321460135,
    0.3833333333333333,
    -5.553528668079267,
    104.57590499999999,
    16.72246730491038,
    0.5,
    5.851535953386667,
    101.9245035,
    17.16335451666463,
    0.3833333333333333,
    5.994256342493658,
    104.410467,
    17.581972919510108,
    0.6666666666666666,
    18.553547734656014,
    101.8292605,
    -1.9982986465944754,
    0.5,
    7.558168411119201,
    101.78370949999999,
    17.331364507411724,
    0.26666666666666666,
    5.30622013173796,
    101.6967485,
    18.62903640667672,
    0.3833333333333333,
    5.435640134951081,
    104.177157,
    19.08340314830298,
    0.6666666666666666,
    19.832161227032632,
    101.60150549999999,
    -1.11583430600364,
    0.5,
    20.315872476472453,
    104.079591,
    -1.1430497768817773,
    0.6666666666666666,
    7.081749912786549,
    101.5559545,
    18.805917918747756,
    0.3833333333333333,
    7.254475520415489,
    104.032929,
    19.264598843595262,
    0.5,
    18.014808823060566,
    101.49383949999999,
    -9.58607488061813,
    0.3833333333333333,
    -19.487338697691918,
    101.47727549999999,
    6.327069825641162,
    0.26666666666666666,
    4.608347154660059,
    101.4689935,
    20.00580214835422,
    0.5,
    6.452442463298419,
    101.32819949999998,
    20.208602063132002,
    0.3833333333333333,
    20.733888770234977,
    101.31991749999999,
    -4.6696680028367945,
    0.26666666666666666,
    19.478589172245663,
    101.26608449999999,
    -9.120872704831212,
    0.5,
    -20.844687273492173,
    101.2495205,
    5.608853357897068,
    0.3833333333333333,
    8.351567359622022,
    101.18740549999998,
    20.218454832559946,
    0.3833333333333333,
    5.6825656145022005,
    101.1004445,
    21.536810231306237,
    0.5,
    18.7706559459266,
    100.98449649999999,
    -12.931340495135789,
    0.3833333333333333,
    -20.802771245812597,
    100.96793249999999,
    9.494574249003346,
    0.3833333333333333,
    -8.163306801381134,
    100.8933945,
    21.709652077679518,
    0.5,
    18.114831487562626,
    100.84370249999999,
    -14.826536050902243,
    0.5,
    18.55665664579586,
    103.303305,
    -15.188158881412054,
    0.5,
    -20.48258130006534,
    100.82713849999999,
    11.479443810047185,
    0.5,
    22.23603576775271,
    100.81057449999999,
    -7.759665412578844,
    0.26666666666666666,
    22.778378103551557,
    103.26936899999998,
    -7.948925544592962,
    0.5,
    20.246432885216283,
    100.7567415,
    -12.473436684675573,
    0.5,
    20.74024832144107,
    103.214223,
    -12.777666847716441,
    0.8333333333333333,
    -22.174732149523003,
    100.7401775,
    8.781999565327112,
    0.26666666666666666,
    6.819711838924529,
    100.7318955,
    22.891098366201486,
    0.26666666666666666,
    17.259064398423217,
    100.70290849999999,
    -16.687556463775927,
    0.26666666666666666,
    21.255164593202565,
    100.65735749999999,
    11.564600478748215,
    0.5,
    21.77358324181726,
    103.11241499999998,
    11.846663905059147,
    0.8333333333333333,
    13.397601844378118,
    100.6490755,
    -20.191421218300036,
    0.26666666666666666,
    19.62155132030043,
    100.61594749999999,
    -14.451693930541866,
    0.5,
    23.519317293331127,
    100.5828195,
    -6.882133425474625,
    0.3833333333333333,
    22.66654011788689,
    100.5165635,
    10.00370162663258,
    0.5,
    18.793948929644294,
    100.47515349999999,
    -16.399897493013814,
    0.5,
    23.403545208592266,
    100.44202549999999,
    -9.005509987042847,
    0.5,
    -24.679416388851887,
    100.4254615,
    4.804031038376624,
    0.26666666666666666,
    14.962590480328897,
    100.42132050000001,
    -20.22634173928273,
    0.26666666666666666,
    25.2049880259445,
    100.4088975,
    -0.4330201455916122,
    0.3833333333333333,
    22.935585163719466,
    100.28880849999999,
    11.555926957544372,
    0.5,
    23.494989679907746,
    102.73487699999998,
    11.837778834557648,
    0.5,
    13.533849925527239,
    100.2805265,
    -21.864961378335536,
    0.5,
    -20.62388865396947,
    100.2722445,
    -15.412910974922589,
    0.3833333333333333,
    25.634727026002075,
    100.2681035,
    -2.5678426384120505,
    0.26666666666666666,
    26.084122471775355,
    100.18114249999999,
    0.8847978707689066,
    0.26666666666666666,
    24.427697087504452,
    100.0734765,
    -10.296239177732591,
    0.3833333333333333,
    25.023494577443586,
    102.514293,
    -10.547366962555337,
    0.5,
    23.074574764978703,
    100.06105349999999,
    13.144397927845418,
    0.26666666666666666,
    26.6020623475233,
    100.0403485,
    -1.2979121145251304,
    0.26666666666666666,
    18.12834820859016,
    99.9658105,
    -19.890333011445424,
    0.3833333333333333,
    18.57050304294602,
    102.40400100000001,
    -20.375463084895312,
    0.5,
    -9.2146029193181,
    99.92854150000001,
    25.432328083014188,
    0.26666666666666666,
    26.922890447216847,
    99.8995545,
    -3.558455517312365,
    0.26666666666666666,
    -23.646760588219227,
    99.8622855,
    13.630123748518695,
    0.5,
    -24.223510846468475,
    102.297951,
    13.962565791165492,
    0.6666666666666666,
    23.08415753678669,
    99.8332985,
    14.760074743263068,
    0.26666666666666666,
    27.47485556469709,
    99.8125935,
    0.06415218461637606,
    0.3833333333333333,
    11.767104621427126,
    99.77118349999999,
    -24.99343547489455,
    0.5,
    27.53201723465225,
    99.72563249999999,
    3.769863985047278,
    0.26666666666666666,
    6.169023093948858,
    99.7173505,
    -27.125979951450162,
    0.26666666666666666,
    6.319487071850049,
    102.149481,
    -27.7875892185587,
    0.8333333333333333,
    22.387044854247968,
    99.65109449999999,
    -16.908731846178167,
    0.3833333333333333,
    9.839486646349595,
    99.6303895,
    -26.351387356543448,
    0.5,
    10.079474125528854,
    102.060399,
    -26.99410412133719,
    0.8333333333333333,
    18.35807587556598,
    99.5972615,
    -21.466070363426546,
    0.5,
    28.248962277101167,
    99.58483849999999,
    1.5105284491967308,
    0.3833333333333333,
    13.409903378818123,
    99.54342849999999,
    -25.074009628221635,
    0.5,
    7.764189975699284,
    99.4895955,
    -27.549289759767515,
    0.26666666666666666,
    -28.125617241472508,
    99.46060849999998,
    5.827747128686461,
    0.3833333333333333,
    -28.81160790589867,
    101.88647699999999,
    5.969887302556862,
    0.5,
    16.810754087777543,
    99.4564675,
    -23.307407501086736,
    0.3833333333333333,
    17.22077248016236,
    101.88223500000001,
    -23.87588085477178,
    0.6666666666666666,
    23.880734002843415,
    99.4233395,
    -16.19060626268138,
    0.5,
    11.487844365987417,
    99.40263449999998,
    -26.543762191563253,
    0.26666666666666666,
    -10.467752410605893,
    99.33223749999999,
    27.220550650452452,
    0.26666666666666666,
    15.078147180461611,
    99.31567349999999,
    -25.02942468067807,
    0.5,
    9.410520577363158,
    99.2618405,
    -27.855964316741662,
    0.5,
    24.527577962961953,
    99.23699449999998,
    16.36589340298273,
    0.5,
    3.2295843027678246,
    99.2080075,
    -29.406915141021855,
    0.26666666666666666,
    13.1704984090961,
    99.17487949999999,
    -26.614035259923998,
    0.26666666666666666,
    13.491730077610638,
    101.59377899999998,
    -27.263158071141657,
    0.6666666666666666,
    -24.877593394125924,
    99.1251875,
    16.514483794729568,
    0.3833333333333333,
    7.1918982005240375,
    99.12104649999999,
    -28.99517832361334,
    0.5,
    27.42418463951063,
    99.1086235,
    -11.950751850543876,
    0.26666666666666666,
    24.362398683962926,
    99.0547905,
    -17.66487006362491,
    0.3833333333333333,
    -27.115876109954353,
    99.0382265,
    13.175150075968956,
    0.5,
    24.266442571845694,
    99.00923949999999,
    18.048154574331274,
    0.3833333333333333,
    24.85830702481754,
    101.424099,
    18.488353466388133,
    0.5,
    -20.7890047812491,
    98.99267549999999,
    -22.038446010481373,
    0.3833333333333333,
    4.847370083006604,
    98.98025249999999,
    -29.947257692727355,
    0.5,
    4.965598621616522,
    101.39440499999999,
    -30.67767861206217,
    0.6666666666666666,
    26.751162238701166,
    98.9678295,
    -14.393389730623559,
    0.5,
    -25.181098104345903,
    98.93884249999999,
    -17.15935220279311,
    0.26666666666666666,
    -17.82941582708458,
    98.9057145,
    -24.84345377578156,
    0.26666666666666666,
    -18.264279627745182,
    101.318049,
    -25.44939167275184,
    0.6666666666666666,
    29.941473767075934,
    98.90157349999998,
    6.277940178084092,
    0.3833333333333333,
    -22.877056078219255,
    98.85188149999999,
    -20.551677646945766,
    0.5,
    2.3936387955327216,
    98.8394585,
    -30.699471685084383,
    0.3833333333333333,
    25.852918661624734,
    98.82703550000001,
    -16.800871285655248,
    0.3833333333333333,
    29.10061207744377,
    98.8146125,
    10.30787845257096,
    0.26666666666666666,
    29.810383103722888,
    101.22472499999999,
    10.559290122145862,
    0.8333333333333333,
    -26.906402880507827,
    98.7980485,
    -15.245219631853388,
    0.3833333333333333,
    -20.112526144874113,
    98.76492049999999,
    -23.63053947121045,
    0.26666666666666666,
    28.113028662586327,
    98.74007449999999,
    -13.322196971635337,
    0.26666666666666666,
    -11.735612429293193,
    98.7359335,
    28.82562162023568,
    0.3833333333333333,
    -12.021846878788148,
    101.144127,
    29.528685562192646,
    0.5,
    -30.054421220633724,
    98.7235105,
    8.235645976431387,
    0.5,
    -30.78745588455162,
    101.13140100000001,
    8.43651539049069,
    0.6666666666666666,
    16.60508183153528,
    98.7193695,
    -26.385279378670344,
    0.26666666666666666,
    -27.735587082441196,
    98.66967749999998,
    14.5759245414593,
    0.3833333333333333,
    -6.893059195742851,
    98.64483149999998,
    -30.644878499687245,
    0.3833333333333333,
    25.793717758721925,
    98.64069049999999,
    17.947811199881805,
    0.3833333333333333,
    -22.3100582211329,
    98.62412649999999,
    -22.202896191300344,
    0.26666666666666666,
    4.050928879800531,
    98.61170349999998,
    -31.2529603883998,
    0.3833333333333333,
    4.1497320232103,
    101.01686699999998,
    -32.015227714946136,
    0.6666666666666666,
    27.30072636808087,
    98.59928049999999,
    -15.820172238553369,
    0.5,
    -29.687557882067924,
    98.58271649999999,
    10.840781313147753,
    0.3833333333333333,
    -19.315123789885366,
    98.5371655,
    -25.194813617231105,
    0.5,
    -19.786224370126472,
    100.940511,
    -25.809321266431862,
    0.8333333333333333,
    31.27387691369375,
    98.5330245,
    5.532931471610155,
    0.3833333333333333,
    18.34040091486005,
    98.4916145,
    -26.085574413180755,
    0.3833333333333333,
    30.512616138585464,
    98.4460635,
    9.73542308958895,
    0.26666666666666666,
    31.25682628830706,
    100.84718699999999,
    9.97287243323746,
    0.6666666666666666,
    25.401887610860975,
    98.4129355,
    19.67393852481062,
    0.26666666666666666,
    -21.62079367243649,
    98.3963715,
    -23.83528857823874,
    0.3833333333333333,
    -22.148130103471527,
    100.796283,
    -24.416637080147,
    0.5,
    5.767567313236022,
    98.38394849999999,
    -31.697941097753333,
    0.26666666666666666,
    28.69845131521413,
    98.37152549999999,
    -14.726421422434411,
    0.5,
    -8.140863820200321,
    98.27628249999998,
    -31.510700710094483,
    0.3833333333333333,
    23.5063785097157,
    98.17689850000001,
    -22.938447970136828,
    0.26666666666666666,
    16.085895310137705,
    98.15205249999998,
    28.72010075032005,
    0.5,
    -13.019634711781267,
    98.13962949999998,
    30.274211113991257,
    0.26666666666666666,
    31.873702619008586,
    98.0775145,
    9.07159610307329,
    0.3833333333333333,
    26.90697939136482,
    98.0443865,
    19.51251805562997,
    0.26666666666666666,
    21.80617743196488,
    98.03610449999998,
    -25.116497870339067,
    0.26666666666666666,
    -31.598744045719062,
    97.9864125,
    10.84472061018532,
    0.5,
    15.980233365210449,
    97.9822715,
    -29.35186941735913,
    0.26666666666666666,
    -27.86261965770232,
    97.97398949999999,
    -18.498579195822284,
    0.26666666666666666,
    -28.542195746914572,
    100.363599,
    -18.949764054256974,
    0.6666666666666666,
    25.152166961551973,
    97.94914349999999,
    -22.15309029807404,
    0.5,
    -9.438054007745547,
    97.90773349999999,
    -32.28659911542025,
    0.26666666666666666,
    -9.668250446958854,
    100.295727,
    -33.074077142625626,
    0.5,
    -30.971071529822016,
    97.8456185,
    13.581358313773555,
    0.3833333333333333,
    -31.72646351835426,
    100.232097,
    13.912610955572909,
    0.5,
    13.667868152919283,
    97.84147749999998,
    -30.946086760225594,
    0.3833333333333333,
    -5.086275104673274,
    97.82077249999999,
    -33.50599794160481,
    0.26666666666666666,
    23.520543304364224,
    97.80834949999999,
    -24.448648955583995,
    0.5,
    24.094215092275547,
    100.193919,
    -25.0449574666958,
    0.8333333333333333,
    -27.458075247782027,
    97.79178549999999,
    20.006159486746313,
    0.3833333333333333,
    33.18337566186266,
    97.70896549999999,
    8.321494192962637,
    0.3833333333333333,
    33.992726287761755,
    100.092111,
    8.524457465961726,
    0.5,
    -30.099877245587827,
    97.7048245,
    16.284664080807566,
    0.5,
    28.393447077541534,
    97.67583749999999,
    19.252754523143995,
    0.3833333333333333,
    29.08597017699377,
    100.05817499999999,
    19.722333901757263,
    0.8333333333333333,
    32.00714975528091,
    97.62200449999999,
    12.763707413796444,
    0.5,
    15.523643110858453,
    97.6137225,
    -30.78966237651351,
    0.3833333333333333,
    -29.334528753427072,
    97.6054405,
    -18.168352501405284,
    0.3833333333333333,
    -30.050005064486268,
    99.986061,
    -18.61148305022005,
    0.5,
    25.21201255908595,
    97.58059449999999,
    -23.660254114169245,
    0.5,
    16.336945223951403,
    97.55574849999999,
    30.551690302737086,
    0.26666666666666666,
    1.1887118298889392,
    97.5060565,
    -34.76465928215447,
    0.5,
    1.217704801349645,
    99.884253,
    -35.61257780123141,
    0.5,
    12.20237398173741,
    97.4687875,
    32.68582502384882,
    0.26666666666666666,
    5.854440242655493,
    97.4190955,
    -34.53506893589553,
    0.5,
    19.017471462400714,
    97.4149545,
    29.42937382853577,
    0.26666666666666666,
    31.893924435135848,
    97.3942495,
    14.647981890267191,
    0.26666666666666666,
    -28.89272612028188,
    97.3776855,
    -20.00555845262689,
    0.5,
    -20.329760045232515,
    97.34455750000001,
    -28.777831861216725,
    0.26666666666666666,
    10.478884903333158,
    97.3321345,
    -33.67601802257217,
    0.5,
    15.025886058692478,
    97.3279935,
    31.920370524402593,
    0.26666666666666666,
    -26.1443780106843,
    97.29072449999998,
    -23.84139361907243,
    0.26666666666666666,
    3.0161998000419463,
    97.2783015,
    -35.28824479966842,
    0.3833333333333333,
    -30.781727701553137,
    97.2368915,
    -17.7453598477085,
    0.3833333333333333,
    -22.88475038501373,
    97.2037635,
    -27.297292654248366,
    0.26666666666666666,
    7.7465418706261815,
    97.1913405,
    -34.803161434842316,
    0.26666666666666666,
    17.80277223136345,
    97.18719949999999,
    30.905261351837744,
    0.5,
    31.65886058332,
    97.16649449999998,
    16.54734172449995,
    0.3833333333333333,
    -28.328317011830805,
    97.14993049999998,
    -21.836279081436675,
    0.26666666666666666,
    34.6144583244993,
    97.1126615,
    9.401908119521217,
    0.26666666666666666,
    12.399846838495776,
    97.1043795,
    -33.680987297017104,
    0.3833333333333333,
    13.612165916751229,
    97.10023849999999,
    33.22165328965363,
    0.3833333333333333,
    13.94416996350126,
    99.468537,
    34.03193751623055,
    0.6666666666666666,
    4.898419976203182,
    97.05054649999998,
    -35.70185612525029,
    0.3833333333333333,
    -21.83179035565403,
    96.9760085,
    -28.921485616660483,
    0.3833333333333333,
    9.67766942208321,
    96.9635855,
    -34.95470535623302,
    0.5,
    9.913710139694995,
    99.32855099999999,
    -35.80725914540944,
    0.5,
    16.48057991598783,
    96.9594445,
    32.321544407471606,
    0.5,
    16.88254527979241,
    99.324309,
    33.10987475887335,
    0.6666666666666666,
    -15.637265652734387,
    96.94702149999999,
    32.7746330289807,
    0.3833333333333333,
    -10.611742512512535,
    96.9428805,
    -34.740386673306546,
    0.5,
    -27.641802002308975,
    96.9221755,
    -23.652659542054632,
    0.5,
    1.9541971426887579,
    96.90975249999998,
    -36.36078394254244,
    0.26666666666666666,
    34.66961860718228,
    96.8849065,
    11.347161015812626,
    0.26666666666666666,
    12.10015632746542,
    96.8724835,
    34.44900263277772,
    0.3833333333333333,
    12.395282091549943,
    99.23522700000001,
    35.28922220918693,
    0.8333333333333333,
    -24.394747899836382,
    96.8352145,
    -27.299507919418957,
    0.3833333333333333,
    -24.989741751051902,
    99.197049,
    -27.965349575990153,
    0.6666666666666666,
    19.28979591869774,
    96.8186505,
    31.16854686657437,
    0.3833333333333333,
    19.76027874598305,
    99.180081,
    31.92875532673472,
    0.6666666666666666,
    33.0541026944674,
    96.7979455,
    15.969022106648657,
    0.5,
    -29.802698463153803,
    96.78138149999998,
    -21.50788191026233,
    0.5,
    -30.529593547620966,
    99.14190299999999,
    -22.032464395878485,
    0.6666666666666666,
    -32.207293941481275,
    96.7399715,
    17.930246962778085,
    0.3833333333333333,
    11.640378609177208,
    96.73583049999999,
    -34.987230646484164,
    0.5,
    15.054715356003749,
    96.73168949999999,
    33.6713738511706,
    0.3833333333333333,
    -26.834103423647502,
    96.69442049999999,
    -25.446916564058796,
    0.5,
    3.871176310450825,
    96.6819975,
    -36.810745420099806,
    0.26666666666666666,
    16.30175735851267,
    96.64886949999999,
    -33.32678136868528,
    0.5,
    -4.138727748381887,
    96.6281645,
    -36.922816563552054,
    0.3833333333333333,
    -4.239672327610714,
    98.984949,
    -37.8233730651021,
    0.5,
    -23.34334005326881,
    96.60745949999999,
    -28.974348467451605,
    0.3833333333333333,
    8.79868664692318,
    96.5950365,
    -36.185738376505775,
    0.26666666666666666,
    9.01328876026277,
    98.951013,
    -37.0683173612986,
    0.5,
    -19.519730043118138,
    96.49151149999999,
    32.02801855390306,
    0.5,
    -19.99582101977956,
    98.84496299999999,
    32.809189738144596,
    0.6666666666666666,
    30.216006935494487,
    96.48322949999998,
    22.257694739673294,
    0.26666666666666666,
    -25.906564968680044,
    96.46666549999999,
    -27.21135725822346,
    0.5,
    -26.538432406940533,
    98.81951099999999,
    -27.875048898667938,
    0.6666666666666666,
    27.13833177947374,
    96.39626849999999,
    26.243017976275397,
    0.3833333333333333,
    -32.44288290558062,
    96.3714225,
    19.427658096159707,
    0.3833333333333333,
    -10.356566279496873,
    96.3465765,
    -36.43493319093694,
    0.5,
    32.25701932782349,
    96.34243550000001,
    19.87575032578044,
    0.5,
    23.533693894220907,
    96.3093075,
    29.801143695562292,
    0.3833333333333333,
    11.909015331503689,
    96.27617949999998,
    36.14551479392417,
    0.3833333333333333,
    -33.20641845599169,
    96.2720385,
    -18.61327486983665,
    0.5,
    29.48910587276474,
    96.25547449999999,
    24.139218889697375,
    0.5,
    37.15667518555856,
    96.1478085,
    9.612805771585304,
    0.5,
    -18.959934136021722,
    96.1229625,
    33.44133075673442,
    0.5,
    -19.422371553973473,
    98.467425,
    34.25697297031331,
    0.6666666666666666,
    31.672746855657017,
    96.11468049999999,
    21.822715779673032,
    0.3833333333333333,
    22.312711847804657,
    96.0815525,
    31.43093673733858,
    0.5,
    22.856924331897456,
    98.42500500000001,
    32.1975449504444,
    0.5,
    33.67000584403685,
    95.97388649999999,
    19.307238061091013,
    0.5,
    25.05653233188262,
    95.94075849999999,
    29.748389384065792,
    0.26666666666666666,
    37.15706674488382,
    95.9200535,
    11.667105573936777,
    0.5,
    38.063336665490745,
    98.25956699999999,
    11.9516691245206,
    0.5,
    -34.59304184689424,
    95.90348949999999,
    -17.97964912067014,
    0.5,
    -35.43677457486727,
    98.24259899999998,
    -18.418177148003558,
    0.5,
    30.964813356440875,
    95.88692549999999,
    23.755072657397456,
    0.26666666666666666,
    31.720052706597972,
    98.22563099999999,
    24.33446467343154,
    0.5,
    -26.3812286938545,
    95.8703615,
    -28.81545042961962,
    0.5,
    -31.027642937823035,
    95.8620795,
    23.773361408489155,
    0.5,
    6.821915062272071,
    95.85793849999999,
    -38.49860681132972,
    0.5,
    6.9883032345226095,
    98.195937,
    -39.43759722136216,
    0.8333333333333333,
    27.670797024517952,
    95.79996449999999,
    27.82300161425199,
    0.3833333333333333,
    -34.10514186401799,
    95.7751185,
    19.5292498510866,
    0.5,
    -34.936974592408674,
    98.111097,
    20.005573018186272,
    0.8333333333333333,
    -18.31796817952837,
    95.7544135,
    34.8277183008707,
    0.26666666666666666,
    -18.764747891224186,
    98.089887,
    35.67717484479437,
    0.8333333333333333,
    -10.021251074755055,
    95.75027249999998,
    -38.06422819488745,
    0.26666666666666666,
    33.1098454509243,
    95.7461315,
    21.30356923148361,
    0.5,
    23.84146336058363,
    95.7130035,
    31.432963761550322,
    0.5,
    11.635701306296067,
    95.6798755,
    37.78091455239222,
    0.5,
    30.13388360695416,
    95.6591705,
    25.665108279811925,
    0.3833333333333333,
    26.582366581745564,
    95.5722095,
    29.610068949629376,
    0.3833333333333333,
    27.230716986178383,
    97.903239,
    30.332265753278875,
    0.5,
    38.36942439531372,
    95.55150450000001,
    10.729533277770427,
    0.3833333333333333,
    9.825325538980765,
    95.45212049999999,
    38.855904250274925,
    0.5,
    29.181057221716408,
    95.43141549999999,
    27.545171266290453,
    0.3833333333333333,
    29.8927903246851,
    97.75901099999999,
    28.217004711809732,
    0.5,
    -11.470041699189855,
    95.38172349999999,
    -38.577073380291495,
    0.3833333333333333,
    25.377111907366046,
    95.3444545,
    31.350642933414463,
    0.26666666666666666,
    -22.37818656363311,
    95.2989035,
    33.68621947949811,
    0.26666666666666666,
    -22.923995992014408,
    97.623267,
    34.50783458875416,
    0.8333333333333333,
    -6.255244708028907,
    95.29476249999999,
    -39.965056971200106,
    0.26666666666666666,
    28.10786600714815,
    95.2036605,
    29.38769335835913,
    0.5,
    16.352295726281806,
    95.1705325,
    37.31752662150413,
    0.26666666666666666,
    24.05757623434556,
    95.11669949999998,
    33.03743523573451,
    0.5,
    24.64434638640277,
    97.436619,
    33.84322633904511,
    0.6666666666666666,
    30.683841178807906,
    95.0628665,
    27.184166868176597,
    0.5,
    31.432227549022734,
    97.381473,
    27.847195328376024,
    0.6666666666666666,
    26.91626998113893,
    94.9759055,
    31.185211395106943,
    0.3833333333333333,
    39.49440458963208,
    94.9552005,
    11.879710817829197,
    0.5,
    -35.826499172772444,
    94.8102655,
    21.09267972171496,
    0.26666666666666666,
    25.608652064072714,
    94.7481505,
    32.930389327953804,
    0.26666666666666666,
    -23.815571273116774,
    94.70259949999999,
    34.37530854956354,
    0.26666666666666666,
    -24.396438865144013,
    97.012419,
    35.213730709309,
    0.5,
    -5.660595103916216,
    94.69845849999999,
    -41.44376003445268,
    0.26666666666666666,
    -40.508126433932745,
    94.6363435,
    10.976329384750048,
    0.26666666666666666,
    -0.08403468400493537,
    94.6114975,
    -42.024791539987184,
    0.5,
    28.455613668009665,
    94.60735650000001,
    30.93786954298711,
    0.26666666666666666,
    24.18781146025862,
    94.52039549999999,
    34.61604015895755,
    0.3833333333333333,
    -12.762905912284003,
    94.50797249999998,
    40.283706279505004,
    0.26666666666666666,
    -25.852537943511496,
    94.4748445,
    33.519780183946885,
    0.3833333333333333,
    -3.580210652092738,
    94.4707035,
    -42.18878875840985,
    0.3833333333333333,
    -12.591015002751107,
    94.4168705,
    -40.550542920986,
    0.5,
    -41.51404849206637,
    94.40858849999998,
    9.001545435980905,
    0.5,
    27.16362053090346,
    94.37960149999999,
    32.74223824594581,
    0.3833333333333333,
    27.8261478609255,
    96.68154299999999,
    33.5408294226762,
    0.8333333333333333,
    -7.103392984244846,
    94.3299095,
    -42.05752735044217,
    0.26666666666666666,
    -7.276646471665452,
    96.630639,
    -43.08332070045296,
    0.8333333333333333,
    -34.10101250578124,
    94.3009225,
    25.72707886118334,
    0.5,
    -14.98046663381843,
    94.28021749999999,
    40.05311264540806,
    0.3833333333333333,
    -15.345843868789611,
    96.579735,
    41.03001783188143,
    0.6666666666666666,
    8.867230571636687,
    94.2595125,
    41.880092529169225,
    0.3833333333333333,
    -1.4381489264836702,
    94.2429485,
    -42.82083617459615,
    0.3833333333333333,
    -37.39394693753464,
    94.2139615,
    21.043949627134193,
    0.26666666666666666,
    -38.305994423815974,
    96.511863,
    21.557216691210638,
    0.8333333333333333,
    17.701677154502107,
    94.20567949999999,
    39.107111896189124,
    0.3833333333333333,
    -10.628315100580297,
    94.18911549999999,
    -41.627815994241594,
    0.5,
    -42.415526146295726,
    94.18083349999999,
    6.951213417683318,
    0.26666666666666666,
    -11.706328442047347,
    94.13942349999999,
    41.45065068843386,
    0.3833333333333333,
    -25.25752270378956,
    94.10629549999999,
    34.97839789261703,
    0.26666666666666666,
    -43.15361678155751,
    94.09387249999999,
    1.238604708623199,
    0.3833333333333333,
    0.7590412789501401,
    94.01519349999998,
    -43.33581512437961,
    0.26666666666666666,
    15.86294028544194,
    93.97792449999999,
    40.42205409393249,
    0.3833333333333333,
    16.249841268013697,
    96.27006899999998,
    41.40795785232108,
    0.6666666666666666,
    24.23710044081406,
    93.9240915,
    36.16978881776225,
    0.5,
    -13.966165630809469,
    93.91166849999999,
    41.26706146697273,
    0.5,
    10.375775384191567,
    93.89096349999998,
    42.35865771266216,
    0.3833333333333333,
    -27.319575709527573,
    93.87854049999999,
    34.02772460275961,
    0.5,
    -2.841372689768519,
    93.87439950000001,
    -43.553981962069045,
    0.5,
    -12.159104060963728,
    93.8205665,
    -42.03906653404348,
    0.3833333333333333,
    -12.45566757464577,
    96.108873,
    -43.06440962023966,
    0.8333333333333333,
    -42.958537292844746,
    93.81228449999999,
    8.440673880107363,
    0.26666666666666666,
    -44.00630649510925,
    96.10038899999999,
    8.646543974744128,
    0.5,
    -6.472117863410093,
    93.7336055,
    -43.46893736143657,
    0.3833333333333333,
    -16.234906192761276,
    93.6839135,
    40.95336124467392,
    0.3833333333333333,
    -16.63087951453594,
    95.968887,
    41.95222371405621,
    0.8333333333333333,
    -42.40397050057144,
    93.67149049999999,
    12.040796414485094,
    0.26666666666666666,
    -0.6204670651213273,
    93.6466445,
    -44.12875048663536,
    0.3833333333333333,
    30.272080953349356,
    93.6425035,
    32.12644203777243,
    0.3833333333333333,
    31.010424391235926,
    95.926467,
    32.91001379479126,
    0.5,
    -38.93014773246836,
    93.61765749999999,
    20.91897288924294,
    0.3833333333333333,
    -10.107347338826505,
    93.5928115,
    -43.07728856715317,
    0.3833333333333333,
    -43.813325044030265,
    93.5845295,
    6.305078258814908,
    0.5,
    25.824976152816564,
    93.55554249999999,
    36.02577825997865,
    0.5,
    -12.884780160001219,
    93.54311949999999,
    42.43928438884378,
    0.5,
    -44.44618972571818,
    93.49756849999999,
    0.4080789792089776,
    0.5,
    -43.432092222685526,
    93.4437355,
    9.967310869345818,
    0.3833333333333333,
    1.6515030809800717,
    93.41888949999999,
    -44.582611488789034,
    0.5,
    -40.38077722760085,
    93.3899025,
    19.108756279134603,
    0.26666666666666666,
    15.511278568344393,
    93.38162049999998,
    41.912991004809,
    0.3833333333333333,
    -44.55446168704509,
    93.35677449999999,
    4.103440475233251,
    0.5,
    -45.64115587453399,
    95.63376899999999,
    4.203524389263331,
    0.6666666666666666,
    -15.196858627966716,
    93.31536449999999,
    42.174919811112076,
    0.5,
    -15.567513716453709,
    95.591349,
    43.203576391870904,
    0.6666666666666666,
    -42.7410391851098,
    93.30294149999999,
    13.608464344829203,
    0.26666666666666666,
    -43.78350355547833,
    95.578623,
    13.940378109337232,
    0.6666666666666666,
    -28.786530541523227,
    93.28223649999998,
    34.45556033414662,
    0.26666666666666666,
    19.042904032643722,
    93.2408265,
    40.75465255158507,
    0.3833333333333333,
    27.417295307084594,
    93.18699349999999,
    35.803656035034685,
    0.3833333333333333,
    28.086009826769583,
    95.45984699999998,
    36.676915938328214,
    0.5,
    -11.7387367021372,
    93.1745705,
    43.56750047659869,
    0.5,
    -40.435945804345195,
    93.02135349999999,
    20.721672348922397,
    0.5,
    -45.157923911685835,
    92.9882255,
    5.6015579393185995,
    0.5,
    -14.092610981909012,
    92.94681549999999,
    43.35566201918198,
    0.3833333333333333,
    -14.436333200979965,
    95.21381099999999,
    44.413117190381534,
    0.6666666666666666,
    -43.00544977052087,
    92.93439249999999,
    15.203802343201907,
    0.26666666666666666,
    -45.67885639696866,
    92.9012645,
    -0.47197199721454247,
    0.3833333333333333,
    29.01081809046101,
    92.8184445,
    35.50400567995957,
    0.26666666666666666,
    15.093258832153754,
    92.7853165,
    43.364780696095316,
    0.3833333333333333,
    -45.846339552452385,
    92.7604705,
    3.3217295585088147,
    0.5,
    -16.453006093693276,
    92.7190605,
    43.010464255553345,
    0.5,
    -16.85429892524677,
    94.980501,
    44.05949996910343,
    0.8333333333333333,
    18.737087500149634,
    92.6445225,
    42.22960605734715,
    0.3833333333333333,
    27.431430995419664,
    92.5906895,
    37.30826230820889,
    0.3833333333333333,
    28.100490287990876,
    94.84899899999999,
    38.21821992548227,
    0.5,
    -12.924443021909319,
    92.57826649999998,
    44.49324633501582,
    0.5,
    -13.239673339516864,
    94.83627299999998,
    45.578447465138154,
    0.6666666666666666,
    -5.038108281016203,
    92.5409975,
    -46.13249258010832,
    0.3833333333333333,
    -5.160988970797085,
    94.798095,
    -47.25767532596462,
    0.6666666666666666,
    -46.41282137405764,
    92.5327155,
    0.9860016713365879,
    0.26666666666666666,
    -14.86038759726649,
    92.48716449999999,
    -44.076285099914536,
    0.3833333333333333,
    -45.222700296677694,
    92.4788825,
    10.9539624989344,
    0.26666666666666666,
    -46.325692986840565,
    94.734465,
    11.221132315981581,
    0.6666666666666666,
    6.960853902348781,
    92.47060049999999,
    46.02347423999579,
    0.3833333333333333,
    1.1628329172820449,
    92.45403649999999,
    -46.565272247278365,
    0.5,
    17.090030780939653,
    92.4209085,
    -43.40198319133774,
    0.26666666666666666,
    -46.45101656855647,
    92.3919215,
    4.843709971244288,
    0.26666666666666666,
    -46.85363730347688,
    92.30496049999999,
    -1.3987721500227088,
    0.5,
    -47.996408945025095,
    94.556301,
    -1.4328885439257018,
    0.8333333333333333,
    -12.712129701180391,
    92.25940949999999,
    -45.21093609041596,
    0.5,
    3.573263837723849,
    92.2262815,
    -46.89318086064084,
    0.3833333333333333,
    -43.31447405589875,
    92.19729449999998,
    18.464583643861193,
    0.5,
    14.612113903416413,
    92.18901249999999,
    44.77831759399218,
    0.3833333333333333,
    -31.717010700951615,
    92.0896285,
    35.08499935767006,
    0.26666666666666666,
    18.365780898743363,
    92.04821849999998,
    43.67206415954727,
    0.5,
    -36.241228730013695,
    92.00266749999999,
    30.651396999537784,
    0.26666666666666666,
    -37.12516113806281,
    94.24663499999998,
    31.398992048306997,
    0.8333333333333333,
    -44.616997918426485,
    91.9695395,
    16.381481187764056,
    0.3833333333333333,
    -45.70521737985152,
    94.212699,
    16.781029509416836,
    0.8333333333333333,
    -14.348794632223537,
    91.8908605,
    -45.470951982247,
    0.3833333333333333,
    6.213387718747896,
    91.8742965,
    47.30679740467114,
    0.3833333333333333,
    6.364933760668576,
    94.115133,
    48.46062173161434,
    0.5,
    2.1230011830555813,
    91.8577325,
    -47.69775121465569,
    0.5,
    -43.35834900667185,
    91.8287455,
    20.123635265822802,
    0.5,
    -44.415869714151654,
    94.068471,
    20.614455638159942,
    0.5,
    -12.118048597616987,
    91.66310549999999,
    -46.566657742226504,
    0.5,
    4.59743821272349,
    91.62997750000001,
    -47.96077991682041,
    0.26666666666666666,
    -44.732797186353785,
    91.6009905,
    18.04608046927227,
    0.3833333333333333,
    14.070771022428742,
    91.5927085,
    46.15424983106547,
    0.5,
    7.817174843350743,
    91.50574749999998,
    47.780912265618454,
    0.26666666666666666,
    0.6252988913005488,
    91.48918349999998,
    -48.443409549698984,
    0.26666666666666666,
    17.22751613409349,
    91.4560555,
    -45.347858000466445,
    0.26666666666666666,
    17.931888843916433,
    91.45191449999999,
    45.08237262804739,
    0.3833333333333333,
    18.36925198645098,
    93.68244899999999,
    46.1819426921461,
    0.6666666666666666,
    -48.23841174202562,
    91.42706849999999,
    5.618932559046617,
    0.26666666666666666,
    -47.86872428486371,
    91.28627449999999,
    9.634674267994152,
    0.26666666666666666,
    5.414541615621682,
    91.27799249999998,
    48.543140084492336,
    0.5,
    5.5466036062466015,
    93.50428499999998,
    49.72711911094337,
    0.8333333333333333,
    -35.246194317902216,
    91.2655695,
    33.84859280598455,
    0.5,
    19.692499093802265,
    91.22830049999999,
    -44.799869333763645,
    0.26666666666666666,
    20.172803949748662,
    93.453381,
    -45.89254907361154,
    0.8333333333333333,
    9.633473551498357,
    91.17446749999999,
    -48.08157951257452,
    0.26666666666666666,
    -49.03593906035957,
    91.11235249999999,
    -3.382532687860788,
    0.26666666666666666,
    -27.16370692394664,
    91.0999295,
    -40.992212629290485,
    0.5,
    16.01148347004157,
    91.08750649999999,
    -46.520148126387596,
    0.3833333333333333,
    -37.24831061868027,
    91.03781449999998,
    32.28165596926375,
    0.26666666666666666,
    12.179850459864625,
    90.94671249999999,
    -47.93508478367051,
    0.26666666666666666,
    8.223178215368296,
    90.80591849999998,
    -49.031521803933956,
    0.3833333333333333,
    -47.35340709395871,
    90.77693149999999,
    15.317609769090248,
    0.5,
    -48.50836824259185,
    92.99100299999999,
    15.691210007360743,
    0.6666666666666666,
    -13.15168021006384,
    90.6982525,
    -48.1485817662209,
    0.3833333333333333,
    -36.70635104928481,
    90.6692655,
    33.89916977356789,
    0.5,
    -23.228742170892218,
    90.64441949999998,
    -44.2881516397584,
    0.3833333333333333,
    21.02329963815922,
    90.6319965,
    -45.40140645121208,
    0.26666666666666666,
    15.168556147367998,
    90.6278555,
    47.68576660676921,
    0.26666666666666666,
    10.805375995893487,
    90.57816349999999,
    -48.951667709636176,
    0.5,
    11.068921751890889,
    92.787387,
    -50.145610824505354,
    0.8333333333333333,
    -48.472911197057456,
    90.5491765,
    12.986498383091163,
    0.3833333333333333,
    -49.655177323814954,
    92.757693,
    13.303242246093388,
    0.5,
    -33.95430494704443,
    90.52847149999998,
    37.00171142578142,
    0.3833333333333333,
    -26.94242003784336,
    90.5036255,
    -42.43377662735391,
    0.3833333333333333,
    -27.599552233888318,
    92.711031,
    -43.46874678899668,
    0.8333333333333333,
    19.150718238608768,
    90.48706149999998,
    46.5055622161547,
    0.26666666666666666,
    6.760808184458612,
    90.43736949999999,
    -49.92794103118442,
    0.3833333333333333,
    -30.506564357850774,
    90.36283149999998,
    -40.265789938642904,
    0.26666666666666666,
    -31.250626903164207,
    92.566803,
    -41.247882376170786,
    0.8333333333333333,
    13.399471800909293,
    90.3504085,
    -48.73072402847883,
    0.3833333333333333,
    13.726288186297325,
    92.554077,
    -49.919278273075875,
    0.8333333333333333,
    -49.47255602234599,
    90.32142149999999,
    10.579822485995471,
    0.5,
    -36.090581708541656,
    90.3007165,
    35.50600141561574,
    0.3833333333333333,
    19.838617443999986,
    90.26344749999998,
    -46.6515265379962,
    0.26666666666666666,
    9.376581457260743,
    90.20961449999999,
    -49.917239476324646,
    0.3833333333333333,
    -50.81831135856798,
    90.1474995,
    -2.8901884992401823,
    0.26666666666666666,
    -28.638549920476407,
    90.1350765,
    -42.106140499831234,
    0.3833333333333333,
    -29.337051138049002,
    92.333493,
    -43.13311953641249,
    0.6666666666666666,
    15.997561151159958,
    90.1226535,
    -48.36745794781689,
    0.26666666666666666,
    -12.471100360768842,
    90.10194849999999,
    -49.4321368878079,
    0.5,
    -22.83674888515974,
    90.0481155,
    -45.6863811245191,
    0.3833333333333333,
    -23.393742760407537,
    92.24441099999999,
    -46.80068310316591,
    0.6666666666666666,
    22.37156726571695,
    90.0356925,
    -45.94031648912473,
    0.5,
    22.917215247807608,
    92.231685,
    -47.06081201324972,
    0.5,
    -32.173453351884035,
    89.99428249999998,
    -39.79099950647553,
    0.5,
    28.382467576823643,
    89.9487315,
    -42.67419431918383,
    0.26666666666666666,
    7.898775641685098,
    89.94459049999999,
    50.64588441472446,
    0.26666666666666666,
    8.09142870611644,
    92.13836099999999,
    51.88114988825433,
    0.8333333333333333,
    -35.401734915347966,
    89.93216749999999,
    37.09916493312724,
    0.3833333333333333,
    -36.265191864502796,
    92.12563499999999,
    38.00402261442303,
    0.5,
    -46.032104884216174,
    89.89903949999999,
    22.729576368818716,
    0.5,
    24.88117192104142,
    89.8079375,
    -45.0875511735964,
    0.26666666666666666,
    25.488029772774137,
    91.998375,
    -46.18724754368412,
    0.5,
    -30.333994796422978,
    89.7665275,
    -41.70425548169308,
    0.3833333333333333,
    14.646284608717252,
    89.75410449999998,
    -49.46829992594786,
    0.3833333333333333,
    15.003511062588405,
    91.94322899999999,
    -50.674843826580734,
    0.8333333333333333,
    -14.203049211742607,
    89.73339949999999,
    -49.63482680802076,
    0.26666666666666666,
    30.761405514369077,
    89.7209765,
    -41.488648226557,
    0.3833333333333333,
    5.343420176476898,
    89.71683549999999,
    51.37862311977191,
    0.5,
    5.473747497854383,
    91.90505099999999,
    52.631760269034636,
    0.6666666666666666,
    -24.57373874304386,
    89.67956649999998,
    -45.509695028210245,
    0.3833333333333333,
    -33.83326874328172,
    89.6257335,
    -39.242367993430946,
    0.26666666666666666,
    -18.338906454912614,
    89.59260549999999,
    -48.52087359847649,
    0.3833333333333333,
    -18.786196856251948,
    91.777791,
    -49.704309539902745,
    0.8333333333333333,
    27.35940827281309,
    89.58018249999998,
    -44.09398266462466,
    0.26666666666666666,
    9.612405003943344,
    89.5760415,
    51.00156942909152,
    0.3833333333333333,
    -51.79114071061695,
    89.55119549999999,
    -3.960650630118101,
    0.26666666666666666,
    -53.054339264534434,
    91.73537099999999,
    -4.057251864999031,
    0.8333333333333333,
    17.284297503671603,
    89.5263495,
    -49.02765984634002,
    0.3833333333333333,
    17.70586573546847,
    91.709919,
    -50.22345642795807,
    0.5,
    20.375599843966146,
    89.52220849999998,
    47.833406122511235,
    0.3833333333333333,
    20.872565693818977,
    91.70567699999998,
    49.0000745645237,
    0.6666666666666666,
    -22.38656118580512,
    89.45181149999999,
    -47.05997155378096,
    0.26666666666666666,
    -48.85026192544795,
    89.4435295,
    18.191552033780972,
    0.5,
    23.7356806074184,
    89.43938849999999,
    -46.41808780905016,
    0.26666666666666666,
    -15.956817988937956,
    89.3648505,
    -49.766746726911485,
    0.5,
    29.798282969334675,
    89.35242749999999,
    -42.96081536629285,
    0.3833333333333333,
    30.525070358830643,
    91.53175499999999,
    -44.008640131324384,
    0.6666666666666666,
    -36.84570098586822,
    89.33586349999999,
    37.133858358233645,
    0.26666666666666666,
    -26.316741190301567,
    89.31101749999999,
    -45.259219074541924,
    0.5,
    -47.39946893332844,
    89.30273549999998,
    22.265161172905795,
    0.3833333333333333,
    26.269452205361027,
    89.21163349999999,
    -45.48214984026076,
    0.3833333333333333,
    26.910170551833247,
    91.38752699999999,
    -46.591470568072,
    0.8333333333333333,
    32.189857445662426,
    89.1246725,
    -41.68970441722601,
    0.26666666666666666,
    32.97497591994688,
    91.298445,
    -42.706526476182745,
    0.8333333333333333,
    15.62044884196329,
    89.0666985,
    50.403873078223924,
    0.26666666666666666,
    -33.71071930191144,
    89.0294295,
    -40.678909917207285,
    0.26666666666666666,
    28.76697664395041,
    88.98387849999999,
    -44.40445976322254,
    0.3833333333333333,
    29.46861022063213,
    91.15421699999999,
    -45.48749536720358,
    0.6666666666666666,
    8.784064323900312,
    88.9797375,
    52.181147490110604,
    0.3833333333333333,
    -36.08066624539851,
    88.96731449999999,
    38.73535980143016,
    0.5,
    -36.96068249528628,
    91.13724899999998,
    39.68012467463577,
    0.8333333333333333,
    -52.714067485823314,
    88.9548915,
    -5.067543026289134,
    0.5,
    25.11406139245635,
    88.84308449999999,
    -46.83605322720729,
    0.3833333333333333,
    -52.18613055453495,
    88.76026449999999,
    10.754015465633021,
    0.26666666666666666,
    -19.518024993056546,
    88.62775249999999,
    -49.815598081014386,
    0.5,
    10.548089209478077,
    88.61118850000001,
    52.48066988564799,
    0.5,
    10.805359678001933,
    90.77243700000001,
    53.760686224322335,
    0.5,
    -53.46072579080438,
    88.58634249999999,
    -3.440399016555341,
    0.5,
    -54.764645932043514,
    90.746985,
    -3.524311187690837,
    0.6666666666666666,
    33.621283089534344,
    88.52836849999998,
    -41.83016757247813,
    0.3833333333333333,
    -23.666829764783536,
    88.48695849999999,
    -48.242771161118355,
    0.5,
    -50.13986146207263,
    88.4786765,
    19.363463594422154,
    0.5,
    14.90958815173264,
    88.47039449999998,
    51.65383919090703,
    0.5,
    -3.7181323199910272,
    88.43726649999998,
    53.688462550710256,
    0.5,
    -17.06772379988993,
    88.3999975,
    -51.10341349940007,
    0.5,
    -17.48400974622871,
    90.556095,
    -52.34983821889763,
    0.5,
    7.909511864377336,
    88.3834335,
    53.321983551049115,
    0.26666666666666666,
    -37.04865587917841,
    88.29233149999999,
    -39.360982213866656,
    0.26666666666666666,
    15.778698653818603,
    88.19294749999999,
    -51.86966652152514,
    0.5,
    16.163544962448324,
    90.34399499999999,
    -53.13478033912332,
    0.8333333333333333,
    -50.05810924775409,
    88.11012749999999,
    21.17113023609419,
    0.3833333333333333,
    -48.3407741488892,
    87.96933349999999,
    25.337552037477174,
    0.26666666666666666,
    18.531977729508817,
    87.9651925,
    -51.343123540265175,
    0.3833333333333333,
    35.054383983478736,
    87.9320645,
    -41.911428289986375,
    0.3833333333333333,
    -23.13111165199928,
    87.8906545,
    -49.57428920624452,
    0.26666666666666666,
    -4.850688952774519,
    87.84096249999999,
    54.56978787531638,
    0.3833333333333333,
    -53.59155006991895,
    87.7954115,
    11.719091502716353,
    0.3833333333333333,
    -54.89866104723405,
    89.936763,
    12.004923002782606,
    0.6666666666666666,
    6.990627843297432,
    87.78712949999998,
    54.42404469999216,
    0.26666666666666666,
    7.161130961426638,
    89.92827899999999,
    55.75146042438221,
    0.6666666666666666,
    -20.706427187818534,
    87.6628995,
    -51.02828185789199,
    0.5,
    11.507197672543876,
    87.64633549999999,
    53.88070062783557,
    0.26666666666666666,
    11.7878610304108,
    89.78405099999999,
    55.19486405778278,
    0.6666666666666666,
    -55.06017427965528,
    87.6214895,
    -2.8770490255427,
    0.5,
    17.084488189118094,
    87.5966435,
    -52.463072116011205,
    0.3833333333333333,
    22.843447490340377,
    87.5925025,
    50.231026642340865,
    0.3833333333333333,
    34.07711516314784,
    87.5635155,
    -43.46040267230414,
    0.5,
    -51.348353828920295,
    87.5138235,
    20.545142503055406,
    0.5,
    15.981094231144406,
    87.5055415,
    52.96046127977985,
    0.26666666666666666,
    16.37087701726988,
    89.639823,
    54.252179847579356,
    0.5,
    -3.1590897225408505,
    87.47241349999999,
    55.281304738672326,
    0.26666666666666666,
    -3.2361406913833104,
    89.60588699999998,
    56.6296292444936,
    0.6666666666666666,
    8.79804102339249,
    87.41858049999999,
    54.75410379611489,
    0.3833333333333333,
    20.380709034248373,
    87.3647475,
    51.666751577030176,
    0.26666666666666666,
    36.487877950927604,
    87.33576049999998,
    -41.93477465689845,
    0.3833333333333333,
    -38.593259670876265,
    87.32747849999998,
    -40.023710870037455,
    0.3833333333333333,
    -22.542854581391417,
    87.29435050000001,
    -50.881644068420094,
    0.5,
    13.341812803197977,
    87.2777865,
    54.055616172464816,
    0.26666666666666666,
    -55.704835387411364,
    87.25294050000001,
    -1.149484042891429,
    0.26666666666666666,
    -57.06348990905555,
    89.381061,
    -1.1775202390595128,
    0.5,
    22.636594561626147,
    87.1411335,
    -51.10218257557829,
    0.26666666666666666,
    23.18870662410483,
    89.266527,
    -52.348577272543615,
    0.6666666666666666,
    10.631548434891403,
    87.05003149999999,
    55.015341666898394,
    0.26666666666666666,
    22.22953277358836,
    86.99619849999999,
    51.526060825727406,
    0.3833333333333333,
    -40.24923592494784,
    86.95892949999998,
    -39.186339618187326,
    0.26666666666666666,
    -41.23092460604413,
    89.07987899999998,
    -40.142103999118724,
    0.5,
    15.19652353186012,
    86.90923749999999,
    54.15972428580314,
    0.26666666666666666,
    15.56717044727134,
    89.02897499999999,
    55.480693170822725,
    0.5,
    -56.28631172050899,
    86.88439149999999,
    0.6158340494158281,
    0.26666666666666666,
    -4.308373605485146,
    86.87610949999998,
    56.13737738814995,
    0.26666666666666666,
    -4.4134558885457595,
    88.99503899999999,
    57.50658171469019,
    0.8333333333333333,
    -54.92211513474152,
    86.8305585,
    12.705943676259349,
    0.26666666666666666,
    -56.2616789185157,
    88.948377,
    13.015844741533966,
    0.5,
    21.226602670564294,
    86.7725845,
    -52.31993447297455,
    0.3833333333333333,
    21.74432468691952,
    88.888989,
    -53.59603043573003,
    0.5,
    37.920499242827624,
    86.73945649999999,
    -41.90140866676583,
    0.5,
    -21.90367744284763,
    86.69804649999999,
    -52.16419531214419,
    0.26666666666666666,
    12.488383864393588,
    86.68148249999999,
    55.20676122593815,
    0.26666666666666666,
    -56.594040783368,
    86.6566365,
    -2.2732187130464685,
    0.5,
    16.89866663703707,
    86.6317905,
    -54.09984811119529,
    0.26666666666666666,
    -26.23523172970045,
    86.55725249999999,
    -50.36844528809841,
    0.26666666666666666,
    -26.87511543042485,
    88.66840499999999,
    -51.596943953661786,
    0.6666666666666666,
    9.712239159019912,
    86.4537275,
    56.1146240909956,
    0.26666666666666666,
    -50.6972759526951,
    86.4081765,
    26.092889259741472,
    0.5,
    -51.93379487837059,
    88.515693,
    26.729301192905897,
    0.8333333333333333,
    21.565479832773807,
    86.39989449999999,
    52.79596513911926,
    0.5,
    22.09146714576829,
    88.50720899999999,
    54.08367160592705,
    0.5,
    -40.11824489617296,
    86.3626255,
    -40.613408724181745,
    0.5,
    -41.0967386741284,
    88.46903099999999,
    -41.603979668673986,
    0.8333333333333333,
    14.365759094504567,
    86.3129335,
    55.32746245096767,
    0.26666666666666666,
    14.716143462663215,
    88.418127,
    56.67691275464981,
    0.6666666666666666,
    -57.19747501836636,
    86.2880875,
    -0.49033877645879864,
    0.5,
    -5.4901907340103335,
    86.2798055,
    56.948034150323025,
    0.5,
    -5.624097825083757,
    88.38419099999999,
    58.33701059301384,
    0.8333333333333333,
    22.60034958110844,
    86.17628049999999,
    -52.72853594588999,
    0.26666666666666666,
    23.15157761967206,
    88.27814099999999,
    -54.01459779822876,
    0.6666666666666666,
    39.350998637666045,
    86.14315249999999,
    -41.81245996805437,
    0.26666666666666666,
    40.310779092243266,
    88.244205,
    -42.832276064836186,
    0.6666666666666666,
    -21.215127360470568,
    86.10174249999999,
    -53.42128727897435,
    0.5,
    -21.732569491213752,
    88.20178499999999,
    -54.72424550529079,
    0.8333333333333333,
    -50.33320419073011,
    86.0396275,
    27.94953810987783,
    0.26666666666666666,
    18.23645647075156,
    86.0354865,
    -54.614579949851304,
    0.5,
    -41.781664444986646,
    85.9940765,
    -39.708147125227185,
    0.5,
    -42.80072943144974,
    88.091493,
    -40.676638518525415,
    0.5,
    -18.568644690223262,
    85.8739875,
    -54.756637316567264,
    0.5,
    -19.021538463155537,
    87.968475,
    -56.092165055995736,
    0.5,
    -56.182301454715585,
    85.86570549999999,
    13.71332371231473,
    0.3833333333333333,
    8.750897548236136,
    85.8574235,
    57.1782322832905,
    0.5,
    20.85280390238412,
    85.8035905,
    54.04007819865756,
    0.5,
    13.490380935107513,
    85.71662949999998,
    56.46321523244509,
    0.5,
    -43.421845261640414,
    85.62552749999999,
    -38.7329061576962,
    0.5,
    -58.20946978498848,
    85.55098949999999,
    3.179850298583689,
    0.3833333333333333,
    -59.629212950476,
    87.637599,
    3.2574076229393887,
    0.6666666666666666,
    40.77814350273814,
    85.5468485,
    -41.66899745839997,
    0.3833333333333333,
    -1.9217308106231052,
    85.54270749999998,
    58.27673437368422,
    0.3833333333333333,
    19.59396534274602,
    85.4391825,
    -55.07856425941912,
    0.26666666666666666,
    20.071866936471533,
    87.523065,
    -56.42194387550251,
    0.6666666666666666,
    -4.922998762905478,
    85.31495249999999,
    58.43413889245889,
    0.5,
    -5.043071903464148,
    87.395805,
    59.85936179227496,
    0.6666666666666666,
    22.507837142051162,
    85.2114275,
    -54.312388233248846,
    0.3833333333333333,
    -58.61529930963414,
    85.1824405,
    5.062029465141581,
    0.5,
    12.571914958019798,
    85.12032549999999,
    57.566507329022215,
    0.3833333333333333,
    -43.29415061585791,
    85.0292235,
    -40.163083776503754,
    0.5,
    -59.12551361548237,
    84.9546855,
    2.0738071996930376,
    0.5,
    -19.695798604779945,
    84.90913450000001,
    -55.85642327235836,
    0.3833333333333333,
    9.669411867168035,
    84.8925705,
    58.45664695349537,
    0.3833333333333333,
    22.024263328791644,
    84.8387375,
    55.08852937980952,
    0.3833333333333333,
    -44.938019287289976,
    84.6606745,
    -39.12250299687131,
    0.26666666666666666,
    23.905984572806513,
    84.6151235,
    -54.64622952852727,
    0.3833333333333333,
    19.287367887497037,
    84.6109825,
    56.44824802734839,
    0.3833333333333333,
    -1.247875924325021,
    84.5778545,
    59.686304617175956,
    0.3833333333333333,
    -1.27831192247929,
    86.640729,
    61.14206814442415,
    0.8333333333333333,
    -9.214668324192052,
    84.49089349999998,
    59.10841249980234,
    0.3833333333333333,
    16.468080744334234,
    84.38322749999999,
    57.6688725247974,
    0.26666666666666666,
    -4.317182800898385,
    84.35009949999998,
    59.86525095582352,
    0.26666666666666666,
    -50.150430104164116,
    84.33767649999999,
    33.00782192070082,
    0.5,
    21.243051481524912,
    84.2424335,
    56.29716499746111,
    0.3833333333333333,
    -59.91641101239004,
    84.21758750000001,
    5.9031578052927145,
    0.5,
    40.19683765635214,
    84.21344649999999,
    -44.830071336330796,
    0.3833333333333333,
    -7.407645119958879,
    84.1223445,
    59.88308254089857,
    0.26666666666666666,
    -52.04528972171812,
    84.1099215,
    30.56525066225141,
    0.3833333333333333,
    -53.31468703200393,
    86.16138299999999,
    31.310744580842908,
    0.8333333333333333,
    -59.41769676626728,
    84.0767935,
    10.86534540409412,
    0.26666666666666666,
    -60.8669088825177,
    86.12744699999999,
    11.13035382858422,
    0.5,
    18.437385239968712,
    84.01467849999999,
    57.610955963728145,
    0.26666666666666666,
    20.76313033877322,
    83.87802549999999,
    -57.015742402922946,
    0.26666666666666666,
    -60.20811635827069,
    83.84903849999999,
    7.857931802608991,
    0.26666666666666666,
    -61.67660700115535,
    85.894137,
    8.049588675843356,
    0.6666666666666666,
    39.091315547109524,
    83.8448975,
    -46.4685682694314,
    0.3833333333333333,
    40.04476226777073,
    85.889895,
    -47.601947983319974,
    0.6666666666666666,
    15.552077200762662,
    83.7869235,
    58.781884710739476,
    0.26666666666666666,
    15.931396156878826,
    85.830507,
    60.21558921587946,
    0.5,
    -51.48613187802851,
    83.74137249999998,
    32.46515641506291,
    0.26666666666666666,
    -52.741891192126765,
    85.78384499999999,
    33.25698949835712,
    0.8333333333333333,
    -59.543350573328574,
    83.7082445,
    12.843084918737894,
    0.3833333333333333,
    -46.433777881605266,
    83.6958215,
    -39.45046717190916,
    0.5,
    23.769705596245178,
    83.6502705,
    -56.169911573231644,
    0.5,
    24.349454513226767,
    85.690521,
    -57.539909416481194,
    0.6666666666666666,
    20.417089788091733,
    83.6461295,
    57.47950147012611,
    0.5,
    41.626808377149985,
    83.6171425,
    -44.640877337556105,
    0.26666666666666666,
    -0.5398837904417246,
    83.6130015,
    61.04097091834153,
    0.3833333333333333,
    -8.691593473128146,
    83.52604050000001,
    60.54157568390696,
    0.26666666666666666,
    -8.903583557838587,
    85.56326100000001,
    62.01819948107543,
    0.6666666666666666,
    17.544347282467065,
    83.4183745,
    58.745181072237244,
    0.3833333333333333,
    -3.674785658007842,
    83.3852465,
    61.243956775358164,
    0.3833333333333333,
    -3.764414576495838,
    85.41903299999998,
    62.737711818659584,
    0.6666666666666666,
    9.572584356356039,
    83.3314135,
    60.67674000663241,
    0.5,
    -61.16009027822119,
    83.2527345,
    6.771349971190744,
    0.3833333333333333,
    40.527255347685745,
    83.24859349999998,
    -46.31024594250429,
    0.26666666666666666,
    -6.8315114795589675,
    83.15749149999999,
    61.28284983037328,
    0.3833333333333333,
    6.4940766762535835,
    83.1036585,
    61.39246319409507,
    0.5,
    6.652468790308549,
    85.13057699999999,
    62.88984034517056,
    0.5,
    -10.001325891195185,
    82.92973649999999,
    61.156012864302035,
    0.3833333333333333,
    -10.245260669029214,
    84.95241299999999,
    62.64762293416306,
    0.5,
    -61.4064092967363,
    82.8841855,
    8.768712350356077,
    0.3833333333333333,
    8.496179555767451,
    82.73510950000001,
    61.6452935265878,
    0.3833333333333333,
    23.583419086427853,
    82.6854175,
    -57.6573473379118,
    0.5,
    24.158624429999264,
    84.702135,
    -59.06362410225111,
    0.6666666666666666,
    0.20046070456637907,
    82.64814849999999,
    62.34315511768614,
    0.3833333333333333,
    0.20534999004360782,
    84.663957,
    63.86371987665409,
    0.6666666666666666,
    -8.129584976777801,
    82.5611875,
    61.92726210398124,
    0.26666666666666666,
    38.124625978581236,
    82.5114955,
    -49.55594444006459,
    0.3833333333333333,
    39.054494904888095,
    84.52397099999999,
    -50.76462601177349,
    0.5,
    -51.49492263706835,
    82.4079705,
    35.70189022549753,
    0.26666666666666666,
    -52.75089635992367,
    84.41792099999999,
    36.572668035875516,
    0.6666666666666666,
    -6.218465984119451,
    82.1926385,
    62.63486634625053,
    0.26666666666666666,
    -61.68322164805815,
    82.1470875,
    12.824266348757556,
    0.26666666666666666,
    7.383727596213937,
    82.13880549999999,
    62.57893273952569,
    0.3833333333333333,
    25.014734099939776,
    82.0891135,
    -57.90566593774246,
    0.3833333333333333,
    25.624849565791965,
    84.09128700000001,
    -59.31799925329716,
    0.6666666666666666,
    -9.453029144351527,
    81.9648835,
    62.528583366548986,
    0.5,
    39.5714006771877,
    81.91519149999999,
    -49.41084167431069,
    0.26666666666666666,
    40.53655679126545,
    83.91312299999998,
    -50.61598415417192,
    0.8333333333333333,
    -4.270334592211088,
    81.82408949999999,
    63.27723322780915,
    0.3833333333333333,
    -49.366829966069965,
    81.76611549999998,
    -39.9329948806973,
    0.3833333333333333,
    -50.570898989632646,
    83.76041099999999,
    -40.90697036559236,
    0.6666666666666666,
    23.34946979299869,
    81.7205645,
    -59.109874159798224,
    0.26666666666666666,
    23.91896905624256,
    83.71374899999999,
    -60.55157840759818,
    0.5,
    0.9715041942580167,
    81.6832955,
    63.594976537932766,
    0.5,
    -54.804557187248896,
    81.5839115,
    32.5261667103539,
    0.5,
    -62.67847863908927,
    81.5507835,
    11.760257268672216,
    0.3833333333333333,
    38.27639770244168,
    81.54664249999999,
    -51.014587138403,
    0.5,
    6.236551720704097,
    81.54250149999999,
    63.47717303690738,
    0.5,
    -2.2876089940898,
    81.4555405,
    63.85285735143744,
    0.26666666666666666,
    -50.97879864294562,
    81.3975665,
    -38.63966720612491,
    0.3833333333333333,
    -54.14603171491041,
    81.2153625,
    34.49199571670957,
    0.3833333333333333,
    -55.46666663478627,
    83.196225,
    35.33326390492199,
    0.5,
    24.79110477783335,
    81.12426049999999,
    -59.34375373383983,
    0.5,
    -56.086374911179945,
    80.9876075,
    31.823758510718186,
    0.26666666666666666,
    -63.640917007496036,
    80.95447949999999,
    10.659810332697306,
    0.26666666666666666,
    39.7294313530506,
    80.9503385,
    -50.8531277907137,
    0.26666666666666666,
    40.698441873856716,
    82.924737,
    -52.093447980731106,
    0.5,
    5.055954946873009,
    80.9461975,
    64.33953726027399,
    0.3833333333333333,
    5.179270921186985,
    82.92049499999999,
    65.90879426662214,
    0.8333333333333333,
    -3.574704003033122,
    80.8592365,
    64.54789685905936,
    0.26666666666666666,
    -55.446675588612486,
    80.6190585,
    33.818030660355475,
    0.3833333333333333,
    -56.79903352979815,
    82.585377,
    34.64286067646171,
    0.6666666666666666,
    26.244865512950767,
    80.5279565,
    -59.53050378706059,
    0.5,
    41.18161287299979,
    80.3540345,
    -50.64315872604302,
    0.5,
    3.8432226119340522,
    80.3498935,
    65.16555746323189,
    0.5,
    -54.73213314031053,
    80.25050949999999,
    35.800488151724004,
    0.3833333333333333,
    0.5127818031376908,
    80.12213849999999,
    65.55612558723394,
    0.26666666666666666,
    0.5252886763849517,
    82.076337,
    67.15505547960551,
    0.5,
    39.83580757313517,
    79.9854855,
    -52.27672684211815,
    0.3833333333333333,
    50.454160770552605,
    79.9068065,
    42.26944003545338,
    0.26666666666666666,
    51.68475005763925,
    81.85575299999999,
    43.30040198753761,
    0.6666666666666666,
    -65.76094638674616,
    79.9026655,
    2.913348814441742,
    0.26666666666666666,
    -53.94305228652049,
    79.88196049999999,
    37.76830843035143,
    0.26666666666666666,
    -55.25873648863075,
    81.83030099999999,
    38.68948668475025,
    0.8333333333333333,
    -58.591184788133,
    79.79499949999999,
    30.286246794838306,
    0.3833333333333333,
    42.631697043064236,
    79.7577305,
    -50.38520078076257,
    0.26666666666666666,
    43.671494531919464,
    81.703041,
    -51.614108116878725,
    0.6666666666666666,
    2.5996245552244424,
    79.75358949999999,
    65.95477646870894,
    0.3833333333333333,
    38.42466465419733,
    79.6169365,
    -53.87127428943413,
    0.3833333333333333,
    39.36185159698263,
    81.55881299999999,
    -55.185207808688624,
    0.8333333333333333,
    52.11324085634432,
    79.53825749999999,
    40.93044522494642,
    0.26666666666666666,
    53.38429551137711,
    81.47821499999999,
    41.92874876701828,
    0.6666666666666666,
    -0.7748854687723673,
    79.52583449999999,
    66.27568803557527,
    0.3833333333333333,
    -53.828373413473805,
    79.46786049999999,
    -38.791635544433625,
    0.3833333333333333,
    -55.1412605699,
    81.40610099999999,
    -39.73777299673689,
    0.6666666666666666,
    -57.99314509189467,
    79.42645049999999,
    32.3366619848355,
    0.26666666666666666,
    -59.40761204535551,
    81.36368099999999,
    33.12536105763637,
    0.6666666666666666,
    -66.52227173199365,
    79.3063615,
    1.6474265525714167,
    0.3833333333333333,
    -55.26477910080174,
    79.2856565,
    37.10814586184001,
    0.5,
    -59.81228937295516,
    79.19869549999999,
    29.453052406569935,
    0.5,
    39.8925506291742,
    79.02063249999999,
    -53.6819305595348,
    0.3833333333333333,
    51.85382228936337,
    78.9419535,
    42.38837949942128,
    0.3833333333333333,
    -59.23702286027086,
    78.83014649999998,
    31.53107594802762,
    0.3833333333333333,
    42.752452705233104,
    78.7928775,
    -51.78161708518576,
    0.5,
    -56.570190126336364,
    78.6893525,
    36.40364016414977,
    0.3833333333333333,
    41.35971940841042,
    78.4243285,
    -53.44552305648851,
    0.3833333333333333,
    -3.431382334946034,
    78.3332265,
    67.59849751563357,
    0.26666666666666666,
    -3.5150745870178888,
    80.243793,
    69.24724135747829,
    0.6666666666666666,
    55.123593960893054,
    78.2048555,
    39.53246248412455,
    0.5,
    44.20653528095699,
    78.1965735,
    -51.46361584957476,
    0.26666666666666666,
    49.432631063643186,
    78.11789449999999,
    46.59866060320414,
    0.26666666666666666,
    -57.858266790165715,
    78.09304849999998,
    35.65560776392202,
    0.26666666666666666,
    -62.18730691081684,
    78.00608749999999,
    27.66251610252067,
    0.5,
    -67.94103397824362,
    77.97295949999999,
    4.653935300206311,
    0.26666666666666666,
    -54.8467899814514,
    77.9522545,
    40.40669835680457,
    0.26666666666666666,
    51.19468437137685,
    77.74934549999999,
    45.29425119633542,
    0.3833333333333333,
    -68.34990314165819,
    77.7452045,
    1.183022729758096,
    0.5,
    -4.797707715125194,
    77.73692249999999,
    68.20101543165778,
    0.5,
    -4.91472497646971,
    79.63294499999999,
    69.86445483242991,
    0.8333333333333333,
    54.88763769386727,
    77.60855149999999,
    41.008359970359834,
    0.5,
    0.8516813302107418,
    77.59612849999999,
    68.52401845866352,
    0.5,
    -56.60087211860079,
    77.5381545,
    -38.75050318573849,
    0.5,
    -57.98138119466423,
    79.429329,
    -39.69563740978089,
    0.8333333333333333,
    41.37713370482452,
    77.4594755,
    -54.82141996189058,
    0.5,
    -2.658471639986078,
    77.3683735,
    68.73494697389305,
    0.5,
    -2.7233124116930556,
    79.255407,
    70.4114090952075,
    0.6666666666666666,
    -56.17703016519799,
    77.35595049999999,
    39.719313048691895,
    0.3833333333333333,
    -57.547201632641844,
    79.24268099999999,
    40.688076781586815,
    0.5,
    44.28700686059001,
    77.2317205,
    -52.833209224312064,
    0.26666666666666666,
    50.79592199157004,
    77.15304149999999,
    46.739791626895496,
    0.5,
    52.0348469181937,
    79.03482299999999,
    47.87978654462465,
    0.5,
    -58.3476173176921,
    77.12819549999999,
    36.9353846231759,
    0.5,
    -62.839909987977855,
    77.04123449999999,
    28.86554216395131,
    0.26666666666666666,
    54.605513742894765,
    77.01224749999999,
    42.48266974747792,
    0.26666666666666666,
    -68.96563181202856,
    77.0081065,
    5.560466441978643,
    0.5,
    52.55619543174313,
    76.78449249999998,
    45.38076308589212,
    0.26666666666666666,
    -4.037774520952975,
    76.7720695,
    69.33376772255428,
    0.26666666666666666,
    -69.161183237933,
    76.6395575,
    7.7803974405712095,
    0.3833333333333333,
    45.74471793755899,
    76.6354165,
    -52.45816755934862,
    0.5,
    -57.958720363073034,
    76.5733015,
    -38.661891524668086,
    0.26666666666666666,
    50.351680403992624,
    76.55673749999998,
    48.17883195813571,
    0.5,
    51.579770169943664,
    78.42397499999998,
    49.35392542052926,
    0.6666666666666666,
    -1.85782816714385,
    76.4035205,
    69.8317703821652,
    0.3833333333333333,
    -56.55991726871149,
    76.39109749999999,
    41.02196492492783,
    0.5,
    57.84842131394279,
    76.27514949999998,
    39.40922918856979,
    0.5,
    59.259358419160904,
    78.13551899999999,
    40.37042990048612,
    0.8333333333333333,
    -69.28064307199314,
    76.2710085,
    10.017553739512243,
    0.3833333333333333,
    40.84040793392811,
    76.24202149999999,
    56.891483214835226,
    0.3833333333333333,
    -70.092364223346,
    76.18404749999999,
    0.6906584536155682,
    0.26666666666666666,
    -58.7889694872911,
    76.16334249999998,
    38.21554593159769,
    0.3833333333333333,
    49.86245746761508,
    75.9604335,
    49.610215709895435,
    0.26666666666666666,
    51.0786149668252,
    77.813127,
    50.8202209711124,
    0.6666666666666666,
    42.83176802177295,
    75.8983185,
    -55.877638837030204,
    0.5,
    42.85885984724886,
    75.87347249999999,
    55.890606799225424,
    0.26666666666666666,
    -3.249835719130755,
    75.8072165,
    70.42819122567886,
    0.3833333333333333,
    -3.3291000049632125,
    77.656173,
    72.14595198728078,
    0.6666666666666666,
    -57.88015384058139,
    75.79479349999998,
    40.27980504772819,
    0.3833333333333333,
    -59.29186490986387,
    77.643447,
    41.26223931718498,
    0.6666666666666666,
    -70.10217229517627,
    75.67470449999999,
    8.743578405612805,
    0.26666666666666666,
    -59.297785429205994,
    75.6084485,
    -38.52999848967937,
    0.26666666666666666,
    -60.74407287869882,
    77.45255699999998,
    -39.46975455040326,
    0.8333333333333333,
    60.90381392036331,
    75.5380515,
    36.085368926160456,
    0.3833333333333333,
    -64.6040191361214,
    75.4800775,
    29.09474586679966,
    0.3833333333333333,
    -66.17972691992924,
    77.321055,
    29.804373814770383,
    0.6666666666666666,
    37.19047295453893,
    75.4179625,
    60.385638017382085,
    0.3833333333333333,
    38.0975576607472,
    77.257425,
    61.85845845683043,
    0.6666666666666666,
    42.137836092202825,
    75.2771685,
    57.22915600538992,
    0.3833333333333333,
    -71.11307415485787,
    75.21919449999999,
    1.5585531806307424,
    0.3833333333333333,
    -72.84753937814709,
    77.05380899999999,
    1.5965666728412484,
    0.8333333333333333,
    -59.183720639320654,
    75.19848949999998,
    39.49556953893994,
    0.3833333333333333,
    -70.88937041661988,
    75.0784005,
    7.440199278033928,
    0.5,
    -72.61837945117159,
    76.90958099999999,
    7.621667553107926,
    0.5,
    47.24872406717734,
    75.0742595,
    -53.37451882265916,
    0.5,
    51.19443692541266,
    74.99558049999999,
    49.72140543425906,
    0.26666666666666666,
    60.7245794091072,
    74.94174749999999,
    37.59901012305082,
    0.5,
    44.163451066080974,
    74.9086195,
    56.17574155752724,
    0.5,
    -65.73872712039041,
    74.88377349999999,
    28.076072517208377,
    0.5,
    -71.41560211595909,
    74.85064549999998,
    3.823384318682996,
    0.26666666666666666,
    36.324405094475416,
    74.8216585,
    61.64156583703749,
    0.5,
    -63.32630695199843,
    74.74297949999999,
    33.47673054950046,
    0.26666666666666666,
    58.92127114491329,
    74.71399249999999,
    40.786379544862186,
    0.3833333333333333,
    60.35837531917947,
    76.53628499999999,
    41.78116928985882,
    0.6666666666666666,
    -71.64167101520064,
    74.48209649999998,
    6.108510466099668,
    0.26666666666666666,
    48.70300430544383,
    74.4779555,
    -52.90063460076771,
    0.26666666666666666,
    -0.17822503974826986,
    74.4738145,
    71.9099771565448,
    0.3833333333333333,
    50.65178426261104,
    74.3992765,
    51.15241961325443,
    0.5,
    51.887193634869845,
    76.213893,
    52.40003960382161,
    0.8333333333333333,
    -71.03623261451844,
    74.3413025,
    12.027053817935691,
    0.5,
    -72.09517880755293,
    74.2543415,
    2.4502216447393597,
    0.26666666666666666,
    63.65118727523086,
    74.20464949999999,
    34.05322269676745,
    0.3833333333333333,
    65.20365525755356,
    76.01451899999999,
    34.88378910400568,
    0.5,
    -64.51850876768695,
    74.14667549999999,
    32.51243091021651,
    0.26666666666666666,
    -71.79038146991645,
    74.1135475,
    8.411232393004106,
    0.26666666666666666,
    40.56989467430052,
    74.08456050000001,
    59.858058493723306,
    0.3833333333333333,
    -60.37167295004604,
    74.04729149999999,
    -39.870858427261226,
    0.5,
    62.029146398626864,
    73.97689449999999,
    37.37953587450744,
    0.3833333333333333,
    45.4638972331823,
    73.9437665,
    56.416124194809015,
    0.3833333333333333,
    -66.30771406764694,
    73.9189205,
    29.269538299020954,
    0.3833333333333333,
    -72.35852778331575,
    73.88579249999998,
    4.749605107592418,
    0.26666666666666666,
    -1.5947632104258942,
    73.8775105,
    72.50514324946238,
    0.3833333333333333,
    -1.6336598740948185,
    75.679401,
    74.27356137749805,
    0.6666666666666666,
    50.06556819021528,
    73.8029725,
    52.57362225446472,
    0.5,
    -63.79055501502618,
    73.7781265,
    34.71280406456553,
    0.26666666666666666,
    60.231741698706394,
    73.74913949999998,
    40.627913310307406,
    0.5,
    -71.86093006585789,
    73.7449985,
    10.728818728307356,
    0.26666666666666666,
    63.50497419881857,
    73.60834549999998,
    35.58588694637268,
    0.5,
    -65.68786925956378,
    73.5503715,
    31.50987637789845,
    0.26666666666666666,
    -72.54439107973313,
    73.51724349999999,
    7.067662699396899,
    0.3833333333333333,
    39.72432740059314,
    73.48825649999999,
    61.14637842228019,
    0.26666666666666666,
    16.34581442277251,
    73.45512849999999,
    71.09559812607789,
    0.3833333333333333,
    16.744492823327935,
    75.24671699999999,
    72.82963710476272,
    0.5,
    61.799253724527794,
    73.38059049999998,
    38.90458587316857,
    0.5,
    63.30655259585774,
    75.17036099999999,
    39.85347821153854,
    0.6666666666666666,
    -67.4226504440478,
    73.3226165,
    28.202938529409785,
    0.3833333333333333,
    -73.03941490774073,
    73.28948849999999,
    3.3645758043576373,
    0.5,
    -74.82086405183198,
    75.07703699999999,
    3.4466386288541653,
    0.8333333333333333,
    36.66482536311984,
    73.26050149999999,
    63.293089086095506,
    0.26666666666666666,
    41.84580199761312,
    73.1197075,
    60.16529610407482,
    0.26666666666666666,
    -68.98920372406188,
    73.0948615,
    24.800335014182842,
    0.5,
    18.676047539368223,
    73.0865795,
    70.9012183978346,
    0.26666666666666666,
    19.13156089398696,
    74.869179,
    72.63051640753788,
    0.6666666666666666,
    42.518652003261636,
    73.0037595,
    -59.83344345507531,
    0.5,
    9.06548819924274,
    72.9996185,
    72.84434259133545,
    0.3833333333333333,
    46.75976444817662,
    72.97891349999999,
    56.612968815551035,
    0.5,
    -66.83349537106992,
    72.9540675,
    30.469879499115542,
    0.3833333333333333,
    57.86713845665617,
    72.92508049999999,
    45.28523540743387,
    0.5,
    -73.26326106118988,
    72.92093949999999,
    5.697085756532024,
    0.26666666666666666,
    -0.7299034592234976,
    72.9126575,
    73.48902803975506,
    0.5,
    -0.7477059826191927,
    74.69101500000001,
    75.28144335779785,
    0.8333333333333333,
    15.077584244792892,
    72.8588245,
    71.98391327803299,
    0.26666666666666666,
    15.445330201982962,
    74.635869,
    73.73961847993623,
    0.5,
    -72.68039622560163,
    72.7801455,
    11.748874434956772,
    0.5,
    45.65854994030799,
    72.77600449999998,
    -57.761367810712215,
    0.3833333333333333,
    5.350346995966093,
    72.7718635,
    73.43742434726401,
    0.5,
    5.480843264160388,
    74.546787,
    75.22858103866069,
    0.8333333333333333,
    -68.5119197906691,
    72.7263125,
    27.100294126619133,
    0.26666666666666666,
    21.00915460111386,
    72.71803049999998,
    70.62668821449557,
    0.3833333333333333,
    -73.68382742189446,
    72.69318449999999,
    1.9545149550292902,
    0.3833333333333333,
    35.70976358434866,
    72.6641975,
    64.51474878995438,
    0.5,
    64.77509086775167,
    72.64349249999998,
    35.277984997402555,
    0.5,
    11.416940328909535,
    72.6310695,
    72.88214350451206,
    0.3833333333333333,
    -66.16479428228165,
    72.58551849999999,
    32.72595493900076,
    0.5,
    59.561397453962925,
    72.55653149999999,
    43.65106293116544,
    0.26666666666666666,
    -73.40863449616737,
    72.55239049999999,
    8.04665390976997,
    0.5,
    -61.37148003150648,
    72.48613449999999,
    -41.19135065751441,
    0.26666666666666666,
    53.270236364723566,
    72.46957049999999,
    51.26274372477552,
    0.3833333333333333,
    63.07981697063245,
    72.41573749999999,
    38.65647783079317,
    0.5,
    44.02808578661056,
    72.4074555,
    -59.46523080744227,
    0.3833333333333333,
    46.01169931791126,
    72.3826095,
    57.975054918892795,
    0.5,
    57.42767171589941,
    72.32877649999999,
    46.77644959062131,
    0.5,
    51.62363596080296,
    72.3204945,
    -53.12411797349638,
    0.5,
    3.947515687929501,
    72.1755595,
    74.11161417588683,
    0.3833333333333333,
    -69.57469273205116,
    72.1300085,
    25.96246579257437,
    0.5,
    19.782581121988123,
    72.1217265,
    71.58583432363666,
    0.3833333333333333,
    10.071581095066119,
    72.03476549999999,
    73.66804896700819,
    0.5,
    10.317229414457975,
    73.79171099999999,
    75.46483064913033,
    0.8333333333333333,
    48.05061452991357,
    72.0140605,
    56.76741282285109,
    0.26666666666666666,
    -67.31705364950119,
    71.9892145,
    31.676377769927694,
    0.26666666666666666,
    59.160341652776594,
    71.96022749999999,
    45.15756037116036,
    0.5,
    60.603276815039436,
    73.71535499999999,
    46.25896428265207,
    0.5,
    -74.13047602276052,
    71.95608649999998,
    6.664815469572021,
    0.3833333333333333,
    16.14911824963971,
    71.89397149999999,
    72.71786896434975,
    0.5,
    16.54299918255775,
    73.647483,
    74.49147552445585,
    0.8333333333333333,
    -61.03134617145027,
    71.88983049999999,
    -42.713612352291996,
    0.3833333333333333,
    -62.51991559026613,
    73.64324099999999,
    -43.75540777551863,
    0.5,
    45.53346099935211,
    71.8111515,
    -59.05326472486113,
    0.3833333333333333,
    46.644033218848506,
    73.562643,
    -60.49358825473579,
    0.8333333333333333,
    6.315287806884622,
    71.8070105,
    74.30542381259328,
    0.26666666666666666,
    -69.05006966673297,
    71.7614595,
    28.280849252615834,
    0.3833333333333333,
    -74.59344706418912,
    71.7283315,
    2.8791909120663544,
    0.26666666666666666,
    12.451311431370849,
    71.66621649999999,
    73.66372160849696,
    0.26666666666666666,
    12.755001954087211,
    73.41417299999999,
    75.46039774528957,
    0.8333333333333333,
    60.84488749257274,
    71.59167849999999,
    43.473634085141796,
    0.26666666666666666,
    -74.23507943039431,
    71.58753749999998,
    9.044505605567622,
    0.5,
    42.25766935985194,
    71.5585505,
    61.73401700531385,
    0.5,
    43.28834422228735,
    73.30388099999999,
    63.23972473715077,
    0.5,
    18.524215449255454,
    71.52542249999999,
    72.51477782622008,
    0.26666666666666666,
    18.976025582164123,
    73.26994499999999,
    74.28343094393277,
    0.8333333333333333,
    43.85330482326625,
    71.44260249999999,
    -60.74757467672836,
    0.5,
    44.92289762383372,
    73.185105,
    -62.2292228395754,
    0.6666666666666666,
    47.29528790653211,
    71.4177565,
    58.13850207345338,
    0.26666666666666666,
    -68.44489664085177,
    71.3929105,
    30.59042463193522,
    0.3833333333333333,
    24.49202646741202,
    71.38462849999999,
    70.8646673486624,
    0.5,
    -74.81690231815102,
    71.3597825,
    5.25720401629449,
    0.5,
    -76.64170481371568,
    73.100265,
    5.385428504496795,
    0.8333333333333333,
    14.840058345318088,
    71.29766749999999,
    73.57880742691471,
    0.26666666666666666,
    15.20201098788682,
    73.03663499999999,
    75.37341248610775,
    0.8333333333333333,
    47.03363546338838,
    71.21484749999999,
    -58.597851971286794,
    0.5,
    48.180797303958826,
    72.95179499999999,
    -60.027067873025494,
    0.5,
    54.48707647098969,
    71.12788649999999,
    -51.85950139258611,
    0.26666666666666666,
    -5.146752477545548,
    71.1237455,
    75.04897993967745,
    0.26666666666666666,
    49.33600641590469,
    71.04920750000001,
    56.880525749607436,
    0.5,
    50.539323645560906,
    72.782115,
    58.26785564593933,
    0.6666666666666666,
    60.439354035601106,
    70.99537449999998,
    44.9907424740248,
    0.26666666666666666,
    17.234999164243856,
    70.9291185,
    73.41280935655215,
    0.5,
    -62.30140983212746,
    70.92497749999998,
    -42.492440793084434,
    0.3833333333333333,
    -63.82095641339886,
    72.65485499999998,
    -43.52884178803771,
    0.5,
    -8.99794276493982,
    70.89599049999998,
    74.90140974656471,
    0.26666666666666666,
    45.36930432256346,
    70.84629849999999,
    -60.33120120746415,
    0.3833333333333333,
    23.27822079367906,
    70.78832449999999,
    71.8648952977977,
    0.3833333333333333,
    -75.46738434571523,
    70.76347849999999,
    3.8248706639745222,
    0.26666666666666666,
    -2.787851459210051,
    70.7551965,
    75.52056459990065,
    0.3833333333333333,
    13.501898033642425,
    70.7013635,
    74.4072682856318,
    0.5,
    13.831212619828824,
    72.425787,
    76.22207970723258,
    0.8333333333333333,
    -75.02434915856918,
    70.62268449999999,
    10.060272975747742,
    0.26666666666666666,
    3.4630479328479074,
    70.6144025,
    75.62432864880714,
    0.3833333333333333,
    3.547512516575905,
    72.336705,
    77.46882446950976,
    0.8333333333333333,
    55.849790376066615,
    70.5398645,
    51.20892555278871,
    0.26666666666666666,
    55.90124389788311,
    70.53158249999998,
    -51.164171308788745,
    0.3833333333333333,
    57.264688871002214,
    72.25186499999998,
    -52.41207792607628,
    0.8333333333333333,
    -6.659275013346385,
    70.5274415,
    75.4914543293524,
    0.5,
    34.90845372295725,
    70.50673649999999,
    67.28763327530343,
    0.3833333333333333,
    65.58600652164499,
    70.48603149999998,
    38.047611448338635,
    0.5,
    59.99056898397919,
    70.3990705,
    46.50299055667779,
    0.26666666666666666,
    61.45375359334453,
    72.11612099999999,
    47.637209838547975,
    0.6666666666666666,
    15.915879990917189,
    70.3328145,
    74.2792406640194,
    0.5,
    -10.53477393885537,
    70.29968649999998,
    75.26219662658627,
    0.26666666666666666,
    -10.79171964468111,
    72.01431299999999,
    77.09785995894204,
    0.6666666666666666,
    46.88016099254642,
    70.2499945,
    -59.87164940990768,
    0.5,
    54.50265261526697,
    70.16303349999998,
    -53.141652335784705,
    0.26666666666666666,
    55.83198560588323,
    71.87432699999998,
    -54.43779019763311,
    0.5,
    -4.292104625234345,
    70.1588925,
    76.00482396571606,
    0.26666666666666666,
    -4.3967901038985975,
    71.87008499999999,
    77.85860016000181,
    0.6666666666666666,
    50.615497354729726,
    70.0843545,
    56.95331690825105,
    0.3833333333333333,
    51.850021680454844,
    71.793729,
    58.3424221986962,
    0.6666666666666666,
    2.001293985926453,
    70.0180985,
    76.2291698093728,
    0.3833333333333333,
    2.0501060343636834,
    71.72585699999999,
    78.08841785350386,
    0.5,
    -72.17572583141161,
    69.97254749999999,
    24.737275962990473,
    0.26666666666666666,
    -73.93610938827531,
    71.67919499999999,
    25.340624157209756,
    0.6666666666666666,
    18.33436762940497,
    69.9642655,
    74.06941434678241,
    0.26666666666666666,
    57.302291429938855,
    69.9352785,
    -50.42747111452046,
    0.26666666666666666,
    -8.19006608161473,
    69.93113749999999,
    75.89456140287575,
    0.5,
    -8.389823790922406,
    71.63677499999999,
    77.74564826636053,
    0.6666666666666666,
    45.16714763498008,
    69.8814455,
    -61.59495088357846,
    0.3833333333333333,
    8.302354291736,
    69.8773045,
    75.93193566628453,
    0.26666666666666666,
    8.504850737875902,
    71.581629,
    77.78393409716952,
    0.5,
    24.428498567492127,
    69.8234715,
    72.42483628167014,
    0.26666666666666666,
    25.024315605723643,
    71.526483,
    74.19129570317428,
    0.5,
    -76.30614479989717,
    69.79862549999999,
    4.790591726277043,
    0.5,
    -1.8990590229050763,
    69.79034349999999,
    76.44035029995304,
    0.5,
    14.56781187284593,
    69.7365105,
    75.1134048324316,
    0.5,
    -75.77701867118233,
    69.65783149999999,
    11.093041829223017,
    0.5,
    48.38475248364362,
    69.6536905,
    -59.36922394495951,
    0.3833333333333333,
    49.56486839787883,
    71.352561,
    -60.81725379727559,
    0.5,
    55.92698171327791,
    69.5667295,
    -52.44109541301087,
    0.5,
    -5.815649783350241,
    69.56258849999998,
    76.45031146945763,
    0.3833333333333333,
    36.13489039584373,
    69.54188349999998,
    67.64334232097634,
    0.26666666666666666,
    37.01622918598626,
    71.23802699999999,
    69.29317993856114,
    0.8333333333333333,
    26.81966602165763,
    69.4549225,
    71.93152910932548,
    0.3833333333333333,
    27.473804217307816,
    71.14894499999998,
    73.68595664857732,
    0.5,
    41.66561342590425,
    69.4010895,
    64.53596717378687,
    0.5,
    -73.16129463527847,
    69.37624349999999,
    23.490156025847927,
    0.3833333333333333,
    17.005688516257266,
    69.36796149999999,
    74.94209831878578,
    0.3833333333333333,
    -63.16519763971106,
    69.36382049999999,
    -43.7749224760024,
    0.5,
    -64.70581221628937,
    71.05562099999999,
    -44.84260351200246,
    0.8333333333333333,
    58.68917486059339,
    69.33897449999999,
    -49.6499042745406,
    0.26666666666666666,
    32.721231843515305,
    69.3141285,
    69.58662372847535,
    0.3833333333333333,
    33.519310668966895,
    71.004717,
    71.28385845356011,
    0.6666666666666666,
    38.41812265861729,
    69.17333449999998,
    66.75719639513441,
    0.5,
    62.95392568206138,
    69.0656685,
    44.54393674638111,
    0.26666666666666666,
    2.953939192628692,
    69.05324549999999,
    77.07366057326577,
    0.3833333333333333,
    3.02598649000988,
    70.73747099999999,
    78.95350595310151,
    0.5,
    43.870535655784344,
    69.0325405,
    63.4610910589348,
    0.5,
    -72.69524812592806,
    69.0076945,
    25.899124014229194,
    0.3833333333333333,
    19.44638841794713,
    68.99941249999999,
    74.68831687187212,
    0.26666666666666666,
    19.92069057448243,
    70.68232499999999,
    76.50998313703973,
    0.5,
    35.051678308504506,
    68.9455795,
    68.81360723117409,
    0.5,
    40.67958501742534,
    68.80478549999998,
    65.79132526487861,
    0.3833333333333333,
    31.574724966784032,
    68.71782449999999,
    70.69881869069063,
    0.5,
    46.047054069592434,
    68.6639915,
    62.308512322153526,
    0.5,
    21.88703908804319,
    68.63086349999999,
    74.35178357109423,
    0.26666666666666666,
    58.3877976961742,
    68.6101585,
    50.99938093541442,
    0.26666666666666666,
    11.788675878103465,
    68.5439025,
    76.68237200368488,
    0.5,
    12.076204558057208,
    70.215705,
    78.5526737598723,
    0.6666666666666666,
    51.11638406390024,
    68.5231975,
    58.38760407490481,
    0.3833333333333333,
    62.49932113118848,
    68.46936449999998,
    46.07826611437023,
    0.5,
    42.91645020986884,
    68.43623649999998,
    64.74631618941667,
    0.26666666666666666,
    43.963192897914425,
    70.10541299999998,
    66.32549463306098,
    0.5,
    -73.68854181382694,
    68.4113905,
    24.643580912854148,
    0.3833333333333333,
    18.10864657629353,
    68.4031085,
    75.5679647186036,
    0.3833333333333333,
    -64.40119982704653,
    68.3989675,
    -43.49129028634086,
    0.5,
    -65.9719607984379,
    70.067235,
    -44.55205346405649,
    0.5,
    39.65613176172946,
    68.20848149999999,
    67.02551670938846,
    0.26666666666666666,
    -12.880953294003437,
    68.1422255,
    76.8644506332079,
    0.5,
    -13.195122886540107,
    69.80423099999999,
    78.73919333157882,
    0.6666666666666666,
    45.12590992196216,
    68.06768749999999,
    63.62285593336172,
    0.3833333333333333,
    20.570248520123393,
    68.0345595,
    75.27010837285489,
    0.26666666666666666,
    36.274209547397824,
    67.98072649999999,
    69.13919417554469,
    0.26666666666666666,
    26.756649439076398,
    67.8937655,
    73.42985729269384,
    0.26666666666666666,
    41.92444335153256,
    67.83993249999999,
    66.0114401680465,
    0.5,
    -74.65257337472683,
    67.81508649999999,
    23.356646945324695,
    0.26666666666666666,
    -76.47336784728114,
    69.469113,
    23.92632126106432,
    0.8333333333333333,
    -63.96618420867135,
    67.8026635,
    -45.039446323063196,
    0.3833333333333333,
    -65.52633504302919,
    69.45638699999999,
    -46.13796940411352,
    0.5,
    60.11770691036651,
    67.7778175,
    -50.094454740779085,
    0.5,
    32.77916357763166,
    67.75297149999999,
    71.08084772335692,
    0.5,
    38.59592996099424,
    67.61217749999999,
    68.23762356028031,
    0.26666666666666666,
    12.850517543381715,
    67.5790495,
    77.3651206458297,
    0.5,
    13.163944800537365,
    69.22731900000001,
    79.25207480792311,
    0.6666666666666666,
    63.73273290985274,
    67.50451149999999,
    45.81162852363732,
    0.3833333333333333,
    65.28718981009305,
    69.150963,
    46.928985316896764,
    0.6666666666666666,
    51.212744680540645,
    67.49622949999998,
    -59.48898562397149,
    0.26666666666666666,
    52.461836014212366,
    69.14247899999998,
    -60.939936492848844,
    0.8333333333333333,
    68.53709371559172,
    67.4631015,
    -38.32521814707509,
    0.26666666666666666,
    -65.62167968441393,
    67.43411449999999,
    -43.172456288669366,
    0.26666666666666666,
    -67.22220845720452,
    69.07884899999999,
    -44.225443027417406,
    0.5,
    35.14868252088819,
    67.38442249999999,
    70.29463241519443,
    0.26666666666666666,
    49.45149488496695,
    67.3305895,
    61.14382222452712,
    0.26666666666666666,
    65.23891944458946,
    67.3223075,
    -43.92056383492603,
    0.5,
    -75.58660672700451,
    67.21878249999999,
    22.039187546614592,
    0.5,
    61.483775800288,
    67.1815135,
    -49.23225753699739,
    0.3833333333333333,
    -12.080852318548397,
    67.17737249999999,
    77.83751830739253,
    0.5,
    -12.375507253147138,
    68.815845,
    79.7359943636704,
    0.8333333333333333,
    31.591374963814058,
    67.1566675,
    72.176122528092,
    0.5,
    32.36189630439489,
    68.79463499999999,
    73.93651576048448,
    0.8333333333333333,
    49.507415021073406,
    67.12768049999998,
    -61.32141545676449,
    0.3833333333333333,
    67.56315406503711,
    67.09455249999999,
    -40.63209153623281,
    0.26666666666666666,
    21.705155444662868,
    67.0697065,
    75.8153435798248,
    0.26666666666666666,
    59.019233698068035,
    67.0490015,
    52.331511315323475,
    0.3833333333333333,
    37.49967000480611,
    67.01587349999998,
    69.42674609660634,
    0.3833333333333333,
    51.56212889956458,
    66.96204049999999,
    59.790113065799154,
    0.5,
    27.933019992909358,
    66.9289125,
    73.8761984041076,
    0.26666666666666666,
    28.614313163468122,
    68.561325,
    75.67805690176876,
    0.5,
    0.8960088246847084,
    66.89578449999999,
    79.00365060752348,
    0.5,
    33.98841614127201,
    66.7881185,
    71.42520857818812,
    0.3833333333333333,
    48.55991194511397,
    66.73428549999998,
    62.49716566123034,
    0.3833333333333333,
    49.74430004133626,
    68.36195099999999,
    64.02148677491888,
    0.6666666666666666,
    66.50911927653256,
    66.7260035,
    -42.913903748994386,
    0.26666666666666666,
    68.1312929174236,
    68.353467,
    -43.96058432823815,
    0.6666666666666666,
    24.18294568069286,
    66.7011575,
    75.38943129752906,
    0.26666666666666666,
    24.772773624124394,
    68.328015,
    77.22819791454197,
    0.8333333333333333,
    30.370262232728955,
    66.5603635,
    73.24473228610466,
    0.3833333333333333,
    31.11100033596625,
    68.183787,
    75.03118917113162,
    0.6666666666666666,
    51.01870131333403,
    66.5313765,
    -60.72967711355015,
    0.3833333333333333,
    68.77965623795481,
    66.4982485,
    -39.557141696803775,
    0.5,
    -66.82639763993818,
    66.4692615,
    -42.81933534179953,
    0.26666666666666666,
    50.70598946374943,
    66.3657365,
    61.17121280728951,
    0.3833333333333333,
    65.37568942906488,
    66.3574545,
    -45.16783245801023,
    0.26666666666666666,
    49.26655716767703,
    66.16282749999999,
    -62.5516764527479,
    0.5,
    50.46818051323012,
    67.77655499999999,
    -64.07732709793687,
    0.6666666666666666,
    47.629629373981324,
    66.13798149999998,
    63.83268311611877,
    0.5,
    48.791327651395505,
    67.75110299999999,
    65.38957782626801,
    0.5,
    67.75817525600695,
    66.1296995,
    -41.870253820772895,
    0.5,
    22.850335718615483,
    66.1048535,
    76.32454471721468,
    0.26666666666666666,
    23.407660980045126,
    67.717167,
    78.18611897861015,
    0.8333333333333333,
    60.25860308201978,
    66.0841485,
    52.146061180433776,
    0.3833333333333333,
    38.72741655487698,
    66.05102049999999,
    69.67693680762396,
    0.5,
    12.479900080257382,
    66.01789249999999,
    78.7611299363159,
    0.26666666666666666,
    12.784287887092928,
    67.62808499999998,
    80.68213310549434,
    0.8333333333333333,
    64.4524308590372,
    65.9433545,
    47.061489337337804,
    0.5,
    66.0244413677942,
    67.551729,
    48.2093305406875,
    0.5,
    1.8675704754898363,
    65.93093149999999,
    79.79379723425491,
    0.5,
    69.97308130451495,
    65.90194449999998,
    -38.4464202347977,
    0.3833333333333333,
    71.67974182413725,
    67.50930899999999,
    -39.38413780150008,
    0.5,
    35.20180722112295,
    65.82326549999999,
    71.73253175710032,
    0.3833333333333333,
    49.81073079126656,
    65.7694325,
    62.53557764719147,
    0.5,
    66.6565500244872,
    65.7611505,
    -44.15655159485831,
    0.3833333333333333,
    -12.870437408472476,
    65.6162155,
    79.03347220875564,
    0.3833333333333333,
    -13.184350515996195,
    67.216611,
    80.96111787238384,
    0.5,
    31.566667196755798,
    65.59551049999999,
    73.60842444539654,
    0.26666666666666666,
    50.78942686565553,
    65.56652349999999,
    -61.95878258796065,
    0.5,
    52.02819337457395,
    67.16570699999998,
    -63.4699724071792,
    0.6666666666666666,
    21.48836715905359,
    65.5085495,
    77.22891715053214,
    0.3833333333333333,
    22.012473675128067,
    67.106319,
    79.11254927615488,
    0.5,
    59.594422567673696,
    65.48784450000001,
    53.64021482590616,
    0.3833333333333333,
    37.59032018490884,
    65.45471649999999,
    70.85247731237536,
    0.5,
    65.47554108373811,
    65.3926015,
    -46.413218094136944,
    0.26666666666666666,
    67.07250550041464,
    66.987543,
    -47.545247803750044,
    0.6666666666666666,
    71.14255196770617,
    65.3056405,
    -37.30061987966134,
    0.5,
    -77.8866052438868,
    65.06132149999999,
    20.448148895598926,
    0.5,
    -79.78627854251819,
    66.64818299999999,
    20.946884234515974,
    0.5,
    64.21600754432673,
    65.0240525,
    -48.63746083569963,
    0.3833333333333333,
    30.306718065047328,
    64.99920649999999,
    74.65943757821975,
    0.26666666666666666,
    31.04590631053629,
    66.58455299999999,
    76.48039947037145,
    0.8333333333333333,
    65.65687171341128,
    64.97850150000001,
    46.73751346213394,
    0.5,
    52.30298969814646,
    64.97021949999998,
    -61.32449324501105,
    0.5,
    53.57867237371101,
    66.554859,
    -62.82021259245034,
    0.6666666666666666,
    -67.57941394954557,
    64.9081045,
    -44.0157517878889,
    0.3833333333333333,
    36.41867399146135,
    64.8584125,
    72.00341754169382,
    0.3833333333333333,
    -1.2622850674374904,
    64.73832349999998,
    80.77612104958912,
    0.3833333333333333,
    -12.032623629272504,
    64.65136249999999,
    79.95525574025679,
    0.5,
    32.76834513500891,
    64.6306575,
    73.93604867201542,
    0.26666666666666666,
    50.525733273006445,
    64.60167049999998,
    -63.175945349748744,
    0.5,
    51.758068230884646,
    66.17732099999998,
    -64.71682206559628,
    0.5,
    22.635969101141633,
    64.5436965,
    77.71196671020526,
    0.26666666666666666,
    60.821811941441794,
    64.52299149999999,
    53.43141758414067,
    0.3833333333333333,
    65.53915676741248,
    64.42774849999999,
    -47.65616201958858,
    0.5,
    -78.37678565268187,
    64.09646849999999,
    21.590456724721474,
    0.5,
    64.23245354090008,
    64.05919949999999,
    -49.88022149645402,
    0.26666666666666666,
    65.79909874921472,
    65.621619,
    -51.096812264660215,
    0.5,
    -13.669954933730113,
    64.0550585,
    80.17173715014142,
    0.5,
    31.50263723076413,
    64.0343535,
    74.99740691746555,
    0.26666666666666666,
    52.05113576002248,
    64.0053665,
    -62.541329937029545,
    0.26666666666666666,
    -68.75310079075958,
    63.9432515,
    -43.60845496303899,
    0.3833333333333333,
    37.638365614688404,
    63.893559499999995,
    72.23843930536567,
    0.3833333333333333,
    -0.2907957106646605,
    63.77347049999999,
    81.54928278801899,
    0.26666666666666666,
    72.5249594659587,
    63.744483499999994,
    -37.3389435145034,
    0.5,
    -11.171848282404387,
    63.68650949999999,
    80.84951415229006,
    0.3833333333333333,
    69.28760453869536,
    63.60368949999999,
    -43.25764862168252,
    0.5,
    62.03847037083806,
    63.55813849999999,
    53.1885029819553,
    0.5,
    63.551603794517035,
    65.10833699999999,
    54.48578354249079,
    0.5,
    -4.464347397464378,
    63.545715499999986,
    81.60537524518722,
    0.26666666666666666,
    40.0409611123676,
    63.52501049999999,
    71.2651394383871,
    0.3833333333333333,
    13.168776026427539,
    63.49188249999999,
    80.70185759678634,
    0.5,
    65.56719178541319,
    63.4628955,
    -48.89611314952267,
    0.3833333333333333,
    67.16639158505741,
    65.010771,
    -50.08870127512078,
    0.8333333333333333,
    30.2053037488157,
    63.43804949999999,
    76.03077749225855,
    0.26666666666666666,
    66.30674261968234,
    63.41734449999999,
    47.9487426814877,
    0.26666666666666666,
    53.566265017463216,
    63.40906249999999,
    -61.86575522815503,
    0.3833333333333333,
    54.872759286181825,
    64.955625,
    -63.374676087378326,
    0.8333333333333333,
    45.84943998419176,
    63.38421649999999,
    67.806309269987,
    0.26666666666666666,
    46.96771900819644,
    64.93017299999998,
    69.4601216912062,
    0.5,
    71.56070810187339,
    63.3759345,
    -39.74898246760769,
    0.3833333333333333,
    19.813377038177016,
    63.35108849999999,
    79.44491992071117,
    0.26666666666666666,
    -8.64425364599521,
    63.3179605,
    81.44653695538038,
    0.26666666666666666,
    -8.855089100775581,
    64.862301,
    83.43303785673112,
    0.5,
    42.41810497080126,
    63.15646149999999,
    70.20962445765612,
    0.26666666666666666,
    64.2136618119565,
    63.0943465,
    -51.118829226006504,
    0.3833333333333333,
    65.77984868541886,
    64.633233,
    -52.36562993883593,
    0.8333333333333333,
    32.703687175435824,
    63.0695005,
    75.30028271402261,
    0.26666666666666666,
    70.5140605188213,
    63.00738549999999,
    -42.13386128284945,
    0.3833333333333333,
    72.23391565342669,
    64.54415099999999,
    -43.161516436089684,
    0.8333333333333333,
    -69.91096614430478,
    62.9783985,
    -43.169477181743446,
    0.5,
    38.86024267233215,
    62.92870649999999,
    72.43814646085524,
    0.3833333333333333,
    39.808053469218294,
    64.46355299999999,
    74.2049305208761,
    0.6666666666666666,
    -80.06141079595496,
    62.90386049999999,
    18.72699817263532,
    0.26666666666666666,
    55.07007941426817,
    62.81275849999999,
    -61.14956539442349,
    0.26666666666666666,
    56.413252082908855,
    64.344777,
    -62.64101820892162,
    0.6666666666666666,
    18.36159983754318,
    62.754784499999985,
    80.26278277486082,
    0.5,
    -10.288946295063408,
    62.721656499999995,
    81.71632037135122,
    0.26666666666666666,
    -10.539896204699101,
    64.251453,
    83.70940135601832,
    0.8333333333333333,
    63.24400673509077,
    62.59328549999999,
    52.91220886910017,
    0.5,
    -3.5114587538649826,
    62.580862499999995,
    82.39375541372051,
    0.26666666666666666,
    -3.597104089325104,
    64.107225,
    84.40335920429906,
    0.5,
    41.26808681674421,
    62.560157499999995,
    71.41846630291677,
    0.5,
    65.56027723143018,
    62.4980425,
    -50.13252794350404,
    0.26666666666666666,
    67.15930838341627,
    64.022385,
    -51.35527252749194,
    0.5,
    31.401162529198796,
    62.47319649999999,
    76.34456323725502,
    0.26666666666666666,
    67.48396968762232,
    62.4524915,
    47.57127038079286,
    0.5,
    53.29299963362419,
    62.44420949999999,
    -63.07140806237516,
    0.3833333333333333,
    47.084625858893475,
    62.41936349999999,
    67.85489439224621,
    0.5,
    71.71692249531928,
    62.411081499999995,
    -40.975182230232065,
    0.26666666666666666,
    73.46611572691243,
    63.933302999999995,
    -41.97457691877431,
    0.5,
    20.957090197185146,
    62.38623549999999,
    79.91360094255288,
    0.26666666666666666,
    21.468238738579906,
    63.907850999999994,
    81.86271316066392,
    0.5,
    37.64578706292575,
    62.33240249999999,
    73.5859493381097,
    0.3833333333333333,
    38.56397699128979,
    63.85270499999999,
    75.38072859025871,
    0.5,
    43.648779578901205,
    62.19160849999999,
    70.31652365166543,
    0.3833333333333333,
    44.7133839588744,
    63.70847699999999,
    72.03156081390118,
    0.6666666666666666,
    64.16026924110551,
    62.1294935,
    -52.35276032209104,
    0.5,
    -11.946452446639059,
    62.1253525,
    81.94600951657705,
    0.3833333333333333,
    70.62390263032255,
    62.04253249999999,
    -43.36374249588202,
    0.5,
    -71.05273152837161,
    62.0135455,
    -42.69959182330175,
    0.26666666666666666,
    -5.1463279968428175,
    61.98455849999999,
    82.75781196182623,
    0.3833333333333333,
    40.0836766625176,
    61.96385349999999,
    72.60306708153094,
    0.3833333333333333,
    -80.53778929924812,
    61.939007499999995,
    19.86578641043084,
    0.5,
    -82.50212562362002,
    63.449715,
    20.350317786295005,
    0.5,
    12.749596845000177,
    61.930725499999994,
    81.97230412970964,
    0.5,
    13.060562621707499,
    63.441230999999995,
    83.97162862067816,
    0.5,
    30.067817837692754,
    61.8768925,
    77.36021025063704,
    0.3833333333333333,
    66.90371275420603,
    61.85618749999999,
    49.143981449080144,
    0.26666666666666666,
    54.80899048610591,
    61.847905499999996,
    -62.35572766119421,
    0.5,
    72.89532994221449,
    61.81477749999999,
    -39.78227972654638,
    0.26666666666666666,
    74.67326481885387,
    63.32245499999999,
    -40.752579232071895,
    0.8333333333333333,
    19.498745899904623,
    61.789931499999994,
    80.7417419836621,
    0.5,
    -9.384731699535697,
    61.75680349999999,
    82.5557366715973,
    0.26666666666666666,
    -9.613627594646323,
    63.26306699999999,
    84.56929122456309,
    0.5,
    64.43803235333506,
    61.62843249999999,
    52.6032500804227,
    0.5,
    58.03965659669223,
    61.6201505,
    -59.59681987736752,
    0.26666666666666666,
    15.381534957475054,
    61.5621765,
    81.79811997799716,
    0.26666666666666666,
    65.51902252909076,
    61.5331895,
    -51.364870308310756,
    0.3833333333333333,
    67.11704746882468,
    63.033998999999994,
    -52.617672023147605,
    0.5,
    52.987100850714754,
    61.479356499999994,
    -64.26571008541096,
    0.3833333333333333,
    54.27946916414682,
    62.978852999999994,
    -65.83316642895757,
    0.6666666666666666,
    4.33998355480234,
    61.47521549999998,
    83.1828590935212,
    0.3833333333333333,
    48.31731814821886,
    61.454510499999984,
    67.86903219561327,
    0.5,
    49.49578932256566,
    62.953400999999985,
    69.52437444428676,
    0.8333333333333333,
    71.8386079877378,
    61.4462285,
    -42.20190790130135,
    0.26666666666666666,
    -6.796185505394633,
    61.388254499999995,
    83.08260766862999,
    0.5,
    -81.34113237264741,
    61.34270349999999,
    18.3879998000473,
    0.26666666666666666,
    11.207104010303528,
    61.33442149999999,
    82.64330090190359,
    0.5,
    66.28234867085092,
    61.259883499999994,
    50.70801271241238,
    0.3833333333333333,
    67.89899132135947,
    62.754026999999994,
    51.9447935102761,
    0.6666666666666666,
    56.312737663461135,
    61.25160149999999,
    -61.599858080448556,
    0.26666666666666666,
    57.68621906988702,
    62.74554299999999,
    -63.102293643386325,
    0.8333333333333333,
    0.08684923171319564,
    61.24746049999999,
    83.46356489218448,
    0.26666666666666666,
    44.878682294602555,
    61.22675549999999,
    70.38901840090787,
    0.26666666666666666,
    6.9863514365806,
    61.10666649999999,
    83.27419665095765,
    0.5,
    -72.17811793766231,
    61.048692499999994,
    -42.19955047174499,
    0.26666666666666666,
    -73.9385598385809,
    62.537684999999996,
    -43.22880780032414,
    0.5,
    59.503396556445324,
    61.02384649999999,
    -58.76105497343329,
    0.5,
    66.85824440219776,
    60.93688549999999,
    -50.339812931872146,
    0.3833333333333333,
    68.48893329005624,
    62.42315099999999,
    -51.56761324728366,
    0.5,
    54.51539505647077,
    60.8830525,
    -63.55117028913917,
    0.26666666666666666,
    55.84503883833591,
    62.368005,
    -65.10119883277672,
    0.5,
    2.7303412085861742,
    60.878911499999994,
    83.68828470257867,
    0.3833333333333333,
    9.642502457543792,
    60.73811749999999,
    83.27862183004984,
    0.5,
    65.62016141061392,
    60.66357949999999,
    52.26232068796941,
    0.26666666666666666,
    57.80322656933744,
    60.655297499999996,
    -60.804173445252395,
    0.5,
    65.44401769850649,
    60.5683365,
    -52.59261151626089,
    0.26666666666666666,
    5.387939748412093,
    60.510362499999985,
    83.82655760787314,
    0.5,
    49.546961549312655,
    60.48965749999999,
    67.84928563930225,
    0.26666666666666666,
    -81.79549179529504,
    60.3778505,
    19.530445872162637,
    0.3833333333333333,
    -83.79050379030224,
    61.850480999999995,
    20.006798210508066,
    0.8333333333333333,
    12.305504401563992,
    60.369568499999986,
    83.19564523186834,
    0.26666666666666666,
    12.605638655260675,
    61.841996999999985,
    85.2248073106944,
    0.5,
    67.44988729642014,
    60.295030499999996,
    50.32343018618951,
    0.26666666666666666,
    56.031549330945786,
    60.286748499999995,
    -62.79648923205449,
    0.3833333333333333,
    1.1025371793060144,
    60.2826075,
    84.15591048624684,
    0.3833333333333333,
    46.10723041661257,
    60.2619025,
    70.42763687211072,
    0.3833333333333333,
    47.231797012139715,
    61.731705,
    72.14538411289391,
    0.8333333333333333,
    8.056737848994423,
    60.14181349999999,
    83.87774954756355,
    0.26666666666666666,
    -73.28684654182368,
    60.083839499999996,
    -41.670084951838355,
    0.3833333333333333,
    59.279454719848594,
    60.0589935,
    -59.96906846770571,
    0.5,
    14.972405851356342,
    60.001019499999984,
    83.02487787996373,
    0.5,
    66.79572332427088,
    59.97203249999999,
    -51.56658112000833,
    0.3833333333333333,
    54.18995924942675,
    59.91819949999999,
    -64.73548725562873,
    0.3833333333333333,
    3.7703351258373807,
    59.91405849999999,
    84.34166107030057,
    0.5,
    73.12890831903843,
    59.88507149999998,
    -42.2299254617571,
    0.3833333333333333,
    74.91254022925888,
    61.34568299999998,
    -43.25992364375117,
    0.6666666666666666,
    -7.51552628710083,
    59.827097499999994,
    84.15260479838629,
    0.3833333333333333,
    -7.6988318062984105,
    61.286294999999996,
    86.2051073544445,
    0.5,
    10.733804357091046,
    59.77326449999998,
    83.8413258485142,
    0.3833333333333333,
    66.79001133025491,
    59.6987265,
    51.8900960278801,
    0.5,
    57.53455202127288,
    59.6904445,
    -62.002030483717746,
    0.5,
    -72.82238081559476,
    59.4875355,
    -43.30773138233789,
    0.3833333333333333,
    -74.59853644524341,
    60.938451,
    -44.36401751361443,
    0.8333333333333333,
    60.74043207405977,
    59.462689499999996,
    -59.094958278038575,
    0.3833333333333333,
    -4.870200465815708,
    59.45854849999999,
    84.60725608773703,
    0.3833333333333333,
    74.30646929564708,
    59.288767499999985,
    -40.997759623496535,
    0.5,
    63.798553642651335,
    59.234934499999994,
    -56.021359434473275,
    0.3833333333333333,
    -9.20149642892041,
    59.23079349999999,
    84.40658262143376,
    0.3833333333333333,
    -74.37863931642248,
    59.1189865,
    -41.11190914626017,
    0.5,
    59.02340323000154,
    59.0941405,
    -61.16817844856018,
    0.26666666666666666,
    -2.203584386143761,
    59.0899995,
    84.9761248818934,
    0.3833333333333333,
    16.10091769134473,
    59.03616649999998,
    83.50399463187865,
    0.5,
    49.6832953205002,
    58.92850049999999,
    69.1117039286217,
    0.3833333333333333,
    62.185181381296566,
    58.8663855,
    -58.18227822748639,
    0.5,
    -73.91869448467392,
    58.5226825,
    -42.76152316844703,
    0.26666666666666666,
    60.49711517224193,
    58.4978365,
    -60.29533817515052,
    0.5,
    61.972654566686856,
    59.924612999999994,
    -61.76595617942248,
    0.6666666666666666,
    -3.8799030327180906,
    58.49369549999999,
    85.32795300609281,
    0.26666666666666666,
    68.04440405619908,
    58.410875499999996,
    -51.72576074800852,
    0.5,
    63.6127385163471,
    58.270081499999996,
    -57.23148369065933,
    0.3833333333333333,
    -83.76784722308508,
    58.220389499999996,
    17.628376076036194,
    0.5,
    -75.45321960584477,
    58.1541335,
    -40.52572063488002,
    0.3833333333333333,
    58.735843415007494,
    58.1292875,
    -62.35793660850288,
    0.5,
    60.16842496171499,
    59.547075,
    -63.8788618916371,
    0.5,
    -1.1878409732566886,
    58.12514649999999,
    85.65910344601905,
    0.26666666666666666,
    50.90479759173074,
    57.9636475,
    69.03870490993201,
    0.3833333333333333,
    61.954712520355635,
    57.9015325,
    -59.38393516492094,
    0.3833333333333333,
    -5.570386358939555,
    57.8973915,
    85.64046052134393,
    0.5,
    75.5502662629523,
    57.72761049999999,
    -40.952483179378774,
    0.26666666666666666,
    65.02215282949389,
    57.67377749999999,
    -56.2430498399441,
    0.26666666666666666,
    -9.953999206598375,
    57.669636499999996,
    85.39646685169654,
    0.5,
    -74.99837580732152,
    57.55782949999999,
    -42.18726720839713,
    0.5,
    60.22240636469493,
    57.5329835,
    -61.48693524834587,
    0.5,
    -2.872392608590952,
    57.528842499999996,
    86.02096992194582,
    0.3833333333333333,
    33.499548812017736,
    57.42117649999999,
    79.36002988751383,
    0.5,
    49.77905257985685,
    57.36734349999999,
    70.34528732619444,
    0.3833333333333333,
    50.9931758135119,
    58.766546999999996,
    72.06102604146749,
    0.6666666666666666,
    74.43266898806573,
    57.35906149999998,
    -43.441241650674016,
    0.3833333333333333,
    76.24809993899417,
    58.75806299999998,
    -44.500784129958745,
    0.5,
    63.39523276324687,
    57.305228499999984,
    -58.43441515456915,
    0.26666666666666666,
    -76.51031263343215,
    57.1892805,
    -39.91220216458611,
    0.3833333333333333,
    18.388595363749673,
    57.10646049999999,
    84.36905448510268,
    0.5,
    66.41248748336031,
    57.077473499999996,
    -55.21747142100323,
    0.26666666666666666,
    -11.672005165669923,
    57.0733325,
    85.57934702810613,
    0.5,
    -85.17964199410518,
    57.027781499999996,
    14.480550816330561,
    0.26666666666666666,
    32.09101009548929,
    56.82487249999999,
    80.3649585417049,
    0.26666666666666666,
    32.87371765879391,
    58.21084499999999,
    82.32507948174649,
    0.8333333333333333,
    34.70830904751952,
    56.45632349999999,
    79.53265018170349,
    0.5,
    -85.83228654384882,
    56.43147749999999,
    12.873715812523795,
    0.3833333333333333,
    -87.92575694735733,
    57.80785499999999,
    13.187708881121937,
    0.6666666666666666,
    50.996530187434146,
    56.40249049999999,
    70.25338852496388,
    0.3833333333333333,
    63.146575990034144,
    56.34037549999999,
    -59.62967092189796,
    0.5,
    64.68673638003497,
    57.714530999999994,
    -61.08405313950523,
    0.5,
    -77.54964595619914,
    56.22442749999999,
    -39.27202298539521,
    0.3833333333333333,
    -79.44110073561863,
    57.59575499999999,
    -40.22987720455119,
    0.5,
    19.54637411878915,
    56.141607499999985,
    84.75544107090867,
    0.26666666666666666,
    20.02311495095474,
    57.51091499999998,
    86.82264695068693,
    0.6666666666666666,
    66.22125807695278,
    56.1126205,
    -56.42290691136533,
    0.26666666666666666,
    -10.716697756641322,
    56.1084795,
    86.33896306066502,
    0.3833333333333333,
    -85.61775833465404,
    56.0629285,
    15.619639264485798,
    0.3833333333333333,
    33.296959761224386,
    55.86001949999999,
    80.55058235120974,
    0.5,
    34.10908073101035,
    57.22245899999999,
    82.51523070123925,
    0.6666666666666666,
    75.63815429749043,
    55.79790449999999,
    -43.39226996687213,
    0.3833333333333333,
    77.48298732913653,
    57.15882899999998,
    -44.45061801484462,
    0.6666666666666666,
    64.58227356751236,
    55.74407149999999,
    -58.64430115922501,
    0.5,
    66.15745097159804,
    57.10368299999999,
    -60.074649967986595,
    0.5,
    -77.10667848633894,
    55.62812349999999,
    -40.95726594480034,
    0.3833333333333333,
    17.9674745548209,
    55.54530349999999,
    85.4945304812814,
    0.5,
    -12.453695790527522,
    55.512175499999984,
    86.4912102899144,
    0.3833333333333333,
    -86.28254671376845,
    55.466624499999995,
    14.007188303237783,
    0.26666666666666666,
    -87.43490862581118,
    55.37966349999998,
    2.378076790774362,
    0.3833333333333333,
    -78.57094988135565,
    55.25957449999999,
    -38.60584004811255,
    0.5,
    65.9991287364405,
    55.14776749999999,
    -57.62173522896285,
    0.3833333333333333,
    38.51939275032444,
    55.12292149999999,
    78.70924680398798,
    0.5,
    -14.199770641440686,
    54.91587149999999,
    86.60276667708699,
    0.26666666666666666,
    -86.91121789361887,
    54.8703205,
    12.37391439582406,
    0.26666666666666666,
    23.45069030319513,
    54.80820549999999,
    84.63776556108627,
    0.26666666666666666,
    24.02265835937062,
    56.14499099999999,
    86.70210130647862,
    0.5,
    41.09279549600773,
    54.754372499999995,
    77.65800973147927,
    0.5,
    42.09505880078841,
    56.089845,
    79.55210752980804,
    0.8333333333333333,
    30.389063520935263,
    54.667411499999986,
    82.49487598154343,
    0.26666666666666666,
    31.130260192177587,
    56.000762999999985,
    84.50694612743473,
    0.5,
    37.13337863668575,
    54.52661749999999,
    79.7833679411519,
    0.3833333333333333,
    -81.27625795773855,
    54.522476499999996,
    -33.74751951913042,
    0.26666666666666666,
    74.38226891802206,
    54.46450249999999,
    -47.0990622309462,
    0.5,
    -88.00289783646726,
    54.41481049999999,
    3.4560086275060873,
    0.3833333333333333,
    -79.57395784417507,
    54.29472149999999,
    -37.91429908689971,
    0.3833333333333333,
    49.8550589040022,
    54.2450295,
    72.7280929299167,
    0.26666666666666666,
    21.886428491847422,
    54.21190149999998,
    85.43699203872727,
    0.3833333333333333,
    65.74659054301989,
    54.182914499999995,
    -58.81346132692903,
    0.26666666666666666,
    67.35016592211794,
    55.504448999999994,
    -60.2479359934395,
    0.8333333333333333,
    39.73581920728185,
    54.15806849999999,
    78.77432267735165,
    0.3833333333333333,
    46.149237446547794,
    54.0172745,
    75.29812456956549,
    0.3833333333333333,
    -13.24375037243373,
    53.95101849999999,
    87.35740554689865,
    0.26666666666666666,
    -80.93811964667879,
    53.92617249999999,
    -35.47413610731279,
    0.5,
    75.60018297254497,
    53.86819849999999,
    -45.8285407784529,
    0.5,
    24.635396408996314,
    53.84335249999999,
    84.92005805657669,
    0.26666666666666666,
    25.236259736045007,
    55.156604999999985,
    86.99127898478588,
    0.6666666666666666,
    31.593264177600293,
    53.70255849999998,
    82.67603337218374,
    0.3833333333333333,
    32.36383159656615,
    55.01237699999998,
    84.6925219910175,
    0.6666666666666666,
    -79.14499451870664,
    53.6984175,
    -39.62291541052459,
    0.5,
    -81.0753602386751,
    55.008134999999996,
    -40.589327981512994,
    0.6666666666666666,
    38.34846103789313,
    53.561764499999995,
    79.86212208970991,
    0.3833333333333333,
    -15.006867753539103,
    53.35471449999999,
    87.43851546001498,
    0.26666666666666666,
    -87.94914085296689,
    53.30916349999999,
    11.853579001941036,
    0.5,
    51.06583091979254,
    53.280176499999996,
    72.60116617245212,
    0.3833333333333333,
    52.31133899100699,
    54.579693,
    74.37192632299974,
    0.5,
    23.067382333009604,
    53.24704849999999,
    85.73256862675588,
    0.5,
    -88.79652238075596,
    53.22220249999999,
    0.019978310655840683,
    0.5,
    -88.57563994765992,
    53.08140849999998,
    7.35837648660629,
    0.3833333333333333,
    -88.81763492652331,
    52.99444749999999,
    -4.521270311380389,
    0.5,
    75.53496465659698,
    52.90334549999999,
    -47.04392388220054,
    0.5,
    77.37728086773349,
    54.193670999999995,
    -48.191336659815185,
    0.6666666666666666,
    67.51454812746783,
    52.85779449999999,
    58.01090394692708,
    0.3833333333333333,
    69.16124442325973,
    54.14700899999999,
    59.425804043193594,
    0.6666666666666666,
    43.52748508866067,
    52.82466649999999,
    77.66812908793543,
    0.5,
    -80.13720610150177,
    52.73356449999999,
    -38.91818338989843,
    0.3833333333333333,
    -88.51684522347595,
    52.71285949999999,
    10.175862617806025,
    0.3833333333333333,
    -89.13463911733164,
    52.625898499999984,
    -1.7194596508055742,
    0.5,
    -91.30865470555925,
    53.90945699999998,
    -1.7613976910691247,
    0.5,
    -83.15445197595454,
    52.592770499999986,
    -32.19880793931666,
    0.26666666666666666,
    -85.18260934122172,
    53.875520999999985,
    -32.98414471832438,
    0.6666666666666666,
    28.571724546737713,
    52.50995049999999,
    84.52092805875297,
    0.26666666666666666,
    -89.05506606793367,
    52.485104499999984,
    5.651074080212641,
    0.26666666666666666,
    -89.06427035301043,
    52.398143499999996,
    -6.278210671781826,
    0.26666666666666666,
    -91.23656962991312,
    53.67614699999999,
    -6.43133776133748,
    0.5,
    -14.041444287926918,
    52.389861499999995,
    88.17916919159457,
    0.3833333333333333,
    -81.52403727144348,
    52.36501549999999,
    -36.45767578506694,
    0.3833333333333333,
    -88.36620367532466,
    52.3443105,
    12.996646924904464,
    0.5,
    -89.3610082810916,
    52.25734949999999,
    1.097837520550839,
    0.5,
    -91.54054506843529,
    53.53191899999999,
    1.124614045442323,
    0.5,
    42.16938423306694,
    52.228362499999996,
    78.81222499696706,
    0.3833333333333333,
    -89.04706744030388,
    52.11655549999998,
    8.47997925100027,
    0.5,
    48.57712975420235,
    52.08756849999999,
    75.13037533249249,
    0.26666666666666666,
    -89.43317626190698,
    52.02959449999999,
    -3.4718733951928975,
    0.26666666666666666,
    -91.61447324390471,
    53.298608999999985,
    -3.556553234100041,
    0.5,
    -82.83331270510598,
    51.99646649999999,
    -33.94753898020452,
    0.26666666666666666,
    27.02219187319785,
    51.913646499999984,
    85.39437966541544,
    0.3833333333333333,
    27.681269723763652,
    53.17983299999999,
    87.47716941335241,
    0.6666666666666666,
    68.64286992950706,
    51.892941499999985,
    57.55610007043869,
    0.3833333333333333,
    70.31708626925113,
    53.158622999999984,
    58.959907389229876,
    0.5,
    44.7433230819515,
    51.85981349999999,
    77.62744623088747,
    0.3833333333333333,
    -89.27069406538092,
    51.801839499999986,
    -8.046007115420842,
    0.26666666666666666,
    -81.11108735363482,
    51.768711499999995,
    -38.18923464722472,
    0.3833333333333333,
    -88.94700019480999,
    51.748006499999995,
    11.313735219653612,
    0.26666666666666666,
    -91.11643922395169,
    53.010152999999995,
    11.589679981108578,
    0.5,
    -89.71143448044872,
    51.66104549999999,
    -0.6484799974468828,
    0.3833333333333333,
    -91.89951824826454,
    52.92107099999999,
    -0.6642965827504653,
    0.5,
    29.774940416278863,
    51.54509749999999,
    84.69935933596274,
    0.5,
    -89.53937037288068,
    51.52025149999999,
    6.766865123884697,
    0.26666666666666666,
    -89.69182603301223,
    51.43329049999999,
    -5.236276941346506,
    0.5,
    76.65930899805268,
    51.34218849999999,
    -46.95482561964794,
    0.3833333333333333,
    -89.89835273381189,
    51.292496499999984,
    2.188973171289516,
    0.3833333333333333,
    43.38535563083656,
    51.2635095,
    78.78571657051326,
    0.3833333333333333,
    -80.65674603841744,
    51.1724075,
    -39.9161578079376,
    0.5,
    -82.62398374667153,
    52.420514999999995,
    -40.88972263252145,
    0.5,
    -89.49038107148084,
    51.1517025,
    9.612525788399143,
    0.5,
    49.78647366027384,
    51.12271549999999,
    75.00133749061358,
    0.3833333333333333,
    -90.02232922039681,
    51.06474149999999,
    -2.4079124304403274,
    0.3833333333333333,
    -83.75134303645737,
    51.03161349999999,
    -33.14985050005899,
    0.3833333333333333,
    -85.7940587202734,
    52.27628699999999,
    -33.95838343908482,
    0.6666666666666666,
    69.75923503347359,
    50.928088499999994,
    57.07367654427705,
    0.5,
    -82.06636923813728,
    50.80385849999999,
    -37.43667488682311,
    0.3833333333333333,
    -89.34879382889248,
    50.78315349999999,
    12.461540310792278,
    0.3833333333333333,
    -83.39782650932354,
    50.435309499999995,
    -34.907129847066784,
    0.26666666666666666,
    -89.11437415023407,
    50.414604499999996,
    15.310832698474483,
    0.3833333333333333,
    -91.28789547097148,
    51.644228999999996,
    15.684267642339714,
    0.5,
    76.54810857398274,
    50.377335499999994,
    -48.16572190537778,
    0.5,
    -16.640287605215587,
    50.2324005,
    88.97883115338898,
    0.26666666666666666,
    50.992024418123,
    50.157862499999986,
    74.84268768672489,
    0.26666666666666666,
    52.23573233076015,
    51.381224999999986,
    76.66811909371818,
    0.6666666666666666,
    70.86323524343389,
    49.963235499999996,
    56.56414601322368,
    0.3833333333333333,
    -83.00278739123364,
    49.83900549999999,
    -36.66110256193387,
    0.26666666666666666,
    -89.72232411361611,
    49.8183005,
    13.618631206656639,
    0.3833333333333333,
    -91.9106734822409,
    51.033381,
    13.950792943404362,
    0.8333333333333333,
    77.75567283007973,
    49.78103149999999,
    -46.83299980289123,
    0.26666666666666666,
    67.97607810117782,
    49.735480499999994,
    60.19186332401325,
    0.26666666666666666,
    -90.22572482071865,
    49.644378499999995,
    -10.591500787173192,
    0.26666666666666666,
    -92.42635225537033,
    50.855216999999996,
    -10.849830074665222,
    0.6666666666666666,
    -84.30548793580382,
    49.4704565,
    -34.09815629308489,
    0.26666666666666666,
    -15.656956226593984,
    49.2675475,
    89.69389115237315,
    0.3833333333333333,
    -82.56631530045163,
    49.242701499999995,
    -38.410747866818475,
    0.5,
    -90.29298872263665,
    49.22199649999999,
    11.907849221744318,
    0.3833333333333333,
    52.193247928445125,
    49.193009499999995,
    74.65479430025087,
    0.5,
    -83.92008233464328,
    48.8741525,
    -35.863109504802196,
    0.26666666666666666,
    -90.06768961809355,
    48.8534475,
    14.784369422586277,
    0.26666666666666666,
    -92.26446253560803,
    50.044995,
    15.144963798746916,
    0.8333333333333333,
    77.62955194371493,
    48.8161785,
    -48.04226271392933,
    0.26666666666666666,
    69.09189344104563,
    48.770627499999996,
    59.70897569869577,
    0.3833333333333333,
    73.84651415107956,
    48.62983349999999,
    53.84475152228567,
    0.26666666666666666,
    -22.051341674135344,
    48.44348849999999,
    88.79410102771979,
    0.26666666666666666,
    -22.589179275943522,
    49.625036999999985,
    90.95981080888369,
    0.5,
    75.67070588604332,
    48.26128449999999,
    51.59668898406164,
    0.26666666666666666,
    -90.6524457448987,
    48.257143499999984,
    13.069346069662554,
    0.3833333333333333,
    -92.86348100696941,
    49.43414699999998,
    13.388110607947008,
    0.5,
    53.389617628699035,
    48.2281565,
    74.43802304651042,
    0.26666666666666666,
    78.82438028315697,
    48.219874499999996,
    -46.67961440693898,
    0.3833333333333333,
    68.14869913630986,
    48.1743235,
    61.256958676921485,
    0.3833333333333333,
    -90.97369611420393,
    48.0832215,
    -11.367323805646402,
    0.26666666666666666,
    -19.278153301637932,
    48.07493949999999,
    89.63581104865335,
    0.3833333333333333,
    -19.748352162653493,
    49.24749899999999,
    91.82205034252294,
    0.5,
    73.03254020821667,
    48.03352949999999,
    55.46578891089395,
    0.3833333333333333,
    49.583487614137894,
    48.000401499999995,
    77.16777071328049,
    0.3833333333333333,
    50.792840970580286,
    49.171142999999994,
    79.0499114623849,
    0.8333333333333333,
    77.42481396168782,
    47.89273549999999,
    49.28599896852117,
    0.3833333333333333,
    75.22247853951721,
    47.41237949999999,
    53.01953052904043,
    0.26666666666666666,
    -91.28300878741254,
    47.40823849999999,
    11.717374025108235,
    0.5,
    79.92286489396582,
    47.370969499999994,
    -45.67003985952886,
    0.5,
    81.87220306211132,
    48.52635899999999,
    -46.783943270736884,
    0.6666666666666666,
    -86.31192259465412,
    47.288149499999996,
    -32.11710696622727,
    0.26666666666666666,
    -91.22228326816058,
    47.234316499999984,
    -12.839003276006297,
    0.3833333333333333,
    -20.765974580151024,
    47.22603449999999,
    89.75467447515662,
    0.26666666666666666,
    -84.66634501134254,
    47.0603945,
    -36.52882309799593,
    0.3833333333333333,
    77.01648848054317,
    47.0438305,
    50.72242244819968,
    0.26666666666666666,
    -91.05415138809103,
    47.03968949999999,
    14.623089678251583,
    0.3833333333333333,
    69.5984289125339,
    46.956869499999996,
    60.56844660932732,
    0.5,
    74.4192805205954,
    46.8160755,
    54.65758306562074,
    0.3833333333333333,
    51.16325396412392,
    46.78294749999999,
    76.88369717966744,
    0.5,
    82.52637805363028,
    46.53448749999999,
    41.72965400601393,
    0.26666666666666666,
    80.38702724706414,
    46.306732499999995,
    45.94603356714497,
    0.26666666666666666,
    -91.85487273498192,
    46.26946349999999,
    -11.800200306030368,
    0.3833333333333333,
    83.96978600655409,
    46.16593849999999,
    39.181714937298665,
    0.5,
    -91.34989392090871,
    46.07483649999999,
    15.802909451648238,
    0.5,
    56.1367937402217,
    46.045849499999996,
    73.79610936486526,
    0.3833333333333333,
    70.69326681931622,
    45.99201649999999,
    60.03850489208316,
    0.5,
    81.95940027156553,
    45.93818349999999,
    43.47143463063572,
    0.5,
    52.35882989335847,
    45.8180945,
    76.65951195766526,
    0.3833333333333333,
    53.6358745249038,
    46.935609,
    78.52925615175465,
    0.8333333333333333,
    80.94705809656924,
    45.809812499999985,
    -45.45833795053981,
    0.3833333333333333,
    79.7291525260152,
    45.71042849999999,
    47.65757640532922,
    0.5,
    81.67376600225947,
    46.82531699999999,
    48.81995631765433,
    0.6666666666666666,
    -21.616712777894772,
    45.664877499999996,
    90.35962769172964,
    0.3833333333333333,
    83.4538675366887,
    45.56963449999999,
    40.94247219825915,
    0.26666666666666666,
    -91.93144535871751,
    45.47853249999999,
    14.061936598618905,
    0.3833333333333333,
    -87.9742151861351,
    45.35844349999999,
    -30.34097379190233,
    0.3833333333333333,
    -90.11992775165058,
    46.464746999999996,
    -31.080997542924337,
    0.8333333333333333,
    81.35160166161423,
    45.34187949999999,
    45.204607016293444,
    0.3833333333333333,
    82.07585111977163,
    45.21350849999999,
    -44.00816898128701,
    0.26666666666666666,
    84.86854826422216,
    45.201085499999984,
    38.36166068022973,
    0.3833333333333333,
    86.93851285603245,
    46.303550999999985,
    39.29731094072314,
    0.6666666666666666,
    -86.41275255525656,
    45.130688499999984,
    -34.83507968040219,
    0.26666666666666666,
    79.03096434661207,
    45.114124499999996,
    49.358363729392735,
    0.3833333333333333,
    57.31350212242925,
    45.0809965,
    73.48667807180622,
    0.3833333333333333,
    71.77576218259424,
    45.027163499999986,
    59.48293986725894,
    0.3833333333333333,
    82.89689037957943,
    44.97333049999999,
    42.6957929324865,
    0.3833333333333333,
    53.54924246738713,
    44.853241499999996,
    76.40740136342592,
    0.26666666666666666,
    80.70318977275227,
    44.74557549999999,
    46.92818192383902,
    0.26666666666666666,
    82.67156025501451,
    45.836930999999986,
    48.072771726859486,
    0.6666666666666666,
    -92.52107439764218,
    44.708306499999985,
    -12.587444029985605,
    0.26666666666666666,
    -94.77768596831638,
    45.798752999999984,
    -12.894454859985254,
    0.8333333333333333,
    -20.62884516157029,
    44.70002449999999,
    91.07021566900649,
    0.5,
    84.36309308108147,
    44.60478149999999,
    40.13362204100246,
    0.3833333333333333,
    86.42072949769322,
    45.69270299999999,
    41.112490871270815,
    0.5,
    -92.21459845704045,
    44.513679499999995,
    15.245517169911745,
    0.26666666666666666,
    -94.46373500477314,
    45.59937899999999,
    15.617359052104714,
    0.6666666666666666,
    56.04023920080986,
    44.484692499999994,
    74.81997960769876,
    0.26666666666666666,
    70.81783477851602,
    44.43085949999999,
    61.05864907098802,
    0.5,
    -88.77427080999765,
    44.393590499999995,
    -29.423860889956455,
    0.3833333333333333,
    82.29903291002759,
    44.37702649999999,
    44.44068323161215,
    0.26666666666666666,
    84.30632639563802,
    45.45939299999999,
    45.5246023348222,
    0.8333333333333333,
    52.20679329327673,
    44.256937499999985,
    77.67367534856433,
    0.26666666666666666,
    85.74836626091547,
    44.236232499999986,
    37.52064821921173,
    0.3833333333333333,
    -87.2563889988199,
    44.165835499999986,
    -33.957726344288155,
    0.5,
    -89.38459360854722,
    45.24305099999999,
    -34.78596357219762,
    0.5,
    72.84550860742861,
    44.06231049999999,
    58.902209586115134,
    0.5,
    77.51470606459971,
    43.921516499999996,
    52.723774116227084,
    0.26666666666666666,
    87.0509507438069,
    43.86768349999999,
    34.85948857833597,
    0.26666666666666666,
    -93.11857703449333,
    43.743453499999994,
    -11.526773701451317,
    0.5,
    83.05799961516625,
    43.65235149999999,
    -43.74090229347685,
    0.5,
    85.08380448382884,
    44.71704299999999,
    -44.80775356892751,
    0.6666666666666666,
    -86.83514168432842,
    43.56953149999999,
    -35.76002962153576,
    0.3833333333333333,
    -88.95307196931205,
    44.63220299999999,
    -36.632225465963465,
    0.5,
    79.28546936426656,
    43.552967499999994,
    50.34261608847809,
    0.5,
    81.21926129998037,
    44.615235,
    51.570484773562924,
    0.6666666666666666,
    57.21442075340378,
    43.5198395,
    74.498048650597,
    0.5,
    58.60989443031607,
    44.581299,
    76.31507422744083,
    0.8333333333333333,
    -24.311719708024285,
    43.50741649999999,
    90.73847375470888,
    0.26666666666666666,
    -89.55331353550115,
    43.42873749999999,
    -28.488145235664604,
    0.3833333333333333,
    76.69722062617662,
    43.3252125,
    54.38646830829736,
    0.5,
    78.56788454388824,
    44.381924999999995,
    55.712967535329,
    0.8333333333333333,
    53.39533666728127,
    43.29208449999998,
    77.40903737182465,
    0.3833333333333333,
    54.697661951849106,
    44.347988999999984,
    79.29706267357648,
    0.8333333333333333,
    86.60897530253743,
    43.271379499999995,
    36.6592244627657,
    0.3833333333333333,
    -88.07998934642767,
    43.20098249999999,
    -33.06079570681693,
    0.5,
    -93.14883701549155,
    43.14714949999999,
    -13.38070542557605,
    0.3833333333333333,
    73.90210648939826,
    43.09745749999999,
    58.29677039481118,
    0.5,
    75.70459689157872,
    44.14861499999999,
    59.71864284346512,
    0.8333333333333333,
    78.51668843336036,
    42.95666349999999,
    52.03153201094875,
    0.26666666666666666,
    80.43172961466183,
    44.004386999999994,
    53.30059376731335,
    0.8333333333333333,
    80.42183741734114,
    42.915253499999984,
    -49.07173023696524,
    0.5,
    82.38334564703239,
    43.96196699999999,
    -50.268601706159515,
    0.6666666666666666,
    87.88041060375626,
    42.902830499999986,
    33.9677227997915,
    0.5,
    90.02383525262837,
    43.94924099999999,
    34.79620384368885,
    0.8333333333333333,
    75.84059065983158,
    42.72890849999998,
    56.03455016118621,
    0.5,
    -87.67044887068404,
    42.60467849999999,
    -34.87347688035156,
    0.5,
    -23.324646074997347,
    42.54256349999999,
    91.45226515141425,
    0.26666666666666666,
    -90.31113627376875,
    42.463884499999985,
    -27.534393095026257,
    0.26666666666666666,
    77.70834903073877,
    42.36035949999999,
    53.706964715459776,
    0.3833333333333333,
    87.4501168692486,
    42.30652649999999,
    35.7779331440423,
    0.3833333333333333,
    -88.88331772260996,
    42.23612949999998,
    -32.14484127322457,
    0.26666666666666666,
    -93.73613779973972,
    42.182296499999985,
    -12.313243170436095,
    0.3833333333333333,
    -96.02238506314801,
    43.21113299999999,
    -12.613566174593073,
    0.6666666666666666,
    84.01263420809862,
    42.09119449999999,
    -43.44691316974528,
    0.3833333333333333,
    86.06172284732054,
    43.11780899999999,
    -44.506593978763455,
    0.8333333333333333,
    79.50316254699719,
    41.9918105,
    51.31628027203289,
    0.26666666666666666,
    81.4422640725337,
    43.016001,
    52.56789686403369,
    0.5,
    57.08441020997749,
    41.95868249999999,
    75.48685116524078,
    0.26666666666666666,
    58.47671289802572,
    42.982064999999984,
    77.32799387658811,
    0.6666666666666666,
    80.19157174434729,
    41.95040049999999,
    -50.26829362591099,
    0.3833333333333333,
    82.14746373811185,
    42.973580999999996,
    -51.494349568006385,
    0.6666666666666666,
    88.6897828902681,
    41.93797749999999,
    33.05683105027767,
    0.26666666666666666,
    86.21359283156016,
    41.86343949999999,
    -39.141978351940736,
    0.5,
    -88.48585256339115,
    41.639825499999986,
    -33.9676972057182,
    0.26666666666666666,
    81.2228865488289,
    41.6232615,
    48.86483835825802,
    0.26666666666666666,
    59.540991710143985,
    41.5901335,
    73.77368586850426,
    0.5,
    -91.047537888414,
    41.499031499999994,
    -26.563167243757366,
    0.26666666666666666,
    85.08040779105899,
    41.4948905,
    -41.915676035346486,
    0.3833333333333333,
    87.15553968840189,
    42.506961,
    -42.938009597184205,
    0.5,
    -93.83985454338489,
    41.39136549999999,
    14.08056058265353,
    0.3833333333333333,
    -96.12863148346746,
    42.400910999999994,
    14.423988889547518,
    0.8333333333333333,
    55.75464477812702,
    41.36237849999999,
    76.79843003270062,
    0.5,
    81.38185706273165,
    41.35409649999999,
    -48.82886102183628,
    0.3833333333333333,
    -27.025540710998218,
    41.349955499999986,
    90.97926661842352,
    0.26666666666666666,
    75.97429221040541,
    41.16775149999999,
    57.013584210801724,
    0.3833333333333333,
    80.47378133670406,
    41.0269575,
    50.57850235688615,
    0.26666666666666666,
    82.43655649125782,
    42.027615,
    51.81212436559069,
    0.5,
    95.05601452036287,
    41.00625249999999,
    -0.5167053423426285,
    0.26666666666666666,
    97.3744538989083,
    42.006404999999994,
    -0.5293079116680586,
    0.8333333333333333,
    89.47883241958054,
    40.97312449999999,
    32.12735971934397,
    0.3833333333333333,
    91.66124296639958,
    41.97246899999999,
    32.910953858840166,
    0.5,
    86.11628006422218,
    40.89858649999999,
    -40.35737299679539,
    0.3833333333333333,
    -94.35245691308074,
    40.795061499999996,
    12.280165173012154,
    0.5,
    82.54255090327968,
    40.757792499999994,
    -47.35984866853386,
    0.3833333333333333,
    -89.28111218873731,
    40.67497249999999,
    -33.043232945133354,
    0.26666666666666666,
    -94.31648740288371,
    40.62113949999999,
    -13.105298726210624,
    0.5,
    -96.61688953466137,
    41.611898999999994,
    -13.424940158557224,
    0.6666666666666666,
    -91.76232331303096,
    40.53417849999999,
    -25.57502724387427,
    0.3833333333333333,
    -94.07291596893866,
    40.426512499999994,
    15.277735211357296,
    0.3833333333333333,
    81.14001353792159,
    40.38924349999999,
    -50.02432245981556,
    0.5,
    83.11903825835871,
    41.37434699999999,
    -51.24442788566472,
    0.6666666666666666,
    -26.03965573496154,
    40.38510249999999,
    91.69735792414728,
    0.5,
    89.07299493920372,
    40.37682049999999,
    33.95790870874361,
    0.3833333333333333,
    87.11959905446611,
    40.3022825,
    -38.772762487076676,
    0.3833333333333333,
    81.42820447696278,
    40.06210449999998,
    49.81868046916758,
    0.3833333333333333,
    95.45324748787918,
    40.04139949999999,
    -1.670178163874959,
    0.5,
    59.406161353093104,
    40.028976499999985,
    74.73964582905593,
    0.26666666666666666,
    60.85509211780269,
    41.00529299999999,
    76.56256402000851,
    0.6666666666666666,
    -91.48677299495762,
    39.93787449999999,
    -27.432866645528353,
    0.3833333333333333,
    85.99169317260984,
    39.933733499999995,
    -41.571038705554436,
    0.3833333333333333,
    -94.60134364836165,
    39.83020849999999,
    13.474638984815538,
    0.5,
    55.5674933282615,
    39.80122149999999,
    77.75308403736506,
    0.26666666666666666,
    56.92279804358495,
    40.77198299999999,
    79.64950072120322,
    0.8333333333333333,
    82.31636947897688,
    39.79293949999999,
    -48.55886022910653,
    0.5,
    84.32408580773242,
    40.76349899999999,
    -49.74322267371888,
    0.8333333333333333,
    -94.8702995281186,
    39.65628649999999,
    -12.017937987649123,
    0.26666666666666666,
    -97.18420927270685,
    40.623512999999996,
    -12.311058426372274,
    0.8333333333333333,
    -92.4553036607798,
    39.56932549999999,
    -24.57052969449474,
    0.3833333333333333,
    -94.27933702216986,
    39.461659499999996,
    16.480584491035867,
    0.5,
    95.71558269754645,
    39.44509549999999,
    0.19311878476285266,
    0.26666666666666666,
    98.05010910480368,
    40.40717099999999,
    0.19782899902536127,
    0.5,
    89.8542461080296,
    39.411967499999996,
    33.02024983455745,
    0.26666666666666666,
    91.60040281113962,
    39.25046849999999,
    -28.03912538520902,
    0.3833333333333333,
    89.02606302904888,
    39.10967449999998,
    -35.527722795752986,
    0.26666666666666666,
    -93.86006859292583,
    39.093110499999995,
    19.479267444286723,
    0.3833333333333333,
    -96.14933855860694,
    40.046600999999995,
    19.954371528293716,
    0.6666666666666666,
    95.82496248926834,
    39.07654649999999,
    -2.832967095405832,
    0.26666666666666666,
    -94.86026321365188,
    39.0599825,
    -13.902296757600004,
    0.26666666666666666,
    90.99505418003805,
    39.043418499999994,
    30.21485084533432,
    0.5,
    93.21444574540483,
    39.99569699999999,
    30.951798426927837,
    0.5,
    90.81176124792,
    38.88191949999999,
    -30.965238252697098,
    0.3833333333333333,
    -94.82378343436228,
    38.8653555,
    14.675144362212606,
    0.3833333333333333,
    95.93710952760048,
    38.84879149999999,
    2.0659233961387433,
    0.3833333333333333,
    56.735320180573375,
    38.83636849999999,
    77.39680581742395,
    0.26666666666666666,
    58.11910847766053,
    39.78359699999999,
    79.28453278858063,
    0.6666666666666666,
    -26.903598788605173,
    38.82394549999999,
    92.12124213792818,
    0.5,
    -27.559784124912618,
    39.77087099999999,
    94.3681017022679,
    0.5,
    87.99724120787279,
    38.74112549999999,
    -38.380155807353155,
    0.3833333333333333,
    -93.34369752676065,
    38.72456149999999,
    22.467489705468534,
    0.5,
    -93.12629632666501,
    38.604472499999986,
    -23.55022845908133,
    0.3833333333333333,
    81.59828391666647,
    38.50094749999999,
    50.76241452548659,
    0.5,
    83.58848596341444,
    39.43999499999999,
    52.000522196839924,
    0.8333333333333333,
    -94.4591839790022,
    38.4968065,
    17.688529512725776,
    0.26666666666666666,
    -96.76306651507544,
    39.435753,
    18.11995706181665,
    0.6666666666666666,
    90.6150592973322,
    38.44711449999999,
    32.06487237094292,
    0.26666666666666666,
    91.62681333188102,
    38.28561549999999,
    -29.26028621580432,
    0.3833333333333333,
    96.11759414927899,
    38.252487499999994,
    3.947267411366416,
    0.26666666666666666,
    98.46192571389554,
    39.185475,
    4.0435422262777925,
    0.5,
    88.94977694284204,
    38.14482149999999,
    -36.74691006808307,
    0.5,
    -93.99710326473847,
    38.1282575,
    20.693143377742008,
    0.3833333333333333,
    96.17114167736071,
    38.11169349999999,
    -4.004491482706667,
    0.26666666666666666,
    98.51677927924756,
    39.041246999999984,
    -4.102162006675123,
    0.5,
    61.69537938827158,
    38.09927049999999,
    73.88877705379258,
    0.3833333333333333,
    63.20014473920504,
    39.02852099999999,
    75.69094234778751,
    0.6666666666666666,
    -95.40502875912173,
    38.09512949999999,
    -12.808872739214141,
    0.5,
    -97.73198068007592,
    39.02427899999999,
    -13.121284269438878,
    0.6666666666666666,
    -92.87813079535732,
    38.00816849999999,
    -25.42552993029061,
    0.26666666666666666,
    90.79496273304866,
    37.91706649999999,
    -32.18689850449843,
    0.3833333333333333,
    -95.01982991385997,
    37.900502499999995,
    15.881103815182838,
    0.3833333333333333,
    96.34002407274963,
    37.88393849999999,
    0.9124639117443814,
    0.26666666666666666,
    -25.894089666679836,
    37.85909249999999,
    92.80954078331811,
    0.26666666666666666,
    -26.525652829281782,
    38.78248499999999,
    95.0731881194966,
    0.8333333333333333,
    90.19341287093715,
    37.850810499999994,
    33.91002866683775,
    0.3833333333333333,
    92.3932522092527,
    38.77400099999999,
    34.737102536760624,
    0.5,
    89.86801598002377,
    37.54851749999999,
    -35.089516416821496,
    0.26666666666666666,
    92.05991880880484,
    38.46433499999999,
    -35.94535828064641,
    0.5,
    82.53039504877758,
    37.53609449999999,
    49.9720034397239,
    0.3833333333333333,
    84.54333151338191,
    38.45160899999998,
    51.190832791912285,
    0.6666666666666666,
    -94.61252786860071,
    37.53195349999999,
    18.900996317313282,
    0.26666666666666666,
    -96.92015049954219,
    38.44736699999999,
    19.361996227491655,
    0.6666666666666666,
    91.35520818602083,
    37.48226149999999,
    31.09230829537122,
    0.26666666666666666,
    91.62639952163569,
    37.32076249999999,
    -30.482605238401007,
    0.5,
    96.5361312030214,
    37.287634499999996,
    2.79880523571202,
    0.5,
    56.51800604035,
    37.27521149999999,
    78.31793680794476,
    0.3833333333333333,
    -27.766800367899563,
    37.26278849999999,
    92.50899964616134,
    0.3833333333333333,
    -28.444039401262966,
    38.17163699999999,
    94.76531671070187,
    0.5,
    -96.59648339106543,
    37.21723749999999,
    1.1925830571562712,
    0.5,
    -98.95249518109141,
    38.12497499999999,
    1.2216704487942291,
    0.8333333333333333,
    -94.10755686920973,
    37.16340449999999,
    21.910607729541344,
    0.5,
    96.49177269325867,
    37.14684049999999,
    -5.18417480222866,
    0.5,
    62.82661352590753,
    37.13441749999999,
    73.42531780524989,
    0.5,
    64.3589699533687,
    38.04013499999999,
    75.21617921513403,
    0.5,
    92.42732246059633,
    37.11371249999999,
    28.234518452282614,
    0.5,
    93.14796139396843,
    37.0930075,
    -25.787433136175324,
    0.3833333333333333,
    -93.54111409553302,
    37.04331549999999,
    -24.39832731016478,
    0.5,
    90.75137907751412,
    36.95221349999999,
    -33.40878234805561,
    0.3833333333333333,
    81.73391157213057,
    36.939790499999994,
    51.69569809892552,
    0.26666666666666666,
    83.72742161047522,
    37.84076099999999,
    52.956568784265166,
    0.5,
    59.048503269547815,
    36.90666249999999,
    76.60677613460373,
    0.5,
    -96.65173741935014,
    36.84868849999999,
    4.247521000649701,
    0.3833333333333333,
    -93.50518187470433,
    36.79485549999999,
    24.906729940170376,
    0.26666666666666666,
    61.52619309473593,
    36.53811349999999,
    74.81256211446302,
    0.5,
    63.0268319507051,
    37.42928699999999,
    76.63725875140113,
    0.5,
    -95.90420603765021,
    36.53397249999999,
    -13.604328085472723,
    0.26666666666666666,
    -98.24333301417826,
    37.42504499999999,
    -13.936140965606203,
    0.8333333333333333,
    92.07447312315092,
    36.517408499999995,
    30.103087890418912,
    0.5,
    94.32019197981315,
    37.40807699999999,
    30.83730954628279,
    0.8333333333333333,
    -26.750349764704886,
    36.297935499999994,
    93.18961471593168,
    0.26666666666666666,
    -96.94117122467816,
    36.25238449999999,
    2.3663399273539842,
    0.26666666666666666,
    -99.30559003503616,
    37.136588999999994,
    2.4240555353382276,
    0.8333333333333333,
    -94.19152252920247,
    36.1985515,
    23.13109547627579,
    0.26666666666666666,
    96.78684879841467,
    36.18198749999999,
    -6.3714445161723505,
    0.5,
    93.18056820989784,
    36.128154499999994,
    -27.010438454506918,
    0.5,
    95.45326499550511,
    37.009328999999994,
    -27.66922963632416,
    0.6666666666666666,
    96.80441761502878,
    36.095026499999996,
    6.593892414909426,
    0.26666666666666666,
    99.1655009714929,
    36.975393,
    6.7547190591755095,
    0.8333333333333333,
    90.6811720582534,
    35.987360499999994,
    -34.6303413697365,
    0.3833333333333333,
    82.66053508896236,
    35.97493749999999,
    50.8966151691595,
    0.26666666666666666,
    84.67664570088827,
    36.85237499999999,
    52.13799602694388,
    0.8333333333333333,
    -97.18972933066355,
    35.656080499999995,
    0.4754587362505365,
    0.5,
    -94.84001525980106,
    35.60224749999998,
    21.337223424556463,
    0.5,
    62.654953167069124,
    35.57326049999999,
    74.33926019292575,
    0.26666666666666666,
    -96.41676379781408,
    35.569119499999985,
    -12.4928388187493,
    0.3833333333333333,
    92.77264119475129,
    35.5525555,
    29.09773991514393,
    0.5,
    95.03538854096473,
    36.41969099999999,
    29.807440888684027,
    0.8333333333333333,
    -93.92050466185424,
    35.48215849999999,
    -25.24640285750612,
    0.3833333333333333,
    -96.21124867799702,
    36.34757699999999,
    -25.862168780859925,
    0.5,
    91.54568433721006,
    35.39105649999999,
    -32.928504734434895,
    0.5,
    93.77850590641032,
    36.25425299999999,
    -33.731638996250375,
    0.8333333333333333,
    58.8273546324304,
    35.345505499999994,
    77.5078267847818,
    0.5,
    60.26216816005065,
    36.207590999999994,
    79.39826158441062,
    0.8333333333333333,
    -97.26060939105255,
    35.28753149999999,
    3.5481835518484224,
    0.5,
    -94.2490983469619,
    35.233698499999996,
    24.35404639471235,
    0.26666666666666666,
    93.18671615811571,
    35.163301499999996,
    -28.234797401201096,
    0.3833333333333333,
    97.22983169217014,
    35.1301735,
    5.445765692000026,
    0.5,
    99.60129100173526,
    35.987007,
    5.578589245463441,
    0.5,
    92.37431195507766,
    34.794752499999994,
    -31.204764288322085,
    0.5,
    -27.605590748675333,
    34.73677849999999,
    93.53455620175139,
    0.5,
    -28.27889784010644,
    35.584016999999996,
    95.8158868408185,
    0.6666666666666666,
    -91.02953755085231,
    34.724355499999994,
    35.003829124648455,
    0.3833333333333333,
    63.77411516353401,
    34.60840749999999,
    73.84135691076001,
    0.5,
    -96.90562639567725,
    34.60426649999999,
    -11.36969383309857,
    0.5,
    93.44950628682665,
    34.58770249999999,
    28.076791759740605,
    0.3833333333333333,
    95.72876253772486,
    35.431304999999995,
    28.761591558758667,
    0.8333333333333333,
    97.31751267864455,
    34.53386949999999,
    7.357935063509626,
    0.26666666666666666,
    -94.55392422337732,
    34.51730549999999,
    -24.197864781411816,
    0.26666666666666666,
    -92.77500456722895,
    34.49660049999999,
    30.33494200578023,
    0.5,
    -95.03780955667355,
    35.33798099999999,
    31.074818640067555,
    0.8333333333333333,
    91.46568702114183,
    34.42620349999999,
    -34.15099171806161,
    0.26666666666666666,
    59.96971187803401,
    34.38065249999999,
    77.0651024520167,
    0.3833333333333333,
    -97.55477945760649,
    34.32267849999999,
    4.7375490037987475,
    0.5,
    -99.93416432242616,
    35.15981699999999,
    4.853098979501156,
    0.6666666666666666,
    97.30033814552667,
    34.252281499999995,
    -8.766472141684517,
    0.5,
    -91.89288841176689,
    34.12805149999999,
    33.292623584750785,
    0.3833333333333333,
    -97.74916435164033,
    34.0949235,
    -0.25036989665731685,
    0.26666666666666666,
    94.62616937239072,
    33.97069349999998,
    -24.684117931104645,
    0.26666666666666666,
    97.36343179652468,
    33.937565499999984,
    9.275204673574462,
    0.3833333333333333,
    -93.54882953458618,
    33.900296499999996,
    28.57992328402965,
    0.26666666666666666,
    92.3110751192002,
    33.829899499999996,
    -32.428828766350456,
    0.26666666666666666,
    81.90478313149238,
    33.81747649999999,
    53.52952837785092,
    0.5,
    58.57906117303408,
    33.7843485,
    78.3870972387451,
    0.26666666666666666,
    -26.56744612175407,
    33.771925499999995,
    94.18627012250855,
    0.5,
    -27.215432612528563,
    34.595631,
    96.48349622305753,
    0.5,
    -97.83606865446612,
    33.7263745,
    2.8391826859754716,
    0.3833333333333333,
    -100.22231423140431,
    34.54896899999999,
    2.9084310441699954,
    0.8333333333333333,
    64.88322850686329,
    33.64355449999999,
    73.3191893308409,
    0.26666666666666666,
    -97.37068927191318,
    33.63941349999999,
    -10.235446013171705,
    0.5,
    94.10486914517763,
    33.622849499999994,
    27.040769583526046,
    0.5,
    -95.16523686075504,
    33.552452499999994,
    -23.135173426443753,
    0.3833333333333333,
    -97.48634019882225,
    34.37080499999999,
    -23.69944594903994,
    0.5,
    -92.72018178912715,
    33.531747499999994,
    31.559712032708035,
    0.5,
    -97.93157430810855,
    33.4986195,
    -2.162144798489459,
    0.26666666666666666,
    97.36745950064879,
    33.34126149999999,
    11.196594883183721,
    0.26666666666666666,
    -28.459277125273353,
    33.17562149999999,
    93.84494290368359,
    0.5,
    -29.153405835645874,
    33.98478299999999,
    96.1338439501149,
    0.5,
    -91.79508036094533,
    33.16319849999999,
    34.515085278428366,
    0.26666666666666666,
    94.63975100946493,
    33.00584049999999,
    -25.910570174255867,
    0.26666666666666666,
    96.94803761945188,
    33.81086099999999,
    -26.542535300457228,
    0.5,
    97.79655901505336,
    32.972712499999986,
    8.127662653403522,
    0.5,
    100.18184094224979,
    33.776924999999984,
    8.325898327876779,
    0.8333333333333333,
    -94.89127811632399,
    32.95614849999999,
    -25.039233983818235,
    0.26666666666666666,
    -93.51089988828552,
    32.9354435,
    29.805935458249706,
    0.3833333333333333,
    92.22169592569173,
    32.86504649999999,
    -33.65223832291234,
    0.5,
    82.82121305678807,
    32.85262349999999,
    52.71411027390454,
    0.26666666666666666,
    59.71949200437036,
    32.819495499999995,
    77.9351564755305,
    0.3833333333333333,
    -98.12160398250282,
    32.76152149999999,
    4.032265604585884,
    0.3833333333333333,
    97.89458617613923,
    32.691124499999994,
    -8.08492432419414,
    0.3833333333333333,
    -95.75427212890753,
    32.58759949999999,
    -22.058861096863563,
    0.26666666666666666,
    -98.0897421808321,
    33.38241899999999,
    -22.59688209922609,
    0.6666666666666666,
    -92.63925458240723,
    32.5668945,
    32.7840131472919,
    0.5,
    -98.27517973140445,
    32.533766499999984,
    -0.9842312145176543,
    0.3833333333333333,
    62.22745919608844,
    32.45094649999999,
    76.10588031324049,
    0.5,
    97.71167261473087,
    32.32257549999999,
    -11.185069194173698,
    0.3833333333333333,
    -98.17167090869793,
    32.30601149999999,
    -6.005853557670223,
    0.3833333333333333,
    -96.5220589269032,
    32.219050499999994,
    -19.048636444372455,
    0.5,
    84.04068964601267,
    32.21490949999999,
    -51.15454735142705,
    0.3833333333333333,
    86.0904625642081,
    33.00063899999999,
    -52.40221923804722,
    0.5,
    -27.414966587307344,
    32.2107685,
    94.48974348920748,
    0.5,
    -98.37847793901808,
    32.1652175,
    2.1211993258123276,
    0.5,
    94.4015676242646,
    32.06169249999999,
    27.889380221095614,
    0.3833333333333333,
    94.62731552737091,
    32.040987499999986,
    -27.13801579165716,
    0.26666666666666666,
    79.11493457730118,
    32.02856449999999,
    58.586891092621514,
    0.5,
    -93.44694393440219,
    31.970590499999986,
    31.031848759008952,
    0.3833333333333333,
    -98.43257293401986,
    31.937462499999988,
    -2.907352687576654,
    0.26666666666666666,
    60.85100104589144,
    31.854642499999994,
    77.45878289716481,
    0.3833333333333333,
    62.3351718031083,
    32.631584999999994,
    79.34802150441273,
    0.6666666666666666,
    -97.19361167552012,
    31.850501499999993,
    -16.011652902392587,
    0.26666666666666666,
    -99.564187570045,
    32.627342999999996,
    -16.402181021963138,
    0.5,
    98.10442419792881,
    31.726271499999996,
    -9.294690661938677,
    0.26666666666666666,
    67.06953906400777,
    31.713848499999994,
    72.20342352036076,
    0.26666666666666666,
    -98.22903077344158,
    31.709707499999986,
    -7.9358420205238644,
    0.26666666666666666,
    81.02289933191044,
    31.660015499999982,
    56.128057399048366,
    0.26666666666666666,
    82.9990676082985,
    32.43221099999998,
    57.49703440878125,
    0.6666666666666666,
    -96.32086651027495,
    31.622746499999995,
    -20.96945882722132,
    0.5,
    -98.67015593735483,
    32.39403299999999,
    -21.4809090425194,
    0.8333333333333333,
    85.18982308781557,
    31.61860549999999,
    -49.60225250431733,
    0.5,
    87.26762365093302,
    32.38979099999999,
    -50.812063541007994,
    0.8333333333333333,
    -98.59406000885272,
    31.568913499999987,
    0.20163432313139493,
    0.5,
    -98.54827670039764,
    31.341158499999985,
    -4.836793179343495,
    0.3833333333333333,
    -100.95189320528539,
    32.10557699999998,
    -4.954763744693336,
    0.8333333333333333,
    -97.05033209645299,
    31.254197499999993,
    -17.93972696556947,
    0.3833333333333333,
    83.68111771956892,
    31.25005649999999,
    -52.329056278041705,
    0.26666666666666666,
    85.72212059077792,
    32.01225299999999,
    -53.605374723847596,
    0.8333333333333333,
    -26.35643237834704,
    31.245915499999995,
    95.11286380849059,
    0.3833333333333333,
    -26.999272192453066,
    32.008010999999996,
    97.43268975503914,
    0.8333333333333333,
    97.73072991935578,
    31.18380049999999,
    13.917637759553008,
    0.5,
    98.45656504853554,
    31.129967499999996,
    -7.392939015874061,
    0.26666666666666666,
    95.02845806582418,
    31.096839499999984,
    26.832896894355848,
    0.5,
    94.5889765512405,
    31.07613449999999,
    -28.365912721553357,
    0.3833333333333333,
    80.06598770164751,
    31.06371149999999,
    57.80924723782862,
    0.3833333333333333,
    82.01881666998038,
    31.821362999999987,
    59.21922887777566,
    0.8333333333333333,
    -93.3570975189431,
    31.005737499999988,
    32.257126498180796,
    0.5,
    61.97312954985634,
    30.889789499999985,
    76.95828573612079,
    0.5,
    63.484669294974786,
    31.643198999999985,
    78.83531709553837,
    0.6666666666666666,
    -97.68291178111191,
    30.88564849999999,
    -14.884592291962692,
    0.26666666666666666,
    98.2889081370592,
    30.761418499999994,
    -10.509581061707273,
    0.26666666666666666,
    100.68619857942649,
    31.511696999999995,
    -10.765912307114768,
    0.5,
    68.14586722361382,
    30.748995499999992,
    71.61051376077799,
    0.5,
    69.80796154614099,
    31.49897099999999,
    73.35711165738233,
    0.6666666666666666,
    -98.62213364199417,
    30.74485449999999,
    -6.771580515246963,
    0.5,
    -101.02755153570135,
    31.494728999999992,
    -6.936741015618839,
    0.6666666666666666,
    81.94645182822862,
    30.695162499999988,
    55.317372107344376,
    0.3833333333333333,
    -96.86486346921713,
    30.657893499999993,
    -19.86749647621921,
    0.26666666666666666,
    84.84653832036298,
    30.65375249999999,
    -50.78226085671964,
    0.5,
    86.91596608427427,
    31.40140499999999,
    -52.020852584932314,
    0.6666666666666666,
    -28.26063929466441,
    30.64961149999999,
    94.75950193281903,
    0.5,
    -28.94992317990013,
    31.39716299999999,
    97.07070929703414,
    0.5,
    -98.88817602454763,
    30.60406049999999,
    1.3949008747655782,
    0.3833333333333333,
    -101.30008275685367,
    31.350500999999987,
    1.4289228473208362,
    0.6666666666666666,
    95.30330259531708,
    30.47983049999999,
    -26.56475243846062,
    0.5,
    -98.90053795771423,
    30.37630549999999,
    -3.659136625680346,
    0.26666666666666666,
    -97.55562493070532,
    30.28934449999999,
    -16.819074859809195,
    0.26666666666666666,
    98.18857738868593,
    30.218947499999995,
    12.77670174099241,
    0.3833333333333333,
    98.65816217396859,
    30.165114499999998,
    -8.605726503718364,
    0.26666666666666666,
    101.06445881235807,
    30.900848999999997,
    -8.815622272101738,
    0.5,
    66.85952484742796,
    30.152691499999996,
    73.06192412934378,
    0.26666666666666666,
    -98.65400924446945,
    30.148550499999992,
    -8.710739792984771,
    0.3833333333333333,
    81.00128276736532,
    30.098858499999988,
    57.011196549785744,
    0.26666666666666666,
    85.97997673243067,
    30.057448499999992,
    -49.20791618998439,
    0.26666666666666666,
    88.07704933566069,
    30.790556999999993,
    -50.40810926778889,
    0.5,
    -98.14887734650246,
    29.920795499999993,
    -13.746617666480551,
    0.3833333333333333,
    -100.54275240373423,
    30.650570999999992,
    -14.081901024199588,
    0.6666666666666666,
    78.03865114871986,
    29.871103499999986,
    61.1155604374262,
    0.5,
    -98.99108540450436,
    29.78000149999999,
    -5.5984056746650905,
    0.5,
    84.47835437568332,
    29.68889949999999,
    -51.955774592006286,
    0.5,
    86.53880204338292,
    30.41301899999999,
    -53.22298860644546,
    0.8333333333333333,
    -27.19642782261792,
    29.684758499999987,
    95.37607169246284,
    0.3833333333333333,
    98.09862164468537,
    29.622643499999995,
    14.716828750949555,
    0.26666666666666666,
    -98.64379190278501,
    29.552246499999992,
    -10.65329389640999,
    0.3833333333333333,
    95.28499704418026,
    29.53568249999999,
    27.673785836956856,
    0.3833333333333333,
    61.69270655100171,
    29.328632499999987,
    77.789889440029,
    0.5,
    76.96908650156281,
    29.274799499999993,
    62.73891505556771,
    0.3833333333333333,
    67.93316821850165,
    29.187838499999994,
    72.46088851576879,
    0.5,
    -99.03964421255395,
    29.18369749999999,
    -7.542300675471438,
    0.5,
    -101.45524529090893,
    29.89549499999999,
    -7.726259228531717,
    0.5,
    94.43507078474265,
    29.14642849999999,
    -30.820913947756925,
    0.26666666666666666,
    85.62819620259054,
    29.09259549999999,
    -50.38708690889005,
    0.26666666666666666,
    87.71668879289763,
    29.80217099999999,
    -51.61604024813127,
    0.5,
    -99.36547960167405,
    29.042903499999987,
    0.6609423719089507,
    0.5,
    97.96642326904117,
    29.026339499999995,
    16.657645454919656,
    0.26666666666666666,
    100.35584822682266,
    29.734298999999993,
    17.0639294904055,
    0.6666666666666666,
    -98.59139294537822,
    28.955942499999995,
    -12.598264008454393,
    0.26666666666666666,
    78.99700685287236,
    28.90625049999999,
    60.34341070339402,
    0.5,
    80.92376311757656,
    29.61128099999999,
    61.81520120835485,
    0.5,
    98.5818934119698,
    28.831712499999995,
    -12.952539157569323,
    0.26666666666666666,
    100.98632983665199,
    29.534924999999998,
    -13.26845474677833,
    0.5,
    84.08552271924972,
    28.72404649999999,
    -53.12230837130834,
    0.26666666666666666,
    99.1802420700944,
    28.60395749999999,
    -7.906884548016924,
    0.26666666666666666,
    -99.04609723251478,
    28.587393499999994,
    -9.489846199142802,
    0.3833333333333333,
    95.88365210859837,
    28.570829499999988,
    26.598093634680097,
    0.5,
    98.22227776978369,
    29.267678999999987,
    27.246827625769857,
    0.6666666666666666,
    95.1839665008944,
    28.550124499999992,
    -29.022896774634873,
    0.26666666666666666,
    97.5055266594528,
    29.24646899999999,
    -29.730772305723526,
    0.5,
    62.80290799707097,
    28.363779499999985,
    77.25746814766077,
    0.3833333333333333,
    -98.49674664709995,
    28.359638499999996,
    -14.544670126418199,
    0.26666666666666666,
    77.93874910662059,
    28.30994649999999,
    61.98002857261674,
    0.26666666666666666,
    -99.40124248435565,
    28.218844499999992,
    -6.365173418780356,
    0.5,
    -101.82566303275456,
    28.90710899999999,
    -6.520421550945731,
    0.5,
    85.25173836231208,
    28.12774249999999,
    -51.55964345355367,
    0.26666666666666666,
    -28.034268872622054,
    28.12360149999999,
    95.60632004447578,
    0.3833333333333333,
    -99.62716902538006,
    28.078050499999993,
    1.8643757957559177,
    0.26666666666666666,
    -102.0570999772186,
    28.76288099999999,
    1.9098483761402083,
    0.8333333333333333,
    98.4335664811259,
    28.061486499999997,
    15.5181052392387,
    0.26666666666666666,
    100.83438517578749,
    28.745912999999998,
    15.896595610927449,
    0.5,
    -99.01035020424533,
    27.991089499999994,
    -11.440064948927699,
    0.26666666666666666,
    -101.42523679459278,
    28.673798999999995,
    -11.71909092329179,
    0.8333333333333333,
    95.50863023430881,
    27.97452549999999,
    28.51233239873445,
    0.3833333333333333,
    79.93991036808103,
    27.94139749999999,
    59.55094173134598,
    0.26666666666666666,
    81.88966427949764,
    28.62289499999999,
    61.00340372479344,
    0.8333333333333333,
    98.69045069564385,
    27.866859499999997,
    -14.179517202568992,
    0.5,
    101.09753485895223,
    28.546538999999996,
    -14.52535908555848,
    0.5,
    61.38688379551321,
    27.767475499999986,
    78.60053070579777,
    0.26666666666666666,
    99.34887062436732,
    27.639104499999988,
    -9.127290567348226,
    0.26666666666666666,
    67.69347287262296,
    27.626681499999986,
    73.29246772173123,
    0.3833333333333333,
    69.34453318658937,
    28.300502999999985,
    75.08008888567589,
    0.6666666666666666,
    -99.42455330047062,
    27.622540499999992,
    -8.317396367117395,
    0.5,
    86.38559739326207,
    27.531438499999997,
    -49.9697316595752,
    0.5,
    88.49256318334164,
    28.202936999999995,
    -51.188505602491674,
    0.5,
    -99.81068494463759,
    27.481746499999993,
    -0.08003244363031577,
    0.5,
    98.27547613404293,
    27.465182499999983,
    17.464826534175877,
    0.5,
    63.90274374501537,
    27.39892649999999,
    76.70178481304393,
    0.3833333333333333,
    -99.51503645099184,
    27.34095249999999,
    8.16427961001508,
    0.3833333333333333,
    99.11182940006651,
    27.270555499999986,
    -12.271418039229683,
    0.3833333333333333,
    101.52919109275105,
    27.935690999999984,
    -12.570720918235285,
    0.6666666666666666,
    94.17905645428304,
    27.21672249999999,
    -33.27132348943999,
    0.26666666666666666,
    84.85084922952885,
    27.162889499999995,
    -52.72510259865364,
    0.26666666666666666,
    -26.951384175674793,
    27.158748499999987,
    96.19485898907972,
    0.3833333333333333,
    -27.608735009227836,
    27.82115699999999,
    98.5410750619841,
    0.5,
    75.71226858602975,
    27.117338499999995,
    65.18993759188078,
    0.3833333333333333,
    -99.86416398345222,
    27.11319749999999,
    3.073906018758589,
    0.5,
    96.10109474098893,
    27.00967249999999,
    27.43142005121527,
    0.26666666666666666,
    98.44502388101304,
    27.66844499999999,
    28.100479076854665,
    0.6666666666666666,
    -99.30725417068581,
    26.972403499999988,
    11.313016552272629,
    0.26666666666666666,
    -99.95236064936417,
    26.885442499999993,
    -2.030814417574574,
    0.3833333333333333,
    98.0750745086872,
    26.868878499999987,
    19.410532019182025,
    0.5,
    100.46714949670395,
    27.52421699999999,
    19.88395962940598,
    0.8333333333333333,
    -99.81644881278595,
    26.74464849999999,
    6.231047104293965,
    0.5,
    68.75260465674268,
    26.661828499999988,
    72.6612130297192,
    0.26666666666666666,
    94.96273482082654,
    26.62041849999999,
    -31.477896042052045,
    0.5,
    86.00118519206552,
    26.566585499999995,
    -51.14135611646645,
    0.26666666666666666,
    88.09877507479882,
    27.214550999999993,
    -52.38870626564855,
    0.6666666666666666,
    -28.869424946185546,
    26.562444499999987,
    95.8040629120758,
    0.3833333333333333,
    74.54501522578538,
    26.521034499999995,
    66.76152379205584,
    0.3833333333333333,
    64.99177465392822,
    26.43407349999999,
    76.12315423397544,
    0.5,
    66.57693988938988,
    27.07880699999999,
    77.97981653236509,
    0.6666666666666666,
    -99.66740005984612,
    26.376099499999988,
    9.388097010400235,
    0.5,
    -102.09831225642773,
    27.01941899999999,
    9.617074986263656,
    0.8333333333333333,
    99.21274596589406,
    26.305702499999985,
    -13.500617722477134,
    0.26666666666666666,
    -100.0520396250873,
    26.289138499999993,
    -3.987002481536403,
    0.3833333333333333,
    76.6895998170354,
    26.152485499999994,
    64.43739913338567,
    0.26666666666666666,
    78.56007786135334,
    26.790350999999994,
    66.00904301468776,
    0.6666666666666666,
    -100.07644196296764,
    26.14834449999999,
    4.288993931225751,
    0.3833333333333333,
    67.42743649376862,
    26.065524499999988,
    74.10502591200343,
    0.5,
    69.07200811556785,
    26.70126899999999,
    75.91246556839376,
    0.5,
    87.11866477483225,
    25.970281499999995,
    -49.53088280720587,
    0.3833333333333333,
    -100.2240694170581,
    25.920589499999995,
    -0.8273901481205512,
    0.3833333333333333,
    98.5520867975316,
    25.90402549999999,
    18.272746803563685,
    0.5,
    -99.98639124680123,
    25.77979549999999,
    7.453142586839901,
    0.3833333333333333,
    -102.4250837162354,
    26.40857099999999,
    7.6349265523725816,
    0.5,
    94.8142270587805,
    25.655565499999994,
    -32.70290401663474,
    0.26666666666666666,
    97.12676918216539,
    26.281310999999995,
    -33.50053582191851,
    0.6666666666666666,
    85.59254667438168,
    25.601732499999997,
    -52.30576327511375,
    0.26666666666666666,
    -27.781537375174757,
    25.597591499999993,
    96.38659199012535,
    0.5,
    75.53349577458397,
    25.556181499999997,
    66.02270994294281,
    0.5,
    96.28630772950027,
    25.448515499999992,
    28.262088168892223,
    0.3833333333333333,
    -99.79479265441952,
    25.41124649999999,
    10.61572114260968,
    0.3833333333333333,
    -102.22881198745415,
    26.03103299999999,
    10.874641170478208,
    0.5,
    -100.34111151383385,
    25.324285499999995,
    -2.787028719415599,
    0.3833333333333333,
    -100.26398740047559,
    25.18349149999999,
    5.509102597220477,
    0.26666666666666666,
    97.22026153832992,
    25.079966499999994,
    25.231758792791343,
    0.26666666666666666,
    98.86485423578898,
    24.97230049999999,
    -17.87597353080093,
    0.3833333333333333,
    74.3418895330968,
    24.95987749999999,
    67.58486221657796,
    0.26666666666666666,
    76.1551063509772,
    25.56865499999999,
    69.23327349015304,
    0.8333333333333333,
    -100.4713427902183,
    24.955736499999997,
    0.38225472268658744,
    0.5,
    70.8334257275516,
    24.732122499999992,
    71.33283634725613,
    0.5,
    72.56107025749188,
    25.33534499999999,
    73.07266162401847,
    0.8333333333333333,
    85.15992795641013,
    24.636879499999996,
    -53.462477158858945,
    0.5,
    98.34016114658976,
    24.60375149999999,
    -21.006992711690202,
    0.3833333333333333,
    100.73870166236024,
    25.203842999999992,
    -21.519358387585086,
    0.5,
    -100.49997052752848,
    24.5871875,
    3.556931234329855,
    0.26666666666666666,
    -102.95118932088283,
    25.186874999999997,
    3.6436856546793637,
    0.6666666666666666,
    67.13569542971075,
    24.50436749999999,
    74.89833107348962,
    0.3833333333333333,
    -99.89723287921191,
    24.446393499999992,
    11.846616939854997,
    0.3833333333333333,
    -102.33375075431465,
    25.04264699999999,
    12.135558816436825,
    0.6666666666666666,
    99.33931440270182,
    24.375996499999992,
    -15.966747747526638,
    0.5,
    73.11530880448421,
    24.36357349999999,
    69.12303183263168,
    0.26666666666666666,
    98.7966195520224,
    24.34286849999999,
    19.080837069810464,
    0.26666666666666666,
    69.52812483348227,
    24.135818499999992,
    72.80609691141781,
    0.5,
    95.42159513181146,
    24.094408499999997,
    -32.11859720684864,
    0.3833333333333333,
    86.31050287874643,
    24.04057549999999,
    -51.86495394239449,
    0.3833333333333333,
    -28.60868653293037,
    24.036434499999995,
    96.54645771020157,
    0.5,
    98.87273604321489,
    24.00744749999999,
    -19.111518957516132,
    0.26666666666666666,
    -100.69414506055826,
    23.990883499999995,
    1.5975870594996806,
    0.3833333333333333,
    -100.25171208933753,
    23.850089499999996,
    9.909242100108628,
    0.3833333333333333,
    71.85430013329447,
    23.76726949999999,
    70.63640759458828,
    0.3833333333333333,
    84.70358029057482,
    23.672026499999987,
    -54.611027021485704,
    0.26666666666666666,
    -100.68051590191055,
    23.622334499999997,
    4.779608299353072,
    0.26666666666666666,
    97.393674585258,
    23.518809499999996,
    26.05308763819714,
    0.26666666666666666,
    99.3650545853388,
    23.41114349999999,
    -17.202613530363617,
    0.5,
    74.11168654779657,
    23.39872049999999,
    68.39139873554296,
    0.26666666666666666,
    99.24443552609175,
    23.378015499999993,
    17.92863724294674,
    0.26666666666666666,
    70.55942858813555,
    23.170965499999994,
    72.1241916427048,
    0.26666666666666666,
    72.2803902610169,
    23.736110999999994,
    73.88331826813663,
    0.8333333333333333,
    95.24065056376955,
    23.129555499999995,
    -33.34168213456487,
    0.26666666666666666,
    97.56359326044685,
    23.693690999999994,
    -34.154893893944504,
    0.8333333333333333,
    85.87061543482973,
    23.07572249999999,
    -53.020506041969966,
    0.26666666666666666,
    -27.50374015226717,
    23.071581499999994,
    97.10134926830537,
    0.5,
    98.85559326838896,
    23.04259449999999,
    -20.347878102235203,
    0.3833333333333333,
    101.26670529932527,
    23.60460899999999,
    -20.844167812045818,
    0.5,
    -100.89244766114525,
    23.026030499999997,
    2.8180754702694926,
    0.26666666666666666,
    66.8188685332193,
    22.943210499999996,
    75.67214497351746,
    0.3833333333333333,
    -100.3471144954965,
    22.885236499999994,
    11.142180566064487,
    0.26666666666666666,
    -102.79460509294763,
    23.443412999999993,
    11.413941067675816,
    0.8333333333333333,
    99.81680025676611,
    22.81483949999999,
    -15.281201199188953,
    0.5,
    102.25135636058968,
    23.37129899999999,
    -15.653913423559414,
    0.8333333333333333,
    72.86161760042832,
    22.802416499999993,
    69.91895384950693,
    0.26666666666666666,
    84.22376010422083,
    22.70717349999999,
    -55.750947351121816,
    0.5,
    98.24513831574504,
    22.674045499999988,
    -23.47777849337652,
    0.5,
    -100.8364279337176,
    22.657481499999996,
    6.006576283152699,
    0.5,
    -103.2958530052717,
    23.210102999999997,
    6.1530781437174,
    0.5,
    69.23127744292347,
    22.574661499999994,
    73.5855998601722,
    0.26666666666666666,
    97.91419368091634,
    22.553956499999995,
    24.931011752676834,
    0.3833333333333333,
    82.48888805606727,
    22.338624499999987,
    -58.4294003710598,
    0.26666666666666666,
    97.53564881849472,
    21.9576525,
    26.87162688123874,
    0.3833333333333333,
    -100.41770589403156,
    21.920383499999993,
    12.377671638588144,
    0.3833333333333333,
    99.83555791819704,
    21.849986499999993,
    -16.518628578430466,
    0.3833333333333333,
    99.45121735800596,
    21.81685849999999,
    18.732476510104785,
    0.5,
    101.8768568057622,
    22.348976999999994,
    19.189366181082953,
    0.6666666666666666,
    83.72072903270887,
    21.742320499999988,
    -56.881777877515546,
    0.5,
    -100.96770224928972,
    21.692628499999998,
    7.2373058702645,
    0.3833333333333333,
    95.81313767711538,
    21.5683985,
    -32.742517612598554,
    0.26666666666666666,
    -28.322980767217008,
    21.5104245,
    97.22415349741881,
    0.26666666666666666,
    81.94685332608422,
    21.37377149999999,
    -59.54242812330573,
    0.5,
    -100.76677791087351,
    21.324079499999996,
    10.429080876969454,
    0.5,
    98.74652550400711,
    21.112888499999993,
    -22.820939180345786,
    0.3833333333333333,
    -101.21547548710299,
    21.096324499999998,
    5.272403579995707,
    0.26666666666666666,
    68.90944084354958,
    21.013504499999996,
    74.3461311451902,
    0.26666666666666666,
    98.05050895957477,
    20.992799499999997,
    25.745401277143596,
    0.3833333333333333,
    99.8700185810358,
    20.852005499999997,
    17.5669826735138,
    0.3833333333333333,
    83.19475394830656,
    20.77746749999999,
    -58.00306358087627,
    0.5,
    95.60046109708183,
    20.60354549999999,
    -33.96279399364501,
    0.26666666666666666,
    97.93217966042529,
    21.10607099999999,
    -34.7911548227583,
    0.5,
    -27.20210457828712,
    20.545571499999998,
    97.75147376510658,
    0.26666666666666666,
    -100.83067311888297,
    20.359226499999995,
    11.666313887199793,
    0.5,
    -103.28995782909962,
    20.855792999999995,
    11.950858128351008,
    0.5,
    100.276464767797,
    20.288829499999995,
    -15.82465279848815,
    0.26666666666666666,
    102.7222322011579,
    20.783678999999996,
    -16.21061993991469,
    0.8333333333333333,
    99.6267776787174,
    20.255701499999997,
    19.535540716814445,
    0.26666666666666666,
    102.05669908551538,
    20.749742999999995,
    20.01201731966358,
    0.8333333333333333,
    -92.95344058706448,
    20.164599499999998,
    40.87141342101679,
    0.3833333333333333,
    79.11864694316304,
    20.13561249999999,
    63.65706905075792,
    0.5,
    -97.5899485091611,
    20.07763849999999,
    28.116116503574965,
    0.3833333333333333,
    98.54307022164991,
    20.0279465,
    24.60833792201557,
    0.5,
    -94.9612589361443,
    19.93684449999999,
    36.08749309172602,
    0.26666666666666666,
    75.79834158731587,
    19.907857499999995,
    67.64402595478978,
    0.26666666666666666,
    -91.68230241459935,
    19.796050499999996,
    43.81663421075836,
    0.3833333333333333,
    -101.15635609475554,
    19.7629225,
    9.707942155266796,
    0.5,
    -96.72242802191461,
    19.70908949999999,
    31.2049568758961,
    0.5,
    72.27683092882711,
    19.680102499999997,
    71.45753215354722,
    0.5,
    74.03968046367655,
    20.160104999999994,
    73.20039879143862,
    0.6666666666666666,
    85.59406810232598,
    19.584859499999993,
    -54.84080970463188,
    0.5,
    -26.07017185835002,
    19.580718499999985,
    98.2566396092389,
    0.3833333333333333,
    -93.8408568142175,
    19.568295499999998,
    39.09476983687469,
    0.26666666666666666,
    -96.12965819993013,
    20.045571,
    40.04830080850578,
    0.8333333333333333,
    77.95077712835707,
    19.539308499999994,
    65.26344607379411,
    0.26666666666666666,
    -98.23210761660646,
    19.481334499999992,
    26.23654444279125,
    0.5,
    -100.62801268042612,
    19.95648899999999,
    26.87646016090811,
    0.8333333333333333,
    -90.31657206239633,
    19.427501499999998,
    46.721671859699846,
    0.5,
    97.0770381493844,
    19.410937499999992,
    -30.27688548239489,
    0.3833333333333333,
    -95.75579278650723,
    19.34054049999999,
    34.26656192259951,
    0.26666666666666666,
    -98.09129992764156,
    19.81226099999999,
    35.102331725589735,
    0.6666666666666666,
    100.26374095530925,
    19.323976499999997,
    -17.06434314218072,
    0.26666666666666666,
    74.54836136983927,
    19.311553499999995,
    69.18837578299184,
    0.5,
    76.36661408617681,
    19.782566999999993,
    70.87589714355262,
    0.8333333333333333,
    100.03978136612584,
    19.2908485,
    18.366571083568893,
    0.5,
    98.53830210272167,
    19.183182499999994,
    -25.290989559217536,
    0.26666666666666666,
    80.02732270976148,
    19.170759499999992,
    62.813495565052385,
    0.5,
    81.97920862951175,
    19.63833899999999,
    64.34553204224879,
    0.5,
    -101.44034174217089,
    19.166618499999988,
    7.7410223812516215,
    0.3833333333333333,
    -97.42187322728306,
    19.11278549999999,
    29.344602098481086,
    0.3833333333333333,
    -99.79801647672899,
    19.57895099999999,
    30.060324100883065,
    0.6666666666666666,
    70.9480405644827,
    19.083798499999997,
    72.93565520286985,
    0.3833333333333333,
    99.01317362778781,
    19.063093499999987,
    23.460936455389003,
    0.26666666666666666,
    -94.69091772438672,
    18.97199149999999,
    37.297711243953714,
    0.5,
    -97.0004523030303,
    19.434722999999988,
    38.20741151819649,
    0.8333333333333333,
    76.74757596479697,
    18.943004499999994,
    66.84607534508439,
    0.26666666666666666,
    -91.31157573287027,
    18.831197499999995,
    45.000086254320586,
    0.26666666666666666,
    -101.21376281138497,
    18.798069499999997,
    10.946799789613092,
    0.5,
    -96.511948143847,
    18.74423649999999,
    32.42719674149245,
    0.5,
    100.68786348888281,
    18.727672499999997,
    -15.121311245287009,
    0.5,
    103.14366503739214,
    19.184444999999997,
    -15.490123714684252,
    0.6666666666666666,
    73.26433656902641,
    18.715249499999995,
    70.70715698041583,
    0.5,
    85.07782648917465,
    18.620006499999995,
    -55.96860211598864,
    0.5,
    -24.927679267339162,
    18.615865499999988,
    98.73948543025014,
    0.3833333333333333,
    -25.535671444591337,
    19.069910999999987,
    101.14776556269527,
    0.5,
    -93.52878347542072,
    18.60344249999999,
    40.295213285911,
    0.3833333333333333,
    78.87209449580813,
    18.574455499999992,
    64.4329724420877,
    0.5,
    -98.082021837831,
    18.516481499999994,
    27.467845384438196,
    0.26666666666666666,
    -100.47426627290004,
    18.968102999999992,
    28.137792832839125,
    0.5,
    -89.90507884379107,
    18.462648499999997,
    47.891889015334925,
    0.5,
    96.87549985596067,
    18.44608449999999,
    -31.50087489700287,
    0.5,
    99.23831692561825,
    18.89598899999999,
    -32.26918891888099,
    0.6666666666666666,
    -95.50315150504932,
    18.37568749999999,
    35.481090548430736,
    0.5,
    75.50958304208383,
    18.346700499999997,
    68.40414515637151,
    0.26666666666666666,
    77.35128018945174,
    18.794180999999995,
    70.07253894067325,
    0.8333333333333333,
    98.39724044775548,
    18.218329499999992,
    -26.523596428388526,
    0.5,
    100.79717314160317,
    18.662678999999994,
    -27.170513414446784,
    0.5,
    -101.51596958878687,
    18.20176549999999,
    8.979380609569361,
    0.3833333333333333,
    -97.22929868043275,
    18.147932499999992,
    30.570273945233843,
    0.3833333333333333,
    -99.6007449897116,
    18.59056499999999,
    31.315890382922472,
    0.6666666666666666,
    71.94684857936369,
    18.118945499999995,
    72.19958738432057,
    0.26666666666666666,
    -26.877797192678653,
    18.01956149999999,
    98.33770917820362,
    0.26666666666666666,
    -27.533353221768376,
    18.45906299999999,
    100.73618988986712,
    0.5,
    -94.39640870578316,
    18.00713849999999,
    38.50307275390578,
    0.3833333333333333,
    -96.69876013763152,
    18.44633699999999,
    39.4421720893669,
    0.6666666666666666,
    77.68135725018595,
    17.978151499999996,
    66.02892115892875,
    0.26666666666666666,
    79.57602450019048,
    18.416642999999993,
    67.63938265060995,
    0.5,
    -98.7024941742904,
    17.920177499999994,
    25.575584199440392,
    0.26666666666666666,
    -90.91718260091928,
    17.866344499999997,
    46.17667449176497,
    0.26666666666666666,
    -93.13467485947828,
    18.302108999999998,
    47.30293484522266,
    0.5,
    97.57329455443026,
    17.849780499999994,
    -29.635504903682886,
    0.5,
    -101.24658623005408,
    17.83321649999999,
    12.18732091250387,
    0.3833333333333333,
    -96.27710647651084,
    17.77938349999999,
    33.64577114394206,
    0.3833333333333333,
    100.66869994858952,
    17.7628195,
    -16.362173421378696,
    0.5,
    74.23735653340044,
    17.750396499999997,
    69.9368568354739,
    0.3833333333333333,
    100.17877275564035,
    17.729691499999987,
    19.164998134322893,
    0.26666666666666666,
    102.62215745699743,
    18.162122999999987,
    19.632437113208816,
    0.6666666666666666,
    -99.91867048708708,
    17.692422499999996,
    20.51007311479911,
    0.3833333333333333,
    -23.775124192723023,
    17.65101249999999,
    99.19985308630976,
    0.5,
    -93.19275047983449,
    17.638589499999988,
    41.48996316524996,
    0.26666666666666666,
    99.10763727176847,
    17.501936499999992,
    24.264873338996395,
    0.5,
    -89.47019649047444,
    17.497795499999995,
    49.05442607549372,
    0.26666666666666666,
    -100.87443470677353,
    17.464667499999997,
    15.386989202028031,
    0.26666666666666666,
    100.79643567826169,
    17.361142499999996,
    16.002965683045954,
    0.26666666666666666,
    -99.28293166707303,
    17.323873499999994,
    23.66872433291037,
    0.26666666666666666,
    82.74590566664291,
    17.286604499999992,
    -59.76381869716505,
    0.3833333333333333,
    98.23168372086401,
    17.253476499999994,
    -27.753909400804968,
    0.26666666666666666,
    100.62757844576313,
    17.674292999999995,
    -28.430834020336796,
    0.6666666666666666,
    -101.56707798559918,
    17.23691249999999,
    10.219742752856046,
    0.5,
    99.88540829037001,
    17.13338749999999,
    21.137120627959856,
    0.3833333333333333,
    -100.39978013210812,
    17.096118499999996,
    18.57505065022182,
    0.5,
    85.72189387609765,
    17.058849499999994,
    -55.48133189669657,
    0.5,
    78.59932519072714,
    17.013298499999994,
    65.19293963852392,
    0.5,
    -101.25485804584497,
    16.868363499999987,
    13.428985812325905,
    0.3833333333333333,
    -103.72448872988997,
    17.27978699999999,
    13.756522051650927,
    0.5,
    -99.8229970290607,
    16.727569499999994,
    21.748179412511966,
    0.5,
    -22.613004742651295,
    16.68615949999999,
    99.63759191514711,
    0.26666666666666666,
    -23.164541443691572,
    17.09313899999999,
    102.06777708380923,
    0.8333333333333333,
    -92.83294133659547,
    16.67373649999999,
    42.67853253012329,
    0.26666666666666666,
    99.54970114136167,
    16.53708349999999,
    23.104274430927333,
    0.26666666666666666,
    -89.01214814379416,
    16.532942499999997,
    50.208813171782275,
    0.26666666666666666,
    -91.18317614730134,
    16.936185,
    51.433418371094035,
    0.8333333333333333,
    -100.83985138829493,
    16.4998145,
    16.628473124498925,
    0.5,
    -103.29935995874115,
    16.902248999999998,
    17.034045639730607,
    0.5,
    82.14670568424663,
    16.321751499999994,
    -60.851826580621335,
    0.26666666666666666,
    84.15028387166727,
    16.719842999999994,
    -62.336017472831614,
    0.5,
    98.04174421479622,
    16.288623499999993,
    -28.98142095887836,
    0.3833333333333333,
    -101.59368912667794,
    16.272059499999987,
    11.461589517182082,
    0.26666666666666666,
    101.04441331900371,
    16.201662499999987,
    -15.65114354462443,
    0.3833333333333333,
    103.50891120483307,
    16.59682499999999,
    -16.032878753029905,
    0.5,
    100.28725898291641,
    16.16853449999999,
    19.961708414255483,
    0.5,
    102.73328968981681,
    16.562888999999988,
    20.448579351188545,
    0.8333333333333333,
    -100.32237441773677,
    16.131265499999998,
    19.814870414815854,
    0.3833333333333333,
    85.17694102017694,
    16.093996499999996,
    -56.59768210892815,
    0.3833333333333333,
    -24.57579708147751,
    16.08985549999999,
    99.27019881514876,
    0.5,
    -90.05823530294214,
    15.936638499999997,
    48.50735441600813,
    0.3833333333333333,
    -92.25477762740415,
    16.325336999999998,
    49.690460621276614,
    0.8333333333333333,
    -101.23861722628688,
    15.90351049999999,
    14.671276729915379,
    0.26666666666666666,
    -103.70785179278168,
    16.29140099999999,
    15.029112747718193,
    0.5,
    100.92423691010704,
    15.799985499999988,
    16.79537100847656,
    0.3833333333333333,
    103.38580366401209,
    16.185350999999986,
    17.20501420380526,
    0.5,
    -99.7028528834629,
    15.762716499999996,
    22.984854153097917,
    0.26666666666666666,
    83.39454431598506,
    15.725447499999994,
    -59.29153315823387,
    0.5,
    85.42855759198468,
    16.108994999999993,
    -60.73766811331274,
    0.5,
    99.96903268557338,
    15.572230499999991,
    21.93453364367766,
    0.5,
    102.40730177546543,
    15.95204099999999,
    22.469522269133215,
    0.8333333333333333,
    -100.7807695865187,
    15.534961499999989,
    17.869725172552183,
    0.5,
    86.34271405910629,
    15.497692499999996,
    -54.9743838626942,
    0.5,
    88.44863391420644,
    15.875684999999995,
    -56.31522249349162,
    0.6666666666666666,
    78.30083321256099,
    15.452141499999996,
    65.93660945394966,
    0.3833333333333333,
    -101.59583253779954,
    15.307206499999989,
    12.70440337511959,
    0.26666666666666666,
    100.99451282631979,
    15.236809499999989,
    -16.89300566018483,
    0.26666666666666666,
    -100.2205138417336,
    15.1664125,
    21.053602118847138,
    0.26666666666666666,
    84.60980401602752,
    15.129143499999994,
    -57.70368884504542,
    0.3833333333333333,
    -101.19791002521255,
    14.938657499999989,
    15.913677878689013,
    0.26666666666666666,
    101.26210919218016,
    14.835132499999988,
    15.599029157560519,
    0.26666666666666666,
    82.78950151357078,
    14.760594499999996,
    -60.3775530651999,
    0.3833333333333333,
    -20.262068699785928,
    14.75645349999999,
    100.44461795988619,
    0.5,
    -88.02747625395874,
    14.603236499999998,
    52.49128054337973,
    0.3833333333333333,
    -90.17448786990894,
    14.959412999999998,
    53.771555678584114,
    0.5,
    85.79185476501813,
    14.532839499999998,
    -56.08903508748637,
    0.5,
    79.19924462073229,
    14.487288499999998,
    65.0770600782117,
    0.26666666666666666,
    81.13093351392088,
    14.840636999999997,
    66.66430544597296,
    0.5,
    -101.57354508951713,
    14.34235349999999,
    13.947668587576542,
    0.26666666666666666,
    100.92018770032794,
    14.27195649999999,
    -18.134293402079027,
    0.26666666666666666,
    103.38165569301886,
    14.62005299999999,
    -18.576593241154125,
    0.5,
    101.02178207458327,
    14.238828499999991,
    17.586384916263786,
    0.26666666666666666,
    103.4857279788414,
    14.586116999999991,
    18.015321133733632,
    0.6666666666666666,
    -100.09427787972812,
    14.201559499999986,
    22.290735037313773,
    0.3833333333333333,
    -102.53560173045321,
    14.547938999999987,
    22.8344115016385,
    0.5,
    84.02075121208141,
    14.164290499999996,
    -58.79891040988028,
    0.5,
    -22.236317758693673,
    14.160149499999992,
    100.11224682161547,
    0.5,
    -89.10719878289403,
    14.006932499999989,
    50.804907164625874,
    0.3833333333333333,
    -91.28054509467192,
    14.348564999999988,
    52.04405124181187,
    0.6666666666666666,
    -101.13278999494392,
    13.97380449999999,
    17.155675464240627,
    0.26666666666666666,
    77.97710090172902,
    13.890984499999986,
    66.66361758547409,
    0.26666666666666666,
    101.33502775424509,
    13.67565249999999,
    -16.17449547113043,
    0.26666666666666666,
    103.80661379703156,
    14.009204999999989,
    -16.568995360670197,
    0.6666666666666666,
    100.73895495458986,
    13.642524499999992,
    19.569621992363956,
    0.5,
    103.19600263640912,
    13.97526899999999,
    20.04692984583625,
    0.5,
    -100.58936941656751,
    13.60525549999999,
    20.34948176630136,
    0.26666666666666666,
    85.21895819255164,
    13.567986499999998,
    -57.193221031097316,
    0.5,
    87.29746936797973,
    13.898912999999999,
    -58.588177641611885,
    0.6666666666666666,
    -101.52687100863605,
    13.377500499999993,
    15.190871224794073,
    0.5,
    100.82150344550925,
    13.30710349999999,
    -19.37449520445514,
    0.3833333333333333,
    101.35421910302134,
    13.273975499999992,
    16.38747904475067,
    0.5,
    83.41005668605132,
    13.199437499999997,
    -59.882910068844325,
    0.26666666666666666,
    -21.053764505840974,
    13.195296499999992,
    100.4989988776489,
    0.5,
    -21.567270957202947,
    13.517132999999992,
    102.9501939722257,
    0.5,
    -88.59771725750963,
    13.04207949999999,
    51.94010285005278,
    0.3833333333333333,
    -90.75863719061962,
    13.36017899999999,
    53.20693462688334,
    0.5,
    86.38350568516535,
    12.971682499999988,
    -55.56123670024095,
    0.3833333333333333,
    78.8721375562186,
    12.926131499999986,
    65.79913879951346,
    0.5,
    -101.87926100014735,
    12.781196499999991,
    13.216762806838997,
    0.3833333333333333,
    -104.36412102454119,
    13.092932999999991,
    13.539122875298485,
    0.8333333333333333,
    101.25478103235277,
    12.71079949999999,
    -17.416386512738317,
    0.3833333333333333,
    103.72440983801991,
    13.020818999999989,
    -17.841176427683152,
    0.5,
    101.08929261503593,
    12.67767149999999,
    18.37545071364866,
    0.5,
    103.55488511784168,
    12.98688299999999,
    18.8236324383718,
    0.8333333333333333,
    84.6242901644006,
    12.603133499999997,
    -58.286500711163875,
    0.3833333333333333,
    86.68829724158111,
    12.910526999999997,
    -59.708122679728845,
    0.8333333333333333,
    91.65035386668353,
    12.51617249999999,
    -46.48638173768667,
    0.3833333333333333,
    7.500796716043403,
    12.404365499999994,
    102.5050993373842,
    0.3833333333333333,
    100.69853285202996,
    12.342250499999988,
    -20.61310163570468,
    0.26666666666666666,
    77.62859958242792,
    12.329827499999988,
    67.3735965025715,
    0.5,
    -6.23557044589093,
    12.317404499999988,
    102.60031594784544,
    0.5,
    101.66329548856918,
    12.30912249999999,
    15.18174820907872,
    0.26666666666666666,
    -99.76903364180895,
    12.271853499999986,
    24.75817365748405,
    0.26666666666666666,
    -102.2024247062433,
    12.571166999999987,
    25.362031551569025,
    0.8333333333333333,
    82.77800024008043,
    12.234584499999997,
    -60.95525607661411,
    0.5,
    -19.863332513993388,
    12.230443499999991,
    100.86272799320788,
    0.3833333333333333,
    -20.347804038724934,
    12.52874699999999,
    103.32279452962759,
    0.5,
    2.2572920282835263,
    12.176610499999995,
    102.7816151577233,
    0.5,
    -88.0659099976382,
    12.07722649999999,
    53.06563597428739,
    0.3833333333333333,
    92.60376503637629,
    11.91986849999999,
    -44.72007447478505,
    0.3833333333333333,
    -16.676424676518717,
    11.861894499999993,
    101.48210652070279,
    0.5,
    -17.083166741799662,
    12.151208999999993,
    103.95727985047603,
    0.6666666666666666,
    5.503051456311069,
    11.808061499999994,
    102.70205320869398,
    0.5,
    101.1502539392052,
    11.74594649999999,
    -18.65702264171434,
    0.3833333333333333,
    103.61733330357605,
    12.03243299999999,
    -19.112071974439083,
    0.5,
    76.35102499204099,
    11.73352349999999,
    68.92221001926504,
    0.5,
    101.41639185571596,
    11.71281849999999,
    17.174136681473303,
    0.3833333333333333,
    -86.3826496518707,
    11.708677499999988,
    55.8423704307326,
    0.5,
    -100.30082446843332,
    11.675549499999988,
    22.822177368199927,
    0.5,
    84.00812235674647,
    11.638280499999986,
    -59.36843799610389,
    0.26666666666666666,
    0.25156120973523644,
    11.580306499999997,
    102.8749670392356,
    0.3833333333333333,
    91.18316993997895,
    11.55131949999999,
    -47.640551660385285,
    0.26666666666666666,
    93.40714969461258,
    11.83305899999999,
    -48.80251633502883,
    0.6666666666666666,
    8.745921674261389,
    11.439512499999992,
    102.51864236727887,
    0.3833333333333333,
    100.55135600151644,
    11.377397499999988,
    -21.849605428549488,
    0.5,
    -18.665520563613555,
    11.265590499999991,
    101.20331238539394,
    0.5,
    -19.120777162726082,
    11.540360999999992,
    103.67168585820843,
    0.6666666666666666,
    3.5003556659007864,
    11.211757499999996,
    102.85654878992096,
    0.3833333333333333,
    101.126979310384,
    11.116514499999994,
    19.162014140701736,
    0.5,
    103.59349100088117,
    11.387648999999993,
    19.629380339255437,
    0.8333333333333333,
    -87.51202204119674,
    11.112373499999988,
    54.181055531039554,
    0.3833333333333333,
    85.20491914506604,
    11.041976499999986,
    -57.75484510806176,
    0.3833333333333333,
    92.15374125910142,
    10.955015499999993,
    -45.88138219741579,
    0.3833333333333333,
    94.40139348493317,
    11.222210999999993,
    -47.000440299791784,
    0.8333333333333333,
    -15.467418012315532,
    10.897041499999991,
    101.78132977899028,
    0.26666666666666666,
    6.7481491100637845,
    10.843208499999996,
    102.734185829323,
    0.26666666666666666,
    101.02151739343358,
    10.781093499999992,
    -19.895895844933772,
    0.26666666666666666,
    77.25579032199377,
    10.768670499999988,
    68.06617531774914,
    0.5,
    79.14007789082288,
    11.03132099999999,
    69.72632593525522,
    0.8333333333333333,
    -7.012031233818534,
    10.75624749999999,
    102.72565493923462,
    0.5,
    101.72014507692745,
    10.747965499999992,
    15.966181388786802,
    0.5,
    104.2011242251452,
    11.010110999999991,
    16.355600447049895,
    0.6666666666666666,
    -85.79062989943064,
    10.743824499999986,
    56.93824445460287,
    0.26666666666666666,
    -87.88308428722164,
    11.005868999999986,
    58.32698212422733,
    0.5,
    -100.12033891801742,
    10.71069649999999,
    24.054611624929937,
    0.5,
    -102.56229840382272,
    10.97193299999999,
    24.641309469440422,
    0.8333333333333333,
    83.3707322918964,
    10.673427499999987,
    -60.43860163434616,
    0.3833333333333333,
    1.4936745828222642,
    10.615453499999985,
    102.96847434156713,
    0.26666666666666666,
    9.991574175312538,
    10.474659499999994,
    102.50792934626222,
    0.3833333333333333,
    93.08720202218937,
    10.358711499999995,
    -44.100969838994146,
    0.5,
    -17.460828236433265,
    10.300737499999993,
    101.52063784893134,
    0.3833333333333333,
    -17.886702095858467,
    10.551974999999993,
    103.99675096719795,
    0.5,
    4.745132769594806,
    10.246904499999996,
    102.90728977174939,
    0.5,
    101.45026052174362,
    10.184789499999992,
    -17.93154560853686,
    0.3833333333333333,
    103.92465711983492,
    10.433198999999991,
    -18.368900379476784,
    0.6666666666666666,
    101.44882266716785,
    10.151661499999994,
    17.958445735501414,
    0.5,
    -86.9363045842276,
    10.14752049999999,
    55.28591500500363,
    0.26666666666666666,
    -89.05670225701364,
    10.39502099999999,
    56.634351956345185,
    0.8333333333333333,
    13.227266033623463,
    10.106110499999993,
    102.17794081325745,
    0.26666666666666666,
    -0.5160243755111459,
    10.019149499999987,
    103.03774059605796,
    0.3833333333333333,
    -82.08575934283115,
    10.006726499999987,
    62.283377901852795,
    0.5,
    -84.08785103411972,
    10.250792999999986,
    63.802484679946765,
    0.5,
    102.25565386380087,
    9.957034499999994,
    -12.73044939710912,
    0.26666666666666666,
    104.74969420194236,
    10.199888999999994,
    -13.04094816289227,
    0.6666666666666666,
    -14.25235269290063,
    9.932188499999992,
    102.05708059374243,
    0.5,
    -14.599971051264058,
    10.174436999999992,
    104.54627768139468,
    0.6666666666666666,
    -85.17717542148947,
    9.778971499999988,
    58.022806969492564,
    0.26666666666666666,
    93.98307092722028,
    9.762407499999995,
    -42.30015843815514,
    0.26666666666666666,
    16.451866040190406,
    9.737561499999993,
    101.7444918648147,
    0.26666666666666666,
    90.18124886410429,
    9.621613499999992,
    -49.9218642677445,
    0.26666666666666666,
    -11.027265714985855,
    9.563639499999992,
    102.49078902694201,
    0.3833333333333333,
    -11.296223415351363,
    9.796898999999991,
    104.99056436906255,
    0.8333333333333333,
    11.237243313547365,
    9.509806499999996,
    102.47298945597659,
    0.5,
    100.18474033827293,
    9.44769149999999,
    -24.31428702787015,
    0.3833333333333333,
    -2.5277721260103845,
    9.422845499999989,
    103.06428079486169,
    0.3833333333333333,
    -83.33080261894249,
    9.410422499999987,
    60.702939865004396,
    0.5,
    102.54015789611589,
    9.360730499999995,
    -10.73863902671049,
    0.5,
    101.31588062954913,
    9.219936499999992,
    -19.170569323703415,
    0.3833333333333333,
    76.85912463223465,
    9.207513499999992,
    68.74097963306849,
    0.26666666666666666,
    -7.788898568517396,
    9.19509049999999,
    102.8212477787843,
    0.5,
    -7.978871704334894,
    9.41936099999999,
    105.32908309046196,
    0.5,
    -100.44338368784776,
    9.14953949999999,
    23.341769968743556,
    0.26666666666666666,
    -102.89322231438062,
    9.372698999999992,
    23.911081431395836,
    0.6666666666666666,
    14.471162747990707,
    9.141257499999995,
    102.10018846721027,
    0.3833333333333333,
    14.82411793696609,
    9.364214999999994,
    104.59043696641054,
    0.8333333333333333,
    -81.39904425634771,
    9.041873499999987,
    63.32350071468843,
    0.3833333333333333,
    102.18255349732539,
    8.992181499999994,
    -13.974693472033437,
    0.3833333333333333,
    -13.031731971418303,
    8.967335499999992,
    102.30926879249775,
    0.26666666666666666,
    -4.540598433920355,
    8.82654149999999,
    103.0480507142695,
    0.5,
    -84.54255768442988,
    8.814118499999989,
    59.095624882412444,
    0.5,
    93.54487021355189,
    8.797554499999995,
    -43.467067034126686,
    0.3833333333333333,
    -9.798543895732198,
    8.598786499999992,
    102.7009008395293,
    0.26666666666666666,
    12.482419491862972,
    8.544953499999995,
    102.41385940541444,
    0.26666666666666666,
    12.78686874776207,
    8.753366999999995,
    104.9117584153026,
    0.8333333333333333,
    -1.2857247907828042,
    8.457992499999989,
    103.1709014191129,
    0.26666666666666666,
    -1.3170839320214094,
    8.664284999999989,
    105.68726486835955,
    0.6666666666666666,
    -82.65960503406195,
    8.445569499999989,
    61.75344262819321,
    0.26666666666666666,
    -84.675692961722,
    8.651558999999988,
    63.25962415571012,
    0.8333333333333333,
    102.48568911070224,
    8.395877499999996,
    -11.984089181472248,
    0.3833333333333333,
    -15.032804600293057,
    8.371031499999994,
    102.08509314525514,
    0.3833333333333333,
    77.74399975920464,
    8.242660499999992,
    67.86276353290167,
    0.26666666666666666,
    -85.7204166827977,
    8.21781449999999,
    57.46219029562943,
    0.5,
    -100.23332097545577,
    8.18468649999999,
    24.5706715033997,
    0.26666666666666666,
    15.713720638834381,
    8.176404499999995,
    101.99828929024766,
    0.5,
    16.096982117830343,
    8.375828999999994,
    104.48605244366833,
    0.5,
    90.66838085811932,
    8.060456499999994,
    -49.31226397741872,
    0.26666666666666666,
    92.87980478148809,
    8.257052999999994,
    -50.51500212320942,
    0.5,
    -11.806059621244666,
    8.002482499999994,
    102.5378118308403,
    0.3833333333333333,
    10.48659059830645,
    7.948649499999997,
    102.68532521129619,
    0.5,
    100.49087055472536,
    7.886534499999993,
    -23.596888221410435,
    0.5,
    -3.300052526261545,
    7.861688499999991,
    103.1732966045618,
    0.3833333333333333,
    -83.88705416078766,
    7.84926549999999,
    60.156269833954866,
    0.26666666666666666,
    101.58159191000053,
    7.658779499999994,
    -18.43770339821353,
    0.26666666666666666,
    -8.565603118776234,
    7.633933499999992,
    102.88721556893834,
    0.5,
    95.25701822241626,
    7.604946499999986,
    -39.82073446216673,
    0.3833333333333333,
    91.65309714447015,
    7.4641524999999955,
    -47.554409220208605,
    0.5,
    93.88853853823771,
    7.646204999999996,
    -48.71427285972589,
    0.6666666666666666,
    100.97501475701164,
    7.290230499999994,
    -21.640807727027,
    0.3833333333333333,
    -79.9651068711214,
    7.112167499999988,
    65.36378493892529,
    0.3833333333333333,
    101.96397050025476,
    7.062475499999996,
    -16.458912012495723,
    0.5,
    75.1012641434544,
    7.050052499999994,
    70.90502456668591,
    0.26666666666666666,
    100.26615249695165,
    6.921681499999993,
    -24.82367043107429,
    0.3833333333333333,
    102.71166841151144,
    7.090502999999992,
    -25.42912580744196,
    0.8333333333333333,
    102.6866346989343,
    6.834720499999986,
    -11.232420705017333,
    0.3833333333333333,
    94.83095030347091,
    6.640093499999987,
    -40.99299510711416,
    0.26666666666666666,
    97.14390031087265,
    6.8020469999999875,
    -41.99282425606817,
    0.6666666666666666,
    -100.52258998533112,
    6.623529499999994,
    23.848760651313402,
    0.3833333333333333,
    -81.25619269484055,
    6.515863499999989,
    63.81536097671399,
    0.3833333333333333,
    -83.23805105325128,
    6.67478699999999,
    65.37183319565824,
    0.6666666666666666,
    91.13047293639556,
    6.499299499999996,
    -48.686976014106435,
    0.26666666666666666,
    100.7687041892245,
    6.325377499999995,
    -22.871018353083056,
    0.3833333333333333,
    18.1927964358026,
    6.246698499999997,
    101.7223010981149,
    0.3833333333333333,
    18.63652317813925,
    6.399056999999996,
    104.20333283221527,
    0.6666666666666666,
    75.99598605282588,
    6.085199499999995,
    70.03574855674701,
    0.5,
    92.09498574379201,
    5.902995499999987,
    -46.915816842273216,
    0.5,
    102.60259188265168,
    5.869867499999987,
    -12.477115832373467,
    0.5,
    101.22983549780946,
    5.729073499999996,
    -20.90750455979936,
    0.3833333333333333,
    -100.28329995114812,
    5.658676499999995,
    25.07318799387908,
    0.3833333333333333,
    -80.5245778115479,
    5.5510104999999905,
    64.82595371994813,
    0.26666666666666666,
    -82.48859190451248,
    5.68640099999999,
    66.40707454238589,
    0.6666666666666666,
    102.17761752239352,
    5.501318499999987,
    -15.712911799186594,
    0.5,
    100.53860448884535,
    5.360524499999996,
    -24.097290501639883,
    0.26666666666666666,
    19.428306615057796,
    5.281845499999997,
    101.54835614221155,
    0.26666666666666666,
    102.85848601244327,
    5.273563499999988,
    -10.476020629966351,
    0.26666666666666666,
    91.5673037589863,
    4.938142499999986,
    -48.04653219493815,
    0.5,
    93.8006526311567,
    5.058584999999987,
    -49.21839883383908,
    0.8333333333333333,
    102.49449783853741,
    4.9050144999999885,
    -13.720217585040029,
    0.3833333333333333,
    104.99436363947734,
    5.024648999999988,
    -14.054857038333688,
    0.5,
    73.25042738239571,
    4.8925914999999875,
    72.9922123360465,
    0.5,
    75.03702317221024,
    5.011922999999987,
    74.7725101979013,
    0.6666666666666666,
    96.00952557004356,
    4.4826324999999905,
    -38.464303330297,
    0.3833333333333333,
    -101.24236334064838,
    4.466068499999998,
    21.153338326454413,
    0.26666666666666666,
    71.83065803195215,
    4.2962874999999885,
    74.42663572548534,
    0.5,
    101.45613092248182,
    4.167916499999987,
    -20.166992718034454,
    0.5,
    -81.06194100871136,
    3.9898534999999926,
    64.26872033850323,
    0.26666666666666666,
    102.36240823666445,
    3.9401614999999888,
    -14.961220950990821,
    0.5,
    74.15484189584524,
    3.927738499999988,
    72.13222524418481,
    0.5,
    75.9634965762317,
    4.023536999999988,
    73.89154781111614,
    0.5,
    100.78276567292458,
    3.799367499999997,
    -23.362888839274998,
    0.5,
    18.68316613716862,
    3.720688499999988,
    101.7571688146684,
    0.3833333333333333,
    101.85227159091964,
    3.571612499999988,
    -18.187467688476843,
    0.3833333333333333,
    104.33647333703964,
    3.6587249999999876,
    -18.63106446136652,
    0.6666666666666666,
    -101.01617782166484,
    3.5012154999999865,
    22.38077150869352,
    0.5,
    91.97864550592517,
    3.376985499999989,
    -47.391458775059256,
    0.26666666666666666,
    102.65566908822784,
    3.34385749999999,
    -12.963712665280594,
    0.5,
    72.74799222352723,
    3.3314344999999888,
    73.58027450762758,
    0.3833333333333333,
    74.5223334972718,
    3.4126889999999888,
    75.37491534927703,
    0.6666666666666666,
    -80.3063797344159,
    3.0250004999999933,
    65.26224307305714,
    0.5,
    96.3455557909218,
    2.921475499999993,
    -37.76803523141328,
    0.3833333333333333,
    -101.45173521342508,
    2.904911499999988,
    20.409128750690268,
    0.3833333333333333,
    -103.92616777960617,
    2.9757629999999877,
    20.906912378755887,
    0.5,
    100.52373634775196,
    2.8345144999999863,
    -24.583929369344904,
    0.3833333333333333,
    19.914760829923644,
    2.755835499999988,
    101.55409049754655,
    0.26666666666666666,
    101.65382559935136,
    2.6067594999999883,
    -19.41983962152656,
    0.26666666666666666,
    56.316866669925645,
    2.420414499999986,
    86.83304524851128,
    0.5,
    91.42427874924519,
    2.412132499999989,
    -48.50988040577669,
    0.26666666666666666,
    97.07831940555674,
    2.3251714999999944,
    -35.88619656481153,
    0.3833333333333333,
    -101.84542282998774,
    2.3086074999999893,
    18.428396701611184,
    0.26666666666666666,
    87.10958515426363,
    2.2713384999999864,
    -55.89442566723906,
    0.26666666666666666,
    69.84302688743972,
    2.138826499999992,
    76.3858798561833,
    0.5,
    82.19961433987793,
    2.130544499999995,
    -62.89761368691863,
    0.5,
    84.20448298231398,
    2.1825089999999947,
    -64.43170182562396,
    0.5,
    -79.53172269016882,
    2.0601474999999936,
    66.24112395794873,
    0.26666666666666666,
    102.02650291591839,
    2.0104554999999897,
    -17.434918882249388,
    0.26666666666666666,
    85.30783436195664,
    1.902789499999997,
    -58.62063129642718,
    0.26666666666666666,
    87.38851324883363,
    1.949198999999997,
    -60.05040279146199,
    0.8333333333333333,
    92.36426379022578,
    1.8158284999999907,
    -46.72227698163022,
    0.5,
    94.6170507119386,
    1.8601169999999905,
    -47.8618447128895,
    0.5,
    97.77107799760012,
    1.7288674999999956,
    -33.98901218562604,
    0.3833333333333333,
    88.19329609912238,
    1.6750344999999878,
    -54.19005819886463,
    0.26666666666666666,
    90.34435210154,
    1.7158889999999876,
    -55.5117669354223,
    0.8333333333333333,
    15.941147448531432,
    1.5632274999999909,
    102.27835432195526,
    0.3833333333333333,
    57.36994842937496,
    1.4555614999999866,
    86.16261360317813,
    0.26666666666666666,
    58.76921546423776,
    1.4910629999999863,
    88.26414076423124,
    0.6666666666666666,
    102.35702260608868,
    1.4141514999999911,
    -15.442335437092972,
    0.3833333333333333,
    104.85353535257865,
    1.448642999999991,
    -15.818977764826947,
    0.6666666666666666,
    96.6545283992508,
    1.3603184999999949,
    -37.060454645038796,
    0.3833333333333333,
    -101.63249221086225,
    1.3437544999999897,
    19.65849627648951,
    0.5,
    86.44311569321388,
    1.3064854999999869,
    -56.950034854141464,
    0.3833333333333333,
    100.73432318531178,
    1.2733574999999884,
    -23.841147582999305,
    0.5,
    70.76968035253743,
    1.1739734999999922,
    75.54932001031494,
    0.26666666666666666,
    -78.73828888011302,
    1.095294499999994,
    67.20496870909278,
    0.26666666666666666,
    55.68141221060895,
    0.8592574999999879,
    87.2712302868531,
    0.5,
    91.80512079717167,
    0.8509754999999911,
    -47.83849141763477,
    0.5,
    97.36500385612037,
    0.764014499999996,
    -35.169559720608554,
    0.3833333333333333,
    87.54277681690249,
    0.7101814999999883,
    -55.25561957324394,
    0.5,
    17.17508736408242,
    0.5983744999999914,
    102.08860831157357,
    0.5,
    82.68238724993344,
    0.5693874999999968,
    -62.295459397350044,
    0.26666666666666666,
    102.17192289175641,
    0.44929849999999166,
    -16.676987991207454,
    0.26666666666666666,
    85.7564019214446,
    0.3416324999999874,
    -57.9926585157345,
    0.26666666666666666,
    100.44663772865937,
    0.30850449999998875,
    -25.05600564696898,
    0.5,
    102.89655572204131,
    0.31602899999998846,
    -25.667127735919443,
    0.5,
    98.03523560381801,
    0.16771049999998588,
    -33.26394562122905,
    0.26666666666666666,
    88.60628682721962,
    0.11387749999998953,
    -53.53819750054612,
    0.26666666666666666,
    67.77248320535662,
    -0.018634500000016565,
    78.2580078837627,
    0.26666666666666666,
    -75.73597622762756,
    -0.2381075000000062,
    70.57925215434544,
    0.3833333333333333,
    86.8718358303398,
    -0.2546714999999998,
    -56.308479884415064,
    0.5,
    100.91646468338041,
    -0.2877995000000099,
    -23.09133933438756,
    0.5,
    103.37784187077993,
    -0.29481900000001016,
    -23.654542732787256,
    0.5,
    59.436521729979425,
    -0.4741445000000124,
    84.76143401709768,
    0.26666666666666666,
    101.96307251704985,
    -0.5155545000000078,
    -17.907866188922736,
    0.26666666666666666,
    104.44997672478277,
    -0.528129000000008,
    -18.344643413042803,
    0.8333333333333333,
    76.4201505204436,
    -0.5362595000000056,
    -69.83622731205777,
    0.3833333333333333,
    55.03003722755696,
    -0.7018995000000102,
    87.6848217471279,
    0.3833333333333333,
    92.16007074885957,
    -0.7101815000000185,
    -47.15339464770545,
    0.3833333333333333,
    94.40787735249029,
    -0.727503000000019,
    -48.30347744399094,
    0.5,
    97.62419330844142,
    -0.7971425000000135,
    -34.44280867918226,
    0.3833333333333333,
    100.00527119401316,
    -0.8165850000000139,
    -35.28287718355256,
    0.5,
    -102.13104809560912,
    -0.8137065000000071,
    16.91190473081709,
    0.26666666666666666,
    -77.09639615772963,
    -0.8344115000000047,
    69.08599772702914,
    0.26666666666666666,
    -78.97679606401572,
    -0.8547630000000048,
    70.77102206183473,
    0.6666666666666666,
    87.95128023821967,
    -0.8509754999999983,
    -54.6010418413106,
    0.26666666666666666,
    90.0964334147616,
    -0.8717309999999983,
    -55.93277456914744,
    0.8333333333333333,
    16.41327713331286,
    -0.9627825000000182,
    102.21107087103022,
    0.26666666666666666,
    68.70820932890817,
    -0.9834875000000158,
    77.4315203786693,
    0.5,
    57.76886324345909,
    -1.070448500000011,
    85.90132830502311,
    0.3833333333333333,
    59.1778599079337,
    -1.096557000000011,
    87.99648265392612,
    0.6666666666666666,
    77.76463214501197,
    -1.132563500000004,
    -68.32865366937531,
    0.5,
    86.18074597456378,
    -1.2195244999999992,
    -57.34821190117098,
    0.26666666666666666,
    100.62367760215263,
    -1.2526525000000093,
    -24.30497876016955,
    0.3833333333333333,
    53.30569276110232,
    -1.2982035000000087,
    88.73693375326617,
    0.26666666666666666,
    98.27166461475382,
    -1.393446500000012,
    -32.529430761957364,
    0.26666666666666666,
    101.73055621114993,
    -1.4804075000000072,
    -19.134470246233786,
    0.26666666666666666,
    104.21178928947066,
    -1.5165150000000074,
    -19.601164642483393,
    0.6666666666666666,
    75.56547082009506,
    -1.5011125000000047,
    -70.74625011970393,
    0.3833333333333333,
    91.57488757970538,
    -1.675034500000018,
    -48.256189750001276,
    0.26666666666666666,
    93.80842142311283,
    -1.7158890000000184,
    -49.43316998780619,
    0.6666666666666666,
    -76.24860435066721,
    -1.7992645000000043,
    70.00241857846379,
    0.3833333333333333,
    87.27594285118411,
    -1.8158284999999978,
    -55.65103944487856,
    0.26666666666666666,
    89.40462438413982,
    -1.8601169999999978,
    -57.00838187036341,
    0.8333333333333333,
    17.642403972378816,
    -1.9276355000000178,
    101.99242828982223,
    0.5,
    18.07270650829049,
    -1.9746510000000181,
    104.48004849201301,
    0.8333333333333333,
    58.79236936965328,
    -2.0353015000000103,
    85.18650407609383,
    0.5,
    60.22632959818141,
    -2.0849430000000106,
    87.26422368770588,
    0.6666666666666666,
    76.92377856718282,
    -2.0974165000000036,
    -69.2514170250151,
    0.26666666666666666,
    78.79996828833362,
    -2.1485730000000034,
    -70.94047597684474,
    0.5,
    54.363223150309175,
    -2.2630565000000082,
    88.07351570697912,
    0.26666666666666666,
    97.85569621779374,
    -2.3582995000000118,
    -33.70647958774648,
    0.26666666666666666,
    100.24242051578871,
    -2.415819000000012,
    -34.5285888459842,
    0.6666666666666666,
    -102.2305099210721,
    -2.3748635000000053,
    16.146469881492475,
    0.5,
    -77.5909129713722,
    -2.395568500000003,
    68.49333617827905,
    0.3833333333333333,
    88.33479286538777,
    -2.4121325000000193,
    -53.93117476220485,
    0.26666666666666666,
    15.647035585955207,
    -2.5239395000000164,
    102.30457287810839,
    0.26666666666666666,
    68.11166962413324,
    -2.544644500000014,
    77.92144037671241,
    0.5,
    99.44399120292525,
    -2.5860545000000092,
    -28.664098812897528,
    0.26666666666666666,
    68.5212242589922,
    -2.6067595000000074,
    -77.5594756039393,
    0.26666666666666666,
    90.96860339597853,
    -2.6398875000000173,
    -49.34743980362617,
    0.26666666666666666,
    96.73442517771834,
    -2.7268485000000124,
    -36.77554768857233,
    0.5,
    93.36433265372709,
    -2.867642500000015,
    -44.63522868336605,
    0.5,
    89.35679319969867,
    -3.008436500000018,
    -52.18944762968613,
    0.26666666666666666,
    76.06536600049878,
    -3.062269500000003,
    -70.15773817419952,
    0.5,
    77.92061882977924,
    -3.136959000000003,
    -71.8689025199117,
    0.8333333333333333,
    -73.10015643942899,
    -3.1326665000000045,
    73.23919138091836,
    0.26666666666666666,
    99.96829567839765,
    -3.1823585000000083,
    -26.71400528812442,
    0.3833333333333333,
    70.01019052061658,
    -3.2030635000000056,
    -76.19540164919361,
    0.3833333333333333,
    97.41716027386573,
    -3.3231525000000106,
    -34.87519414480292,
    0.3833333333333333,
    -102.00209502460856,
    -3.3397165000000046,
    17.373673454118716,
    0.3833333333333333,
    -104.48995100081854,
    -3.421173000000005,
    17.797421587146,
    0.8333333333333333,
    87.6551220516993,
    -3.376985500000019,
    -54.97818814797817,
    0.26666666666666666,
    101.19490262378298,
    -3.4101135000000062,
    -21.57286336767094,
    0.5,
    103.66307098046062,
    -3.4932870000000062,
    -22.09903076688243,
    0.6666666666666666,
    73.80448571733494,
    -3.4308185000000035,
    -72.51588100147276,
    0.3833333333333333,
    16.87506992670528,
    -3.488792500000016,
    102.08078157449985,
    0.5,
    69.0275815442663,
    -3.5094975000000135,
    77.07335491885092,
    0.5,
    99.06369022506831,
    -3.5509075000000085,
    -29.85300587743465,
    0.26666666666666666,
    58.13194711334907,
    -3.5964585000000087,
    85.58719434628385,
    0.26666666666666666,
    90.3414604112298,
    -3.604740500000017,
    -50.426699294063,
    0.3833333333333333,
    -74.50104083683917,
    -3.728970500000003,
    71.78520264119726,
    0.5,
    53.68144737314363,
    -3.8242135000000066,
    88.43700144187855,
    0.3833333333333333,
    92.79124432787277,
    -3.832495500000015,
    -45.74409884703706,
    0.3833333333333333,
    -102.30093622687511,
    -3.9360205000000037,
    15.37698978111159,
    0.26666666666666666,
    -104.79608101289647,
    -4.032021000000004,
    15.752038312358213,
    0.8333333333333333,
    88.6930052990295,
    -3.9732895000000177,
    -53.24649666011385,
    0.5,
    75.18973915781709,
    -4.027122500000003,
    -71.04724508909139,
    0.5,
    -72.18808425127864,
    -4.097519500000004,
    74.09127108558347,
    0.3833333333333333,
    -73.94876923301715,
    -4.197459000000004,
    75.89837525840258,
    0.5,
    99.60595785986015,
    -4.147211500000008,
    -27.908411287531777,
    0.26666666666666666,
    102.0353714661982,
    -4.248363000000007,
    -28.589104245764258,
    0.6666666666666666,
    69.06327148235913,
    -4.167916500000006,
    -77.00856205126146,
    0.26666666666666666,
    70.74774151851423,
    -4.269573000000006,
    -78.88681966226785,
    0.5,
    96.95622923890703,
    -4.28800550000001,
    -36.035097413556116,
    0.26666666666666666,
    59.138106084924345,
    -4.561311500000008,
    84.84871519998522,
    0.5,
    89.69370766314739,
    -4.569593500000017,
    -51.493526252167,
    0.5,
    76.5432400234219,
    -4.623426500000002,
    -69.54985232993613,
    0.5,
    -73.60197069471559,
    -4.693823500000002,
    72.65083313909813,
    0.3833333333333333,
    100.1067338647525,
    -4.743515500000006,
    -25.95316012806293,
    0.3833333333333333,
    102.54836151999037,
    -4.859211000000006,
    -26.58616403362544,
    0.8333333333333333,
    70.5351484831072,
    -4.764220500000004,
    -75.62751256317635,
    0.3833333333333333,
    54.72279345227801,
    -4.789066500000006,
    87.74911021678736,
    0.26666666666666666,
    -102.06737472690725,
    -4.900873500000003,
    16.602953962897562,
    0.26666666666666666,
    88.00905176226198,
    -4.9381425000000165,
    -54.290396771042154,
    0.5,
    101.29092510327185,
    -4.971270500000005,
    -20.80530188051122,
    0.3833333333333333,
    74.29724851001704,
    -4.9919755000000015,
    -71.91957083748602,
    0.3833333333333333,
    76.10937652245649,
    -5.113731000000002,
    -73.67370671157106,
    0.8333333333333333,
    16.10372636310484,
    -5.049949500000014,
    102.1400686913304,
    0.26666666666666666,
    16.496500176839106,
    -5.173119000000014,
    104.63128987892382,
    0.8333333333333333,
    68.40837418363766,
    -5.070654500000011,
    77.53714225771441,
    0.5,
    70.0768711149459,
    -5.194329000000012,
    79.42829206887818,
    0.5,
    99.22070594285545,
    -5.112064500000007,
    -29.0954280557831,
    0.3833333333333333,
    90.65676012077176,
    -5.165897500000015,
    -49.72012643210333,
    0.3833333333333333,
    -64.82596879770173,
    -5.203166500000006,
    80.54778986919924,
    0.3833333333333333,
    -101.47459874616496,
    -5.269422500000004,
    19.813243571442612,
    0.26666666666666666,
    61.44043183312818,
    -5.273563500000008,
    -83.1546059408028,
    0.26666666666666666,
    71.97703537274647,
    -5.360524500000003,
    -74.2158795746254,
    0.26666666666666666,
    93.06692484626844,
    -5.393652500000013,
    -45.020902227390586,
    0.5,
    19.316546855129804,
    -5.418498500000014,
    101.56247592786949,
    0.5,
    -68.84668533152586,
    -5.430921500000004,
    77.1236969777947,
    0.5,
    81.2271307046211,
    -5.447485499999997,
    -63.95235542356267,
    0.26666666666666666,
    65.59894858133103,
    -5.501318500000006,
    -79.89955609880101,
    0.3833333333333333,
    67.19892293697325,
    -5.635497000000006,
    -81.84832575974737,
    0.6666666666666666,
    75.66406254803485,
    -5.5882795000000005,
    -70.43540584079109,
    0.26666666666666666,
    77.50952748823082,
    -5.724579,
    -72.15334256861527,
    0.5,
    95.23327574028552,
    -5.621407500000011,
    -40.20508169987586,
    0.5,
    97.55603856321932,
    -5.758515000000012,
    -41.185693448653325,
    0.8333333333333333,
    66.86242044889522,
    -5.6669585000000104,
    78.8335457662183,
    0.26666666666666666,
    55.750492795873384,
    -5.753919500000005,
    87.04137285454999,
    0.5,
    57.1102609128459,
    -5.894259000000005,
    89.1643331680756,
    0.5,
    -66.3648975311099,
    -5.799470500000004,
    79.24324666875351,
    0.26666666666666666,
    79.15244009091201,
    -5.816034499999999,
    -66.47172778971067,
    0.3833333333333333,
    -101.81024067070074,
    -5.865726500000002,
    17.823966230925944,
    0.5,
    -104.29341727242515,
    -6.008793000000002,
    18.258697114607067,
    0.5,
    63.03164337980559,
    -5.869867500000006,
    -81.9144810968943,
    0.3833333333333333,
    64.5690005354106,
    -6.013035000000006,
    -83.91239526998929,
    0.5,
    100.98292575138782,
    -5.936123500000003,
    -22.01446727682596,
    0.3833333333333333,
    73.38825025737461,
    -5.956828500000002,
    -72.77435361020078,
    0.5,
    93.89782145500547,
    -5.989956500000011,
    -43.18269528563522,
    0.26666666666666666,
    -70.31592568085283,
    -6.027225500000003,
    75.7414600692179,
    0.3833333333333333,
    -72.0309482584346,
    -6.1742310000000025,
    77.58881275383297,
    0.5,
    82.43306392824321,
    -6.0437895000000195,
    -62.33528860029331,
    0.3833333333333333,
    67.1237250229541,
    -6.0976225000000035,
    -78.5789422421194,
    0.26666666666666666,
    90.00465017876827,
    -6.130750500000014,
    -50.78388001624432,
    0.5,
    -63.8171140449673,
    -6.168019500000006,
    81.28196057809664,
    0.5,
    95.9679081769504,
    -6.217711500000009,
    -38.326574186107244,
    0.26666666666666666,
    60.401753731205474,
    -6.238416500000007,
    -83.84590586765302,
    0.5,
    61.87496723684463,
    -6.390573000000007,
    -85.89092796198602,
    0.8333333333333333,
    92.46813853760511,
    -6.358505500000012,
    -46.115489677512215,
    0.5,
    -67.87534137155681,
    -6.3957745000000035,
    77.90672453158184,
    0.26666666666666666,
    80.4069102989184,
    -6.41233850000002,
    -64.8924981499642,
    0.3833333333333333,
    64.59570373733223,
    -6.466171500000005,
    -80.64123827057338,
    0.26666666666666666,
    88.33740301319921,
    -6.499299500000016,
    -53.588132640472956,
    0.3833333333333333,
    74.768127405537,
    -6.5531325,
    -71.30364088675157,
    0.5,
    94.68926703575602,
    -6.586260500000011,
    -41.327829677560224,
    0.26666666666666666,
    96.99876135370128,
    -6.746901000000011,
    -42.33582552335437,
    0.6666666666666666,
    99.34921136572657,
    -6.673221500000006,
    -28.330689028333335,
    0.3833333333333333,
    56.76412061015781,
    -6.718772500000006,
    86.31406798690871,
    0.26666666666666666,
    90.94574484654441,
    -6.727054500000014,
    -49.00044751898974,
    0.3833333333333333,
    -65.36732041264334,
    -6.764323500000005,
    79.9923932299701,
    0.26666666666666666,
    78.30048986374833,
    -6.780887499999999,
    -67.38307262814148,
    0.26666666666666666,
    -101.52962727680494,
    -6.8305795000000025,
    19.039527140326925,
    0.3833333333333333,
    62.00360716018333,
    -6.834720500000007,
    -82.62121349757905,
    0.26666666666666666,
    72.46310629798367,
    -6.921681500000002,
    -73.61123674996688,
    0.26666666666666666,
    93.31562647950732,
    -6.954809500000012,
    -44.28600348367168,
    0.5,
    18.542221940390608,
    -6.979655500000013,
    101.6114956076064,
    0.26666666666666666,
    -69.35658666361999,
    -6.9920785000000025,
    76.53888129324604,
    0.5,
    -71.04821072858633,
    -7.162617000000003,
    78.40568327600813,
    0.6666666666666666,
    81.6271706043187,
    -7.008642500000019,
    -63.28751515457562,
    0.5,
    66.13187294645493,
    -7.062475500000005,
    -79.33550557856076,
    0.3833333333333333,
    67.74484545734407,
    -7.234731000000005,
    -81.27051790974515,
    0.5,
    76.11601760509517,
    -7.149436499999999,
    -69.80446294237436,
    0.26666666666666666,
    77.97250583936578,
    -7.3238129999999995,
    -71.50701081901762,
    0.5,
    95.44089946864399,
    -7.18256450000001,
    -39.45720594288867,
    0.3833333333333333,
    66.21053388458589,
    -7.228115500000009,
    79.25493785270899,
    0.26666666666666666,
    67.82542495494164,
    -7.404411000000009,
    81.18798511740921,
    0.6666666666666666,
    -66.88927114634042,
    -7.360627500000004,
    78.67065649476679,
    0.5,
    79.56886489267865,
    -7.37719150000002,
    -65.81639925020951,
    0.3833333333333333,
    63.57854629418077,
    -7.431024500000005,
    -81.3632223489158,
    0.3833333333333333,
    87.62938310494714,
    -7.464152500000015,
    -54.61504617871478,
    0.26666666666666666,
    101.04486762846412,
    -7.497280500000002,
    -21.24031864286609,
    0.3833333333333333,
    73.85579033716547,
    -7.517985500000001,
    -72.15419428345413,
    0.26666666666666666,
    94.12378893486033,
    -7.55111350000001,
    -42.43958843410224,
    0.3833333333333333,
    96.41949110400326,
    -7.73528700000001,
    -43.47470034712913,
    0.6666666666666666,
    -70.80793515366996,
    -7.588382500000002,
    75.14039123738551,
    0.5,
    82.81265095474923,
    -7.604946500000018,
    -61.65756446356771,
    0.26666666666666666,
    84.83247170974312,
    -7.790433000000019,
    -63.161407499264484,
    0.5,
    64.62429947148246,
    -7.824419500000007,
    80.49785091732682,
    0.3833333333333333,
    85.83893187199065,
    -7.832701500000016,
    -57.3389238413806,
    0.5,
    100.29735513692493,
    -7.865829500000004,
    -24.413006857298196,
    0.26666666666666666,
    71.52218419718315,
    -7.886534500000001,
    -74.42986877755806,
    0.5,
    88.63983916177533,
    -8.060456500000013,
    -52.871859995523195,
    0.26666666666666666,
    -61.75849869243644,
    -8.097725500000006,
    82.69063009787129,
    0.3833333333333333,
    -63.264803538593426,
    -8.295231000000006,
    84.70747473440474,
    0.8333333333333333,
    58.286030080393346,
    -8.168122500000006,
    -85.16716560560457,
    0.5,
    59.707640570159036,
    -8.367345000000007,
    -87.24441354720469,
    0.5,
    86.90181737303033,
    -8.429005500000015,
    -55.6277954672433,
    0.3833333333333333,
    72.9274128699935,
    -8.4828385,
    -72.98670788624752,
    0.5,
    89.61263811321437,
    -8.656760500000013,
    -51.1083282160131,
    0.26666666666666666,
    -63.33040956365831,
    -8.694029500000003,
    81.4315583821925,
    0.3833333333333333,
    -64.8750536993573,
    -8.906079000000004,
    83.41769395248988,
    0.6666666666666666,
    99.92003432662897,
    -8.830682500000002,
    -25.6013947227389,
    0.26666666666666666,
    70.56585715684525,
    -8.851387500000001,
    -75.22990341642449,
    0.5,
    20.95724226510286,
    -8.909361500000013,
    100.98932071909437,
    0.5,
    -101.84282950174922,
    -8.988040499999999,
    16.26895301019709,
    0.5,
    87.92766865268558,
    -9.025309500000015,
    -53.89521777238445,
    0.3833333333333333,
    90.07224593689742,
    -9.245439000000014,
    -55.20973527902797,
    0.6666666666666666,
    63.94176843403968,
    -9.385576500000006,
    80.8751310866962,
    0.26666666666666666,
    86.15498863131464,
    -9.393858500000015,
    -56.6259567814975,
    0.26666666666666666,
    100.34916297287816,
    -9.426986500000002,
    -23.634784559448782,
    0.5,
    102.79670353319226,
    -9.656913000000001,
    -24.21124271943534,
    0.8333333333333333,
    71.98336228708433,
    -9.4476915,
    -73.80082861436715,
    0.3833333333333333,
    88.9160154772993,
    -9.621613500000011,
    -52.14204033500501,
    0.5,
    91.08469878162366,
    -9.856287000000012,
    -53.41379741634659,
    0.8333333333333333,
    -62.2918876095646,
    -9.658882500000004,
    82.12096170825428,
    0.5,
    58.84185404660345,
    -9.729279500000004,
    -84.61916424645835,
    0.3833333333333333,
    99.51986938249203,
    -9.795535500000003,
    -26.78187273810333,
    0.26666666666666666,
    69.59450398298404,
    -9.816240500000001,
    -76.0109996172171,
    0.26666666666666666,
    22.154705051821153,
    -9.874214500000011,
    100.64340294860264,
    0.26666666666666666,
    -101.55187521756389,
    -9.952893499999998,
    17.480908894390666,
    0.3833333333333333,
    87.19601985328795,
    -9.990162500000013,
    -54.90424755853236,
    0.5,
    89.32275204483156,
    -10.233825000000014,
    -56.243375547764856,
    0.5,
    98.590668420389,
    -10.164084500000003,
    -29.899784475774577,
    0.5,
    48.7897618951515,
    -10.238622500000009,
    -90.73122598376436,
    0.26666666666666666,
    64.85426288978624,
    -10.350429500000004,
    80.02636327603561,
    0.26666666666666666,
    66.43607417978103,
    -10.602879000000005,
    81.97822579496331,
    0.5,
    97.56258801921474,
    -10.532633500000005,
    -32.98530999623396,
    0.26666666666666666,
    -61.240537609351804,
    -10.623735500000002,
    82.78984492281408,
    0.5,
    99.0970043587293,
    -10.760388500000001,
    -27.953951267308476,
    0.26666666666666666,
    68.60850905783408,
    -10.7810935,
    -76.7728215764235,
    0.5,
    50.52087927050601,
    -10.834926500000007,
    -89.70881088541165,
    0.3833333333333333,
    23.344808163165908,
    -10.839067500000011,
    100.27442431924216,
    0.26666666666666666,
    -101.23754688241381,
    -10.917746500000021,
    18.68656034675197,
    0.26666666666666666,
    98.12781603206973,
    -11.128937500000003,
    -31.05646624935569,
    0.5,
    52.22891368931617,
    -11.431230500000005,
    -88.65039858393098,
    0.26666666666666666,
    97.0603036143186,
    -11.497486500000003,
    -34.12522368393517,
    0.5,
    -60.176778085543255,
    -11.588588500000004,
    83.43791476793358,
    0.3833333333333333,
    56.67353464258536,
    -11.658985500000004,
    -85.84639860370653,
    0.26666666666666666,
    58.05581597533134,
    -11.943351000000005,
    -87.94021320379693,
    0.6666666666666666,
    98.6515908146524,
    -11.7252415,
    -29.117142141787678,
    0.3833333333333333,
    67.60826230555548,
    -11.745946499999999,
    -77.51503875921331,
    0.5,
    69.25724431300806,
    -12.032433,
    -79.40564946065754,
    0.8333333333333333,
    49.384408358931395,
    -11.799779500000007,
    -90.21846285428325,
    0.5,
    24.5270569189148,
    -11.803920500000011,
    99.88251380860413,
    0.3833333333333333,
    -100.89995061462511,
    -11.88259950000002,
    19.885407214558374,
    0.3833333333333333,
    64.15067755404587,
    -11.911586500000004,
    80.37555786064476,
    0.3833333333333333,
    65.71532822609576,
    -12.202113000000002,
    82.33593732066049,
    0.8333333333333333,
    97.64272782483886,
    -12.0937905,
    -32.203471230079366,
    0.26666666666666666,
    51.10045983257461,
    -12.396083500000007,
    -89.17682290685431,
    0.5,
    22.55807997584042,
    -12.400224500000009,
    100.27359116513773,
    0.3833333333333333,
    -101.19917252056499,
    -12.478903500000019,
    17.9005607060397,
    0.26666666666666666,
    55.57252086582584,
    -12.623838500000003,
    -86.42776898163733,
    0.5,
    98.18378779690323,
    -12.6900945,
    -30.270958696971384,
    0.26666666666666666,
    66.59415916347791,
    -12.71079950000002,
    -78.23732591532105,
    0.26666666666666666,
    68.21840694795299,
    -13.02081900000002,
    -80.14555337667035,
    0.8333333333333333,
    95.33300312672314,
    -12.830888500000004,
    -38.26764220775516,
    0.3833333333333333,
    59.896899916950254,
    -12.851593500000002,
    -83.45432013892152,
    0.5,
    61.35779991492465,
    -13.165047000000001,
    -85.48979136182204,
    0.5,
    65.04163176420037,
    -12.876439500000002,
    79.50603164699518,
    0.5,
    97.1355787495684,
    -13.0586435,
    -33.340317873804175,
    0.3833333333333333,
    -60.6859618927643,
    -13.1497455,
    82.83552285967167,
    0.5,
    -62.16610730478294,
    -13.470471,
    84.85590146600512,
    0.8333333333333333,
    94.03469483171907,
    -13.199437500000002,
    -41.23926093650392,
    0.26666666666666666,
    57.20651935320051,
    -13.220142500000001,
    -85.26469140723837,
    0.5,
    68.05797420356036,
    -13.307103500000018,
    -76.86649965197972,
    0.3833333333333333,
    23.737710275556655,
    -13.365077500000009,
    99.87653097346106,
    0.26666666666666666,
    24.316678818862915,
    -13.691055000000008,
    102.31254392403328,
    0.6666666666666666,
    95.99023771385063,
    -13.4271925,
    -36.37320978696257,
    0.26666666666666666,
    98.33146302394455,
    -13.754685,
    -37.26036124518117,
    0.8333333333333333,
    -100.85630799643124,
    -13.443756500000017,
    19.097020016162396,
    0.5,
    63.43065264835606,
    -13.4727435,
    80.69983340866824,
    0.5,
    97.69376182405992,
    -13.654947499999997,
    -31.414915795464072,
    0.5,
    65.56660055392268,
    -13.67565250000002,
    -78.93936309282961,
    0.26666666666666666,
    67.16578593328666,
    -14.00920500000002,
    -80.8647134121669,
    0.8333333333333333,
    94.74903693775593,
    -13.795741500000002,
    -39.36652309809618,
    0.5,
    58.814287192938814,
    -13.816446499999998,
    -84.06789549583807,
    0.5,
    96.60655117656655,
    -14.023496499999998,
    -34.46652637971971,
    0.3833333333333333,
    98.96280852233647,
    -14.365533,
    -35.307173364590916,
    0.5,
    -59.60809871712587,
    -14.114598499999998,
    83.45704464880825,
    0.26666666666666666,
    56.10361902999776,
    -14.1849955,
    -85.84052341057722,
    0.3833333333333333,
    67.04126661632338,
    -14.271956500000018,
    -77.58353854485594,
    0.3833333333333333,
    24.908808219960136,
    -14.329930500000007,
    99.4566739335597,
    0.3833333333333333,
    95.42284495753042,
    -14.3920455,
    -37.48033232959709,
    0.5,
    60.39507127355504,
    -14.412750499999998,
    -82.83799619671491,
    0.3833333333333333,
    64.31688767664487,
    -14.437596499999998,
    79.82680872922977,
    0.26666666666666666,
    97.18168686733382,
    -14.619800499999998,
    -32.54852985865375,
    0.26666666666666666,
    94.14396132589593,
    -14.7605945,
    -40.45324487448175,
    0.26666666666666666,
    57.72039313787849,
    -14.781299499999998,
    -84.65987849093601,
    0.5,
    68.4856449244959,
    -14.868260500000018,
    -76.19761737731704,
    0.26666666666666666,
    22.944410053021063,
    -14.926234500000005,
    99.84080927140882,
    0.3833333333333333,
    23.504029810411822,
    -15.290289000000005,
    102.27595096095537,
    0.8333333333333333,
    96.05583487745493,
    -14.9883495,
    -35.58161871333232,
    0.26666666666666666,
    -100.78276569313371,
    -15.004913500000017,
    18.30607372159371,
    0.26666666666666666,
    54.990192479303005,
    -15.1498485,
    -86.3943658262288,
    0.26666666666666666,
    -100.10120547390002,
    -15.373462500000015,
    21.466973201481846,
    0.5,
    -102.54269829033662,
    -15.748425000000015,
    21.99055791371311,
    0.5,
    84.33262719601359,
    -15.410731500000008,
    -58.03398116494419,
    0.3833333333333333,
    63.47274785637929,
    -15.605358500000017,
    -80.28143428369336,
    0.5,
    65.02086365775439,
    -15.985977000000018,
    -82.23951804671027,
    0.6666666666666666,
    -60.09517282396327,
    -15.67575550000002,
    82.82672586648711,
    0.5,
    -61.56090874649895,
    -16.058091000000022,
    84.84688991201119,
    0.5,
    56.615653925131824,
    -15.746152499999999,
    -85.229994937677,
    0.5,
    82.41335936479267,
    -15.77928050000001,
    -60.63396845095873,
    0.3833333333333333,
    24.112542296796708,
    -15.891087500000006,
    99.41571438687413,
    0.5,
    63.57591050311354,
    -15.998753499999998,
    80.122213618611,
    0.26666666666666666,
    53.86668494121251,
    -16.1147015,
    -86.92595857400738,
    0.3833333333333333,
    83.50417799107903,
    -16.375584500000006,
    -58.960309660970665,
    0.3833333333333333,
    62.40728276825674,
    -16.570211500000017,
    -80.92085499627468,
    0.3833333333333333,
    -59.00391050094185,
    -16.64060850000002,
    83.42094652631658,
    0.5,
    55.50051067541061,
    -16.7110055,
    -85.77797639806856,
    0.3833333333333333,
    -100.01689779033451,
    -16.934619500000014,
    20.670375995322292,
    0.26666666666666666,
    -102.4563343218061,
    -17.347659000000014,
    21.174531507403323,
    0.5,
    64.43934805576454,
    -16.963606499999997,
    79.22898523685124,
    0.26666666666666666,
    84.55813805385561,
    -16.971888500000006,
    -57.26518937896442,
    0.3833333333333333,
    52.73354643730124,
    -17.079554499999997,
    -87.43504745367571,
    0.5,
    63.912674918423484,
    -17.166515500000017,
    -79.61096881309874,
    0.5,
    65.47152064814112,
    -17.585211000000015,
    -81.55269975975969,
    0.8333333333333333,
    -60.56170728066579,
    -17.236912500000017,
    82.17416920004906,
    0.5,
    -62.03882209238935,
    -17.657325000000018,
    84.17841722931855,
    0.5,
    82.65810445307713,
    -17.340437500000004,
    -59.86963020207141,
    0.3833333333333333,
    23.312699056912596,
    -17.452244500000003,
    99.34466694087834,
    0.26666666666666666,
    61.330020157757666,
    -17.53506450000002,
    -81.53879914145092,
    0.5,
    62.825874307946876,
    -17.962749000000017,
    -83.5275503400229,
    0.6666666666666666,
    62.81907909820154,
    -17.55991050000002,
    80.39178110656516,
    0.26666666666666666,
    64.35125175913329,
    -17.988201000000018,
    82.35255625550577,
    0.8333333333333333,
    54.3754094239693,
    -17.675858500000018,
    -86.30356018881079,
    0.3833333333333333,
    26.42066043676315,
    -17.820793500000004,
    98.49768345557989,
    0.26666666666666666,
    27.065066788879324,
    -18.255447000000004,
    100.90006597888672,
    0.8333333333333333,
    -62.09115677353071,
    -17.833216500000017,
    80.89555157602275,
    0.5,
    -99.59949555803914,
    -17.899472500000012,
    21.839757182850793,
    0.5,
    -102.0287515472596,
    -18.336045000000013,
    22.372434187310567,
    0.8333333333333333,
    65.28450453706515,
    -17.92845950000002,
    78.31940648592007,
    0.26666666666666666,
    66.87680952577405,
    -18.36573900000002,
    80.22963591240593,
    0.6666666666666666,
    51.5912317357164,
    -18.0444075,
    -87.92138415064649,
    0.3833333333333333,
    52.84955446097777,
    -18.484515,
    -90.0658081543208,
    0.5,
    53.24080110552159,
    -18.64071150000002,
    -86.80648937387146,
    0.3833333333333333,
    -61.0071444615765,
    -18.798069500000015,
    81.4996106292386,
    0.26666666666666666,
    66.11099633271667,
    -18.893312500000018,
    77.39382747786068,
    0.5,
    50.440200336309,
    -19.00926050000002,
    -88.38472622730781,
    0.26666666666666666,
    25.613970019738456,
    -19.381950500000002,
    98.41590399749164,
    0.3833333333333333,
    -62.51694454078905,
    -19.394373500000015,
    80.20545833561704,
    0.3833333333333333,
    -99.47929989058923,
    -19.46062950000001,
    21.037547807240774,
    0.5,
    64.5169237786691,
    -19.489616500000018,
    78.58210368665584,
    0.3833333333333333,
    52.09714152154613,
    -19.605564500000018,
    -87.28651276640814,
    0.3833333333333333,
    26.74924383740957,
    -20.3468035,
    97.91787970773873,
    0.3833333333333333,
    27.4016644188098,
    -20.843067,
    100.30612067622016,
    0.6666666666666666,
    74.56388253136579,
    -20.375790500000008,
    -68.86566784943562,
    0.5,
    76.38251381261861,
    -20.872761000000008,
    -70.54531828478771,
    0.6666666666666666,
    65.33775815906407,
    -20.454469500000016,
    77.65318835193584,
    0.5,
    66.93136201660221,
    -20.953359000000017,
    79.54716855564159,
    0.5,
    62.179409100464625,
    -20.65737850000001,
    -80.15247608417843,
    0.26666666666666666,
    72.2995509194379,
    -20.74433950000001,
    -71.13278386620577,
    0.26666666666666666,
    74.0629546003998,
    -21.25029900000001,
    -72.867729814162,
    0.5,
    24.80389364656892,
    -20.9431075,
    98.30350315839787,
    0.26666666666666666,
    -62.92048799286653,
    -20.955530500000016,
    79.49404730672043,
    0.3833333333333333,
    69.9654968229058,
    -21.11288850000001,
    -73.32462627596901,
    0.26666666666666666,
    71.6719723551718,
    -21.62783700000001,
    -75.11303179489508,
    0.5,
    73.58380732580284,
    -21.340643500000006,
    -69.62345768090688,
    0.26666666666666666,
    -98.56506546663873,
    -21.39033550000001,
    23.34110198243988,
    0.3833333333333333,
    66.13935524433926,
    -21.419322500000018,
    76.70869531222708,
    0.26666666666666666,
    67.75251025029874,
    -21.94174500000002,
    78.57963910033018,
    0.5,
    61.08684801673086,
    -21.622231500000012,
    -80.73600020648304,
    0.26666666666666666,
    71.29410058021458,
    -21.709192500000007,
    -71.85602137925666,
    0.5,
    73.03298108217103,
    -22.238685000000007,
    -73.60860726655561,
    0.5,
    25.93526474791437,
    -21.9079605,
    97.79994342629257,
    0.3833333333333333,
    26.567832180790333,
    -22.442301,
    100.18530790010459,
    0.6666666666666666,
    74.83401488984065,
    -21.936947500000006,
    -68.08866407008844,
    0.3833333333333333,
    64.54838913750217,
    -22.015626500000014,
    77.88609166318521,
    0.5,
    66.12274009207539,
    -22.552593000000016,
    79.78575243545802,
    0.8333333333333333,
    72.58942858566691,
    -22.305496500000007,
    -70.36096438007974,
    0.5,
    -98.07405801782342,
    -22.35518850000001,
    24.477547181980267,
    0.5,
    -63.30120590685476,
    -22.51668750000001,
    78.76154988168605,
    0.3833333333333333,
    70.27504929037575,
    -22.674045500000005,
    -72.55846424021931,
    0.5,
    64.02420827358635,
    -22.81483950000001,
    -78.08847212316088,
    0.26666666666666666,
    75.0345149192181,
    -22.893518500000017,
    67.55097339437673,
    0.26666666666666666,
    65.3439035831723,
    -22.980479500000015,
    76.93827039557854,
    0.3833333333333333,
    77.23005701372868,
    -23.129555500000006,
    -64.9458819404944,
    0.26666666666666666,
    61.47605542781333,
    -23.18338850000001,
    -80.00406696971405,
    0.5,
    77.06516747418226,
    -23.262067499999997,
    65.09425322408796,
    0.3833333333333333,
    -97.56066103211943,
    -23.320041500000013,
    25.603099586069096,
    0.3833333333333333,
    -62.21112769679388,
    -23.481540500000012,
    79.34619380562664,
    0.26666666666666666,
    -63.728472274764464,
    -24.05426100000001,
    81.28146682527607,
    0.6666666666666666,
    75.07793754530454,
    -23.4981045,
    -67.29463577322066,
    0.3833333333333333,
    62.938638339385896,
    -23.77969250000001,
    -78.68087222183944,
    0.3833333333333333,
    75.67568832461373,
    -23.85837150000002,
    66.49356307917262,
    0.3833333333333333,
    23.17573533534225,
    -24.06542150000002,
    97.98554181456316,
    0.3833333333333333,
    -63.6585038160936,
    -24.07784450000001,
    78.00819137199768,
    0.26666666666666666,
    -65.21115025063247,
    -24.665109000000008,
    79.91083018594884,
    0.5,
    76.27000434238111,
    -24.0944085,
    -65.72496893607706,
    0.5,
    60.37090276305267,
    -24.14824150000001,
    -80.55831525691012,
    0.26666666666666666,
    61.84336380605395,
    -24.73722300000001,
    -82.52315221439574,
    0.5,
    77.6691193542615,
    -24.22692050000002,
    64.01554379070883,
    0.3833333333333333,
    79.56348811899957,
    -24.81782100000002,
    65.57689851731149,
    0.8333333333333333,
    70.55931213827155,
    -24.235202500000003,
    -71.77370030420582,
    0.3833333333333333,
    -97.02503675319358,
    -24.284894500000007,
    26.717255981206357,
    0.26666666666666666,
    64.53241991512705,
    -24.541636500000013,
    77.14078352466728,
    0.26666666666666666,
    77.42656769934416,
    -24.6907125,
    -64.13205871903082,
    0.3833333333333333,
    61.84211116413831,
    -24.74454550000001,
    -79.25078157192388,
    0.5,
    76.29488642436327,
    -24.82322450000002,
    65.42418097245167,
    0.3833333333333333,
    78.15573731276237,
    -25.42866900000002,
    67.01989270348707,
    0.5,
    -97.3866053161799,
    -24.881198500000007,
    24.783072674593782,
    0.5,
    -99.76188837267209,
    -25.48805700000001,
    25.387537861779,
    0.8333333333333333,
    24.29874215953135,
    -25.03027450000002,
    97.4704166038048,
    0.26666666666666666,
    -62.5663682941537,
    -25.04269750000001,
    78.58586695713048,
    0.26666666666666666,
    75.29508960345585,
    -25.0592615,
    -66.48389669447911,
    0.26666666666666666,
    69.52436582898314,
    -25.200055500000005,
    -72.4482255398615,
    0.26666666666666666,
    78.54711767138708,
    -25.2870165,
    -62.516739574661116,
    0.3833333333333333,
    65.3015746519895,
    -25.50648950000001,
    76.17446400439606,
    0.26666666666666666,
    66.89429598496486,
    -26.128599000000012,
    78.03237776060085,
    0.6666666666666666,
    81.59012014815498,
    -25.514771499999995,
    -58.390704350199634,
    0.5,
    60.73506395317959,
    -25.709398500000006,
    -79.79789759367964,
    0.3833333333333333,
    76.89178670370262,
    -25.788077500000018,
    64.34325000624612,
    0.3833333333333333,
    70.81769760109752,
    -25.796359500000005,
    -70.97060777551694,
    0.26666666666666666,
    -96.84510221267782,
    -25.84605150000001,
    25.89272917780846,
    0.26666666666666666,
    79.63116369921401,
    -25.883320500000004,
    -60.87985802220023,
    0.26666666666666666,
    81.5733872040729,
    -26.514621,
    -62.36473260810755,
    0.6666666666666666,
    73.46270101284824,
    -26.01583250000002,
    68.14567957125976,
    0.5,
    58.130512460601985,
    -26.077947500000015,
    -81.59785414001423,
    0.26666666666666666,
    63.70523183723656,
    -26.10279350000001,
    77.31567262243989,
    0.5,
    20.415175076090936,
    -26.222882500000022,
    98.04594170593575,
    0.26666666666666666,
    77.59533475645544,
    -26.2518695,
    -63.30267764311861,
    0.26666666666666666,
    80.67823489012326,
    -26.479624500000025,
    -59.222272215384415,
    0.5,
    -62.89754133362451,
    -26.60385450000001,
    77.80462611521132,
    0.3833333333333333,
    -64.43162770761535,
    -27.25272900000001,
    79.7022999228994,
    0.6666666666666666,
    59.61793965302404,
    -26.674251500000008,
    -80.32192230918736,
    0.3833333333333333,
    69.77999407284321,
    -26.761212500000006,
    -71.6380873399326,
    0.3833333333333333,
    78.69119417888693,
    -26.8481735,
    -61.67898477937689,
    0.26666666666666666,
    64.46758439376188,
    -27.067646500000013,
    76.34591475897207,
    0.3833333333333333,
    66.0399645009268,
    -27.727833000000015,
    78.20801024089822,
    0.8333333333333333,
    81.68788028959526,
    -27.07592850000002,
    -57.54485150433217,
    0.26666666666666666,
    21.540525342162354,
    -27.187735500000016,
    97.54106023293832,
    0.26666666666666666,
    61.07541545789125,
    -27.270555500000007,
    -79.016049346746,
    0.5,
    76.09414748846302,
    -27.34923450000002,
    64.64615777653026,
    0.26666666666666666,
    -96.63318799616324,
    -27.40720850000001,
    25.065863738074103,
    0.5,
    -98.9900950204599,
    -28.07567700000001,
    25.67722626827103,
    0.6666666666666666,
    79.75023656958288,
    -27.444477500000023,
    -60.034373877367585,
    0.5,
    -61.79271152482467,
    -27.568707500000006,
    78.35210778395974,
    0.3833333333333333,
    72.64816204004822,
    -27.576989500000018,
    68.40453075140407,
    0.26666666666666666,
    74.4200684312689,
    -28.249599000000018,
    70.0729339404627,
    0.6666666666666666,
    62.86266336395074,
    -27.663950500000013,
    77.46235874619462,
    0.26666666666666666,
    64.39589905575441,
    -28.338681000000015,
    79.35168456927254,
    0.5,
    82.65966914326152,
    -27.67223250000002,
    -55.84847599167303,
    0.5,
    68.72964287559999,
    -27.726065500000008,
    -72.28365726139064,
    0.5,
    -95.69596393385811,
    -27.77575750000001,
    28.075174212654836,
    0.26666666666666666,
    -98.03001183468392,
    -28.45321500000001,
    28.759934559304956,
    0.8333333333333333,
    19.60093308673784,
    -27.784039500000016,
    97.78177844665981,
    0.3833333333333333,
    20.079004625438763,
    -28.461699000000017,
    100.16669987218809,
    0.8333333333333333,
    62.502269719822664,
    -27.866859500000007,
    -77.68095034484193,
    0.3833333333333333,
    64.02671532274516,
    -28.54653900000001,
    -79.57560767032588,
    0.6666666666666666,
    55.853019246392314,
    -28.00765350000001,
    -82.54354736432197,
    0.3833333333333333,
    -94.66376968174873,
    -28.144306500000006,
    31.049868699801088,
    0.26666666666666666,
    -96.97264211301089,
    -28.83075300000001,
    31.807182570527946,
    0.8333333333333333,
    -63.204008857824086,
    -28.165011500000006,
    77.00266889209077,
    0.3833333333333333,
    -64.74557004947833,
    -28.851963000000005,
    78.88078276750761,
    0.6666666666666666,
    71.18049917098001,
    -28.173293500000018,
    69.69237903912426,
    0.5,
    76.66088563217227,
    -28.31408750000002,
    63.55192119938898,
    0.26666666666666666,
    63.61793814854334,
    -28.62880350000001,
    76.48905268655443,
    0.26666666666666666,
    81.75606474450196,
    -28.637085500000023,
    -56.68587863444027,
    0.26666666666666666,
    67.66705602376425,
    -28.690918500000002,
    -72.90697051519221,
    0.26666666666666666,
    -95.08868654314453,
    -28.740610500000006,
    29.146948804066817,
    0.3833333333333333,
    20.721650252357968,
    -28.748892500000018,
    97.27044780323773,
    0.5,
    -64.58371052025646,
    -28.761315500000006,
    75.62510622237284,
    0.5,
    61.39130733150187,
    -28.831712500000005,
    -78.21294882208716,
    0.5,
    75.27640924951271,
    -28.91039150000002,
    64.92362512072629,
    0.26666666666666666,
    71.81485502561442,
    -29.138146500000012,
    68.6368752289505,
    0.5,
    62.0050348497423,
    -29.22510750000001,
    77.58024471407451,
    0.26666666666666666,
    63.51735277290675,
    -29.93791500000001,
    79.47244580466169,
    0.6666666666666666,
    -95.47172431532177,
    -29.336914500000006,
    27.238225508995395,
    0.5,
    -97.80030295715888,
    -30.052449000000006,
    27.90257247262943,
    0.6666666666666666,
    62.795082511896396,
    -29.428016500000005,
    -76.86478440872548,
    0.3833333333333333,
    56.21061963130505,
    -29.56881050000001,
    -81.7525370326807,
    0.3833333333333333,
    64.35233288048474,
    -29.593656500000012,
    75.50111504341702,
    0.3833333333333333,
    -94.45985429472326,
    -29.705463500000008,
    30.20508218611261,
    0.26666666666666666,
    -63.48511534524555,
    -29.726168500000004,
    76.18018548750373,
    0.26666666666666666,
    -65.03353279269056,
    -30.451197000000004,
    78.038238792077,
    0.6666666666666666,
    70.33650124244922,
    -29.734450500000015,
    69.9003910678187,
    0.5,
    75.83529797965856,
    -29.875244500000015,
    63.8271335045027,
    0.5,
    77.68493939379658,
    -30.603909000000016,
    65.38389285827105,
    0.6666666666666666,
    -93.3545313120557,
    -30.07401250000001,
    33.13473827656188,
    0.3833333333333333,
    19.902671375802097,
    -30.310049500000016,
    96.9670572741814,
    0.5,
    20.388102384968,
    -31.049319000000015,
    99.33210745160046,
    0.5,
    68.83351373070829,
    -30.330754500000015,
    71.12958838588284,
    0.3833333333333333,
    55.05773103240363,
    -30.53366350000001,
    -82.18130731396444,
    0.3833333333333333,
    56.40060252099884,
    -31.27838700000001,
    -84.18572944357334,
    0.8333333333333333,
    65.50685478089254,
    -30.620624500000005,
    -74.08545712179316,
    0.3833333333333333,
    67.10458294628016,
    -31.367469000000007,
    -75.89241949061739,
    0.5,
    -93.8096642095368,
    -30.670316500000006,
    31.24906737302375,
    0.26666666666666666,
    82.71373156106357,
    -30.794546500000017,
    -54.106932477305065,
    0.3833333333333333,
    76.37080249577755,
    -30.840097500000017,
    62.72012864576593,
    0.3833333333333333,
    78.23350499567456,
    -31.592295000000018,
    64.24988788102851,
    0.5,
    63.062160777982506,
    -30.989173500000003,
    -76.02802528540894,
    0.5,
    64.60026226037232,
    -31.745007,
    -77.88236736554087,
    0.6666666666666666,
    56.54422616471592,
    -31.12996750000001,
    -80.93887345325729,
    0.26666666666666666,
    57.92335363214802,
    -31.88923500000001,
    -82.91299231797088,
    0.8333333333333333,
    4.267044035035207,
    -31.150672500000002,
    -98.63495102143466,
    0.5,
    4.371118279792163,
    -31.910445000000003,
    -101.04068153415258,
    0.8333333333333333,
    21.006616006709706,
    -31.274902500000017,
    96.42628367080285,
    0.26666666666666666,
    21.518972494678234,
    -32.03770500000002,
    98.7781442481395,
    0.6666666666666666,
    -63.740186217717365,
    -31.287325500000005,
    75.33735825596597,
    0.5,
    -65.29482490595437,
    -32.050431,
    77.1748547987944,
    0.5,
    69.4745137277075,
    -31.29560750000001,
    70.0806857736461,
    0.3833333333333333,
    59.49236065467589,
    -31.38256850000001,
    78.69700784068462,
    0.26666666666666666,
    74.98949241312133,
    -31.436401500000017,
    64.0761602575655,
    0.26666666666666666,
    64.410095779441,
    -31.585477500000003,
    -74.6399544331334,
    0.3833333333333333,
    -93.13832049710308,
    -31.635169500000007,
    32.27839727565751,
    0.3833333333333333,
    67.96201235723669,
    -31.89191150000001,
    71.28461602780067,
    0.3833333333333333,
    55.389904979683294,
    -32.094820500000004,
    -81.35912086800369,
    0.5,
    56.74087827187069,
    -32.877621000000005,
    -83.34348966966232,
    0.5,
    3.067592581667741,
    -32.115525500000004,
    -98.3697541046624,
    0.3833333333333333,
    65.72526448927815,
    -32.1817815,
    -73.2253246650412,
    0.26666666666666666,
    67.32831972072395,
    -32.966703,
    -75.01130819345684,
    0.5,
    -62.62895126291225,
    -32.2521785,
    75.8606424354019,
    0.5,
    -64.15648665956866,
    -33.038817,
    77.71090200699707,
    0.5,
    60.24586007596233,
    -32.34742150000001,
    77.72712712437598,
    0.5,
    61.71527129732726,
    -33.13638300000001,
    79.6229107127754,
    0.5,
    82.69425998137872,
    -32.35570350000001,
    -53.21835625188204,
    0.26666666666666666,
    75.51658395438135,
    -32.401254500000015,
    62.96689510915321,
    0.5,
    -92.44603451886263,
    -32.60002250000001,
    33.29256463141213,
    0.5,
    -94.70081584859099,
    -33.39514500000001,
    34.10457840290998,
    0.5,
    -63.96852621655121,
    -32.8484825,
    74.47436119987599,
    0.5,
    -65.52873417305246,
    -33.649665,
    76.29080903401929,
    0.8333333333333333,
    68.5947706860504,
    -32.85676450000001,
    70.23258564313078,
    0.5,
    83.54817666979531,
    -32.952007500000015,
    -51.49070791776524,
    0.26666666666666666,
    74.12364501256526,
    -32.99755850000001,
    64.2983048617169,
    0.5,
    54.227912431479865,
    -33.05967350000001,
    -81.75461532168778,
    0.26666666666666666,
    55.55054444200376,
    -33.866007,
    -83.74863032953382,
    0.6666666666666666,
    23.176563875439605,
    -33.20460850000001,
    95.27710368335822,
    0.3833333333333333,
    23.741845921182033,
    -34.014477000000014,
    97.6009354805133,
    0.5,
    56.93463171987448,
    -33.5400295,
    79.69278359463542,
    0.5,
    -91.73302474750247,
    -33.56487550000001,
    34.29106193082387,
    0.5,
    -93.97041559500254,
    -34.383531000000005,
    35.12742929499031,
    0.8333333333333333,
    72.7045646997898,
    -33.593862500000014,
    65.59667902527664,
    0.3833333333333333,
    -62.85497250580774,
    -33.8133355,
    74.98890850424876,
    0.26666666666666666,
    -64.38802061570549,
    -34.638051000000004,
    76.81790627264506,
    0.8333333333333333,
    82.64295152172714,
    -33.91686050000001,
    -52.318397936108454,
    0.5,
    84.65863326615951,
    -34.744101000000015,
    -53.594456422355,
    0.6666666666666666,
    53.0587278976888,
    -34.02452650000001,
    -82.12507908872794,
    0.3833333333333333,
    54.35284321226657,
    -34.85439300000001,
    -84.12812979820912,
    0.8333333333333333,
    67.69749924243169,
    -34.417921500000006,
    70.35538999210222,
    0.3833333333333333,
    57.693096187095634,
    -34.50488250000001,
    78.72957107089894,
    0.3833333333333333,
    59.10024487458577,
    -35.346465,
    80.64980451165256,
    0.5,
    83.46947532129215,
    -34.51316450000001,
    -50.58768418087685,
    0.3833333333333333,
    73.23792296752734,
    -34.55871550000001,
    64.4928480328834,
    0.5,
    22.341477591749296,
    -34.76576550000001,
    94.91904736783007,
    0.3833333333333333,
    51.88283666794971,
    -34.989379500000005,
    -82.4702383074757,
    0.26666666666666666,
    53.14827170863141,
    -35.84277900000001,
    -84.4817075344873,
    0.8333333333333333,
    56.023504135357435,
    -35.1011865,
    79.66617422524949,
    0.5,
    84.25644842087834,
    -35.10946850000001,
    -48.84262222841211,
    0.3833333333333333,
    86.31148374821683,
    -35.96579700000001,
    -50.03390569739777,
    0.6666666666666666,
    -91.47035388352032,
    -35.12603250000001,
    33.41798656760311,
    0.3833333333333333,
    86.5673116999092,
    -35.33722350000001,
    -44.43879842392016,
    0.26666666666666666,
    68.29848564153272,
    -35.38277450000001,
    69.2878182132725,
    0.3833333333333333,
    69.96430236449693,
    -36.24576900000001,
    70.97776499896209,
    0.6666666666666666,
    54.337068874955605,
    -35.69749050000002,
    80.56424605915913,
    0.5,
    55.6623632377594,
    -36.568161000000025,
    82.52922767035814,
    0.6666666666666666,
    85.0035647389563,
    -35.70577250000001,
    -47.084152502177496,
    0.26666666666666666,
    23.400384905338342,
    -35.73061850000001,
    94.30360816474345,
    0.5,
    50.70072996792883,
    -35.9542325,
    -82.78982287126303,
    0.5,
    51.93733313787832,
    -36.831165000000006,
    -84.80908684373286,
    0.5,
    -64.34211824773197,
    -35.97079650000002,
    72.68850833213226,
    0.5,
    83.35784432462627,
    -36.07432150000001,
    -49.674326798317104,
    0.5,
    -90.73020501340623,
    -36.0908855,
    34.393364288059445,
    0.5,
    72.33248391362191,
    -36.119872500000014,
    64.65904582090094,
    0.5,
    85.7105400345944,
    -36.302076500000005,
    -45.31322316682333,
    0.3833333333333333,
    87.80104101104793,
    -37.187493,
    -46.4184237318678,
    0.8333333333333333,
    21.506772771080684,
    -36.32692250000001,
    94.52639341290924,
    0.26666666666666666,
    55.099859929346096,
    -36.66234350000003,
    79.60718328049578,
    0.3833333333333333,
    84.1167905054745,
    -36.67062550000001,
    -47.927616313529136,
    0.5,
    86.37711209458092,
    -36.89838050000001,
    -43.530789651434326,
    0.26666666666666666,
    -63.223548412310585,
    -36.935649500000025,
    73.18446793664191,
    0.5,
    67.37467917923588,
    -36.943931500000005,
    69.3774037840733,
    0.3833333333333333,
    69.01796403726603,
    -37.845003000000005,
    71.06953558368484,
    0.5,
    72.8321742537261,
    -37.084725500000005,
    63.54229420680784,
    0.3833333333333333,
    74.60856874771942,
    -37.98923100000001,
    65.09210626063242,
    0.8333333333333333,
    87.00304091015903,
    -37.494684500000005,
    -41.73781416929561,
    0.5,
    -64.48585937025213,
    -37.531953500000014,
    71.76595315854101,
    0.3833333333333333,
    -90.42604555013176,
    -37.6520425,
    33.509395797950276,
    0.26666666666666666,
    -92.63155885623254,
    -38.570385,
    34.326698134485646,
    0.8333333333333333,
    25.474672784850352,
    -37.66032450000001,
    93.0062719931269,
    0.3833333333333333,
    26.096006267407677,
    -38.57886900000001,
    95.27471765149586,
    0.5,
    71.40747518419055,
    -37.68102950000001,
    64.79612742008074,
    0.5,
    73.14912092039032,
    -38.60007900000001,
    66.37652077179003,
    0.5,
    24.588124385375398,
    -37.710016500000016,
    -93.2244840145497,
    0.3833333333333333,
    48.31986497261033,
    -37.8839385,
    -83.35120561669713,
    0.3833333333333333,
    67.94257775066424,
    -37.90878450000001,
    68.29462505444702,
    0.26666666666666666,
    69.59971379336338,
    -38.833389000000004,
    69.96034761675061,
    0.6666666666666666,
    54.16401927575561,
    -38.22350050000002,
    79.51508442064356,
    0.3833333333333333,
    23.597440929687295,
    -38.256628500000005,
    93.25779743476095,
    0.5,
    69.95855109739644,
    -38.277333500000005,
    66.0156988411298,
    0.26666666666666666,
    86.15234046054121,
    -38.4595375,
    -42.61530046188915,
    0.3833333333333333,
    88.25361705713978,
    -39.397575,
    -43.65469803413035,
    0.5,
    -63.36455380894479,
    -38.49680650000002,
    72.25202305053396,
    0.5,
    -64.91003073111418,
    -39.43575300000002,
    74.01426751518115,
    0.8333333333333333,
    66.4333871511823,
    -38.5050885,
    69.43622150022489,
    0.5,
    -89.65851246457201,
    -38.61689550000002,
    34.46029816444223,
    0.26666666666666666,
    23.368896919653093,
    -38.674869500000014,
    -93.14276542446332,
    0.3833333333333333,
    54.896162548163915,
    -39.18835350000002,
    78.53731541404125,
    0.3833333333333333,
    56.23509334202157,
    -40.144167000000024,
    80.4528596924325,
    0.8333333333333333,
    -90.08639174975417,
    -39.21319950000002,
    32.62196547547405,
    0.3833333333333333,
    24.62031805461555,
    -39.22148150000001,
    92.59017741010507,
    0.5,
    70.46303295055384,
    -39.2421865,
    64.90329276013954,
    0.5,
    25.118303295815224,
    -39.27117350000001,
    -92.43522811391951,
    0.5,
    48.62047308282004,
    -39.4450955,
    -82.44731446929397,
    0.3833333333333333,
    66.9913604953181,
    -39.469941500000004,
    68.34988633474754,
    0.26666666666666666,
    87.30589697132486,
    -39.6521455,
    -39.023240976106024,
    0.3833333333333333,
    89.43530909257669,
    -40.619271000000005,
    -39.9750273413769,
    0.5,
    -65.80060967491394,
    -39.68941450000001,
    69.37186582510816,
    0.26666666666666666,
    -67.40550259381429,
    -40.657449000000014,
    71.06386255254982,
    0.5,
    45.92018297410687,
    -39.8136445,
    -83.80713652340214,
    0.5,
    55.60602398431477,
    -40.15320650000002,
    77.54428237738851,
    0.26666666666666666,
    56.962268471737076,
    -41.132553000000016,
    79.43560633781263,
    0.8333333333333333,
    -89.31145517641563,
    -40.17805250000002,
    33.56506661066445,
    0.3833333333333333,
    -66.96631427102724,
    -40.285718500000016,
    67.89697535894196,
    0.3833333333333333,
    -68.59963900934498,
    -41.26829700000002,
    69.55299914818444,
    0.5,
    86.4604982214808,
    -40.61699850000002,
    -39.90322424496935,
    0.26666666666666666,
    -64.68324053331044,
    -40.65426750000002,
    69.86225413732616,
    0.5,
    -66.26088054631802,
    -41.64583500000002,
    71.56621155530974,
    0.5,
    23.76617054880908,
    -40.782638500000004,
    92.13778356040065,
    0.26666666666666666,
    69.49928125194197,
    -40.803343500000004,
    64.97970983071926,
    0.3833333333333333,
    -68.09643738230018,
    -40.88202250000001,
    66.40000811105341,
    0.5,
    66.02241784646858,
    -41.031098500000006,
    68.37334950541478,
    0.3833333333333333,
    67.6327207207727,
    -42.031857,
    70.04099217627855,
    0.6666666666666666,
    56.29314783283648,
    -41.118059500000015,
    76.53634636579456,
    0.3833333333333333,
    -65.85593963945227,
    -41.250571500000014,
    68.40183616050233,
    0.3833333333333333,
    46.219428264683245,
    -41.374801500000025,
    -82.88133611809957,
    0.5,
    47.34673139309015,
    -42.38394300000002,
    -84.90283212098004,
    0.5,
    54.638952214324505,
    -41.71436350000002,
    77.40750870368217,
    0.3833333333333333,
    55.97160958540559,
    -42.73178700000002,
    79.29549672084515,
    0.8333333333333333,
    -88.92783366537644,
    -41.73920950000002,
    32.666564173451874,
    0.26666666666666666,
    -91.0968052181905,
    -42.75723900000003,
    33.46330964109704,
    0.6666666666666666,
    24.76595091325801,
    -41.74749150000001,
    91.43970829907279,
    0.26666666666666666,
    25.369998496508206,
    -42.76572300000001,
    93.66994508685505,
    0.6666666666666666,
    69.96791720921946,
    -41.76819650000001,
    63.85400494519663,
    0.26666666666666666,
    -66.9931940958585,
    -41.84687550000002,
    66.91917946838655,
    0.3833333333333333,
    -68.62717443965992,
    -42.867531000000014,
    68.55135457737158,
    0.8333333333333333,
    66.54514139780179,
    -41.995951500000025,
    67.27190973174622,
    0.26666666666666666,
    68.16819362701646,
    -43.02024300000002,
    68.91268801788638,
    0.5,
    86.13495561788531,
    -42.17815550000002,
    -38.976893723368306,
    0.5,
    88.23580819393129,
    -43.20689100000002,
    -39.92754966784071,
    0.5,
    52.969739564024664,
    -42.310667500000015,
    78.23963018332742,
    0.5,
    54.261684431439896,
    -43.342635000000016,
    80.14791384633541,
    0.5,
    45.0133243422721,
    -42.33965450000002,
    -83.05769027295449,
    0.5,
    68.51633088752831,
    -42.364500500000005,
    65.02451171747646,
    0.26666666666666666,
    -88.12469220499472,
    -42.70406250000001,
    33.58314003739127,
    0.3833333333333333,
    67.0425621417507,
    -42.96080450000002,
    66.15957801544894,
    0.3833333333333333,
    -69.15936390630509,
    -43.03948350000001,
    63.89061644035446,
    0.3833333333333333,
    -70.84617766011742,
    -44.08922700000001,
    65.44892415841188,
    0.8333333333333333,
    85.26328148811554,
    -43.14300850000002,
    -39.828121628408454,
    0.5,
    53.65950613039357,
    -43.27552050000001,
    77.23543456404147,
    0.5,
    -88.50689760052975,
    -43.300366500000024,
    31.76527920694253,
    0.26666666666666666,
    23.904901955083382,
    -43.308648500000004,
    90.94142209478197,
    0.3833333333333333,
    24.487948344231757,
    -44.364957000000004,
    93.15950556050836,
    0.8333333333333333,
    68.97370215251597,
    -43.329353500000025,
    63.895392335035,
    0.26666666666666666,
    65.5474765519535,
    -43.55710850000002,
    67.25869640269823,
    0.26666666666666666,
    85.77218357814453,
    -43.73931250000002,
    -38.04511389474887,
    0.5,
    45.28563581530208,
    -43.900811500000025,
    -82.09357808894246,
    0.26666666666666666,
    67.51427815664832,
    -43.92565750000003,
    65.03679330332493,
    0.5,
    69.16096786778608,
    -44.997015000000026,
    66.62305655462553,
    0.6666666666666666,
    -68.05915021323027,
    -44.00433650000001,
    64.41270112677874,
    0.26666666666666666,
    -69.7191294867237,
    -45.07761300000001,
    65.98374261767579,
    0.6666666666666666,
    42.592610094755166,
    -44.26936050000002,
    -83.32777994904937,
    0.3833333333333333,
    24.880120633782077,
    -44.2735015,
    90.21231782399755,
    0.5,
    50.30494093486099,
    -44.46812850000001,
    78.79989904972997,
    0.5,
    51.531890713760035,
    -45.552717000000015,
    80.72184780704045,
    0.8333333333333333,
    84.89271758288523,
    -44.704165500000016,
    -38.886883564270086,
    0.5,
    -88.04785368668942,
    -44.86152350000002,
    30.86170435063948,
    0.5,
    23.044904966294425,
    -44.8698055,
    90.40496963379076,
    0.3833333333333333,
    -70.09200497748826,
    -45.19694450000001,
    61.33329170279123,
    0.26666666666666666,
    -71.80156607450016,
    -46.29930900000001,
    62.82922564676174,
    0.6666666666666666,
    50.99661122107224,
    -45.43298150000001,
    77.79855693383692,
    0.26666666666666666,
    83.99440363026993,
    -45.66901850000002,
    -39.70776414055511,
    0.26666666666666666,
    42.86406319510323,
    -45.83051750000002,
    -82.3386991461997,
    0.3833333333333333,
    24.011837039349867,
    -45.83465850000002,
    89.66627787069162,
    0.5,
    24.597491601285228,
    -46.95257700000002,
    91.85326025778166,
    0.5,
    -68.9940214840601,
    -46.161797500000006,
    61.85740922497519,
    0.5,
    84.48362418527289,
    -46.26532250000001,
    -37.94025307628514,
    0.26666666666666666,
    86.5442003849137,
    -47.39374500000002,
    -38.865625102535994,
    0.8333333333333333,
    -87.54986144925653,
    -46.42268050000001,
    29.956336899075353,
    0.3833333333333333,
    47.61737412181077,
    -46.62558950000001,
    79.2216240083539,
    0.5,
    41.65030002470824,
    -46.79537050000002,
    -82.41887788983489,
    0.26666666666666666,
    24.960949826534275,
    -46.79951150000002,
    88.90546851638882,
    0.26666666666666666,
    83.57692481582043,
    -47.230175500000016,
    -38.75091980288851,
    0.3833333333333333,
    85.6153863966941,
    -48.382131000000015,
    -39.69606418832482,
    0.8333333333333333,
    -86.72020811248909,
    -47.38753350000002,
    30.845628518688955,
    0.3833333333333333,
    -88.83533513962297,
    -48.54332700000001,
    31.59796092158381,
    0.5,
    43.10786130028438,
    -47.39167450000002,
    -81.32138161148943,
    0.5,
    44.159272551510824,
    -48.547569000000024,
    -83.30482994347697,
    0.6666666666666666,
    48.31020090608625,
    -47.59044250000001,
    78.22211896943072,
    0.5,
    49.48849848916153,
    -48.75118500000001,
    80.12997552966074,
    0.6666666666666666,
    -68.85782514399911,
    -47.72295450000001,
    60.815665376853126,
    0.5,
    25.89165174978203,
    -47.76436450000002,
    88.12271828864078,
    0.5,
    26.523155450996224,
    -48.92934900000002,
    90.27205288104666,
    0.6666666666666666,
    -87.01202849247318,
    -47.98383750000001,
    29.049679196139646,
    0.26666666666666666,
    41.89371454536922,
    -48.35652750000002,
    -81.38788948444886,
    0.3833333333333333,
    42.915512461109934,
    -49.53595500000002,
    -83.37295995967933,
    0.6666666666666666,
    -67.74297554878451,
    -48.687807500000005,
    61.30099746039804,
    0.26666666666666666,
    83.1194691551316,
    -48.79133250000001,
    -37.78869335189523,
    0.3833333333333333,
    85.14677328086653,
    -49.98136500000001,
    -38.71036879950243,
    0.6666666666666666,
    -86.17247831600662,
    -48.94869050000001,
    29.92917145883895,
    0.5,
    47.29754430314471,
    -49.1515995,
    77.87482388091584,
    0.26666666666666666,
    25.00640414063696,
    -49.32552150000002,
    87.51627451913255,
    0.26666666666666666,
    82.18499255662881,
    -49.756185500000015,
    -38.566496184595955,
    0.5,
    84.18950457020513,
    -50.96975100000001,
    -39.50714243300074,
    0.8333333333333333,
    -69.58788697743323,
    -49.880415500000026,
    58.197042541381236,
    0.26666666666666666,
    -71.28515251346819,
    -51.09701100000002,
    59.616482603366144,
    0.6666666666666666,
    42.10828062152517,
    -49.91768450000002,
    -80.32772312382163,
    0.3833333333333333,
    47.95418518044862,
    -50.11645250000001,
    76.85156431390635,
    0.3833333333333333,
    25.90837670113097,
    -50.29037450000002,
    86.70097966206635,
    0.3833333333333333,
    82.62100557014921,
    -50.35248950000002,
    -36.82148645464709,
    0.3833333333333333,
    -85.58335348592863,
    -50.50984750000001,
    29.011558672869167,
    0.5,
    -68.47306212730564,
    -50.845268500000024,
    58.682399908942415,
    0.5,
    40.892589371096946,
    -50.88253750000002,
    -80.35041466655875,
    0.3833333333333333,
    24.12237714982192,
    -50.886678500000016,
    86.86819036263725,
    0.5,
    26.79033308745858,
    -51.25522750000002,
    85.86387675842654,
    0.5,
    44.58688965907127,
    -51.30906050000001,
    78.08210554369766,
    0.5,
    -84.71222646158401,
    -51.47470050000001,
    29.85999868313133,
    0.3833333333333333,
    42.29290258927682,
    -51.478841500000016,
    -79.23802681409865,
    0.3833333333333333,
    80.26049522915874,
    -51.68589150000001,
    -40.053054195908885,
    0.3833333333333333,
    -70.1750331144679,
    -52.03787650000002,
    55.54232405791174,
    0.3833333333333333,
    -84.9517696656113,
    -52.07100450000001,
    28.09329005371037,
    0.26666666666666666,
    -87.02376404769937,
    -53.341029000000006,
    28.778492250142328,
    0.6666666666666666,
    27.651649310752212,
    -52.22008050000002,
    85.00514753806792,
    0.26666666666666666,
    45.24301899529388,
    -52.273913500000006,
    77.05798352270837,
    0.5,
    46.34650726347178,
    -53.54888700000001,
    78.93744653545735,
    0.5,
    80.7139584317346,
    -52.28219550000001,
    -38.330856669007495,
    0.26666666666666666,
    41.076577540462644,
    -52.443694500000014,
    -79.24518477329097,
    0.26666666666666666,
    25.885715799906563,
    -52.81638450000002,
    85.19263390032394,
    0.3833333333333333,
    26.517074721855504,
    -54.10458900000002,
    87.27050301984404,
    0.8333333333333333,
    -69.05900345510608,
    -53.002729500000015,
    56.026693034101825,
    0.5,
    -70.7433693930355,
    -54.29547900000002,
    57.393197742250656,
    0.6666666666666666,
    -84.06923444196738,
    -53.035857500000006,
    28.930732181906798,
    0.5,
    79.73250639335706,
    -53.24704850000001,
    -39.050030413201,
    0.5,
    69.46271960840453,
    -53.30088150000002,
    -55.23922737445852,
    0.26666666666666666,
    64.60299822178958,
    -53.441675500000024,
    -60.727798951618105,
    0.26666666666666666,
    41.86745948033655,
    -53.46652150000003,
    78.14008280231351,
    0.5,
    72.08124057674678,
    -53.52863650000001,
    -51.54032844645074,
    0.26666666666666666,
    -69.85658211054542,
    -53.599033500000026,
    54.44838995506205,
    0.3833333333333333,
    -71.56040118641238,
    -54.906327000000026,
    55.77639946616112,
    0.8333333333333333,
    67.51472009251931,
    -53.66943050000002,
    -57.26063591713278,
    0.3833333333333333,
    69.16142058258076,
    -54.978441000000025,
    -58.65723679316041,
    0.5,
    70.24054907311765,
    -53.89718550000002,
    -53.6543035187816,
    0.3833333333333333,
    -83.16556567814695,
    -54.000710500000004,
    29.7462195233111,
    0.5,
    41.229235393709224,
    -54.004851500000015,
    -78.10922985353946,
    0.26666666666666666,
    65.50694884226596,
    -54.03797950000002,
    -59.212853756041234,
    0.26666666666666666,
    29.309827321693408,
    -54.14978650000001,
    83.22355597651982,
    0.5,
    -70.61457996371423,
    -54.195337500000015,
    52.85709143349924,
    0.26666666666666666,
    68.33678231878899,
    -54.265734500000015,
    -55.702242921537355,
    0.3833333333333333,
    42.52226927025129,
    -54.431374500000025,
    77.11360263370521,
    0.5,
    70.9786356154372,
    -54.49348950000002,
    -52.05687767513893,
    0.5,
    72.70982184996006,
    -55.82259900000002,
    -53.326557618435004,
    0.6666666666666666,
    -83.38190284165776,
    -54.5970145,
    28.000891257085573,
    0.5,
    66.37230263323036,
    -54.63428350000002,
    -57.682216796899446,
    0.3833333333333333,
    27.564713660738214,
    -54.74609050000002,
    83.42947776875582,
    0.5,
    79.16041987483823,
    -54.80820550000001,
    -38.04095372239086,
    0.5,
    69.1194653526846,
    -54.86203850000002,
    -54.13023061084764,
    0.5,
    70.80530597104277,
    -56.20013700000002,
    -55.45048013794148,
    0.5,
    40.01187067558567,
    -54.96970450000001,
    -78.06924757689733,
    0.26666666666666666,
    64.34953309440344,
    -55.00283250000002,
    -59.592378980111356,
    0.26666666666666666,
    -69.4959333259198,
    -55.16019050000002,
    53.339425007802795,
    0.3833333333333333,
    67.19867123502414,
    -55.23058750000002,
    -56.136854326297815,
    0.26666666666666666,
    -82.4657905076729,
    -55.561867500000005,
    28.804477097441783,
    0.3833333333333333,
    -84.47715125176249,
    -56.917035000000006,
    29.507025319330605,
    0.6666666666666666,
    41.34931577924151,
    -55.56600850000001,
    -76.94204578100646,
    0.5,
    39.145641854880104,
    -55.62398250000002,
    78.04496729199873,
    0.5,
    40.10041360743815,
    -56.980665000000016,
    79.94850307960846,
    0.6666666666666666,
    72.33437055475142,
    -55.68609750000002,
    -48.82850608679731,
    0.3833333333333333,
    28.37095410142383,
    -55.71094350000002,
    82.51548559340063,
    0.3833333333333333,
    60.1391029623803,
    -55.73993050000002,
    -63.196313719515224,
    0.26666666666666666,
    78.14974286604262,
    -55.77305850000001,
    -38.72220629725271,
    0.26666666666666666,
    35.05077308630687,
    -55.85173750000002,
    79.8088488156753,
    0.3833333333333333,
    74.6085762905354,
    -55.91385250000002,
    -44.995856114842425,
    0.26666666666666666,
    42.65527084544016,
    -56.162312500000006,
    -75.78751974272866,
    0.26666666666666666,
    37.450479889218904,
    -56.22028650000002,
    78.44849626936852,
    0.5,
    72.95146948914562,
    -56.28240150000001,
    -47.199576330376644,
    0.3833333333333333,
    74.73077362302722,
    -57.65514300000001,
    -48.35078550916632,
    0.8333333333333333,
    33.338979837170115,
    -56.448041500000016,
    80.12213588784962,
    0.5,
    75.13833145789016,
    -56.51015650000001,
    -43.33888534641542,
    0.5,
    39.79832225179352,
    -56.58883550000002,
    77.01443285317585,
    0.3833333333333333,
    71.22797203652365,
    -56.650950500000015,
    -49.342389808467445,
    0.3833333333333333,
    29.154133185099646,
    -56.67579650000001,
    81.5801215635038,
    0.5,
    58.94789506906987,
    -56.70478350000002,
    -63.459741727681575,
    0.26666666666666666,
    -69.09167554549242,
    -56.721347500000014,
    52.21546450143528,
    0.3833333333333333,
    73.527726931336,
    -56.87870550000001,
    -45.562175737723,
    0.26666666666666666,
    62.004263652546534,
    -56.93253850000002,
    -60.262616719252335,
    0.5,
    -81.71954640563392,
    -57.1230245,
    27.862186422279283,
    0.3833333333333333,
    41.43549878243201,
    -57.127165500000004,
    -75.74306587131711,
    0.26666666666666666,
    42.44612070395475,
    -58.520511000000006,
    -77.59045772183704,
    0.8333333333333333,
    38.11482686340226,
    -57.18513950000001,
    77.4244497464361,
    0.5,
    71.84998037452046,
    -57.24725450000001,
    -47.72586088687405,
    0.3833333333333333,
    27.431772210968127,
    -57.272100500000015,
    81.76080969928964,
    0.3833333333333333,
    77.5204565311099,
    -57.334215500000006,
    -37.693396997456425,
    0.26666666666666666,
    34.05167493496244,
    -57.412894500000014,
    79.13070582432282,
    0.3833333333333333,
    34.88220359191275,
    -58.813209000000015,
    81.0607230395502,
    0.8333333333333333,
    74.06292415683369,
    -57.47500950000001,
    -43.9173334039535,
    0.3833333333333333,
    75.8693369411467,
    -58.87683900000001,
    -44.988487877220656,
    0.8333333333333333,
    29.91357058352084,
    -57.640649500000016,
    80.62356631384439,
    0.5,
    76.07395352761098,
    -57.70276450000001,
    -40.00712672432779,
    0.26666666666666666,
    -81.86897201956343,
    -57.719328500000024,
    26.141464438111043,
    0.26666666666666666,
    36.42803555155818,
    -57.78144350000002,
    77.79285724223477,
    0.3833333333333333,
    37.316524223547404,
    -59.19074700000002,
    79.6902440042405,
    0.5,
    -70.42554712258278,
    -57.913955500000014,
    49.02694866936846,
    0.26666666666666666,
    32.35013332202067,
    -58.00919850000002,
    79.40923994369346,
    0.26666666666666666,
    28.2020630997299,
    -58.23695350000002,
    80.81167309836921,
    0.3833333333333333,
    76.47951375562974,
    -58.299068500000004,
    -38.33442594763547,
    0.3833333333333333,
    78.34486774966949,
    -59.720997000000004,
    -39.26941194635828,
    0.6666666666666666,
    34.73900101602164,
    -58.37774750000001,
    78.11956240427291,
    0.26666666666666666,
    30.648573977087228,
    -58.605502500000014,
    79.64600187638739,
    0.3833333333333333,
    56.54237920781703,
    -58.63448950000002,
    -63.893517821401936,
    0.26666666666666666,
    75.00937660988087,
    -58.66761750000001,
    -40.60947798815873,
    0.3833333333333333,
    41.48638317187252,
    -58.6883225,
    -74.51165303798169,
    0.26666666666666666,
    42.498246176064534,
    -60.119745,
    -76.32901042915198,
    0.6666666666666666,
    59.621377054223174,
    -58.86224450000002,
    -60.81079834684269,
    0.5,
    -69.29279905714257,
    -58.878808500000005,
    49.48959015234202,
    0.5,
    76.84340751674854,
    -58.8953725,
    -36.65857940920727,
    0.26666666666666666,
    28.948058119845765,
    -59.20180650000001,
    79.84097734388392,
    0.3833333333333333,
    75.42030833734198,
    -59.26392150000001,
    -38.948559970069496,
    0.3833333333333333,
    -81.04048596513378,
    -59.28048550000002,
    25.212879613785525,
    0.5,
    35.400266749793005,
    -59.34260050000002,
    77.08892595529461,
    0.3833333333333333,
    -69.90254308185646,
    -59.475112500000016,
    47.89333031645995,
    0.26666666666666666,
    -71.6074831570237,
    -60.925725000000014,
    49.06146032417849,
    0.8333333333333333,
    31.35843797324525,
    -59.57035550000001,
    78.64761114288054,
    0.3833333333333333,
    55.329039324300425,
    -59.59934250000001,
    -64.06279268044524,
    0.3833333333333333,
    56.67852808830775,
    -61.052985000000014,
    -65.62529981899269,
    0.6666666666666666,
    9.618546756086374,
    -59.98859650000002,
    -83.82289349134379,
    0.5,
    9.853145457454335,
    -61.45173300000002,
    -85.86735430820583,
    0.6666666666666666,
    -70.47127321335242,
    -60.071416500000005,
    46.28876965714864,
    0.26666666666666666,
    -80.08167746322374,
    -60.24533850000002,
    25.977870364920584,
    0.5,
    -82.03488910866822,
    -61.71473700000002,
    26.611476959186938,
    0.6666666666666666,
    41.50047653568115,
    -60.24947950000001,
    -73.24709067458214,
    0.26666666666666666,
    54.1093015221115,
    -60.56419550000002,
    -64.1995898524827,
    0.26666666666666666,
    42.70255719574699,
    -60.8457835,
    -72.05350695292412,
    0.5,
    43.744082981009115,
    -62.329827,
    -73.81090956153203,
    0.8333333333333333,
    8.536149127251445,
    -60.95344950000002,
    -83.24203731966362,
    0.3833333333333333,
    8.744347886452701,
    -62.440119000000024,
    -85.27233091282615,
    0.8333333333333333,
    52.88366686527684,
    -61.529048500000016,
    -64.3033404619625,
    0.3833333333333333,
    10.105609088053916,
    -61.549753500000016,
    -82.62523908618164,
    0.3833333333333333,
    -69.86157989421476,
    -61.63257350000001,
    45.14655206603651,
    0.3833333333333333,
    -79.18674775692347,
    -61.80649550000002,
    25.0368072782824,
    0.5,
    41.47618384523934,
    -61.81063650000002,
    -71.94857200876729,
    0.5,
    7.467637537810508,
    -61.91830250000002,
    -82.63040499793267,
    0.3833333333333333,
    7.649775038732716,
    -63.428505000000015,
    -84.64578072958956,
    0.8333333333333333,
    53.83961522599051,
    -62.12535250000001,
    -62.92187246155371,
    0.5,
    -70.35064544306705,
    -62.22887750000001,
    43.54054565383697,
    0.3833333333333333,
    42.64130262151243,
    -62.406940500000026,
    -70.74262303003793,
    0.26666666666666666,
    51.65264327043246,
    -62.49390150000002,
    -64.37345993098198,
    0.5,
    52.91246383800399,
    -64.01814300000002,
    -65.94354431954253,
    0.5,
    -68.70904227787025,
    -62.597426500000005,
    45.585692161411046,
    0.26666666666666666,
    -78.18873200593184,
    -62.771348500000016,
    25.762484736455697,
    0.26666666666666666,
    6.413766056308919,
    -62.883155500000015,
    -81.98779167862588,
    0.5,
    43.77163725146954,
    -63.00324450000002,
    -69.51302453352884,
    0.26666666666666666,
    44.83923816004197,
    -64.53990900000002,
    -71.20846415629782,
    0.5,
    52.607288900555176,
    -63.09020550000002,
    -63.00416454093564,
    0.3833333333333333,
    -69.20051247905997,
    -63.1937305,
    43.99167106542819,
    0.26666666666666666,
    -70.88832985659802,
    -64.735041,
    45.06463865238986,
    0.5,
    -78.23870551455806,
    -63.36765250000002,
    24.0971201815798,
    0.3833333333333333,
    41.411794140178536,
    -63.37179350000002,
    -70.61518759930352,
    0.5,
    50.41674553645693,
    -63.45875450000002,
    -64.40934615271978,
    0.26666666666666666,
    7.967527622568878,
    -63.47945950000002,
    -81.38981723883805,
    0.26666666666666666,
    48.18852488482159,
    -63.82730350000001,
    -65.7371053693712,
    0.3833333333333333,
    49.36385476006114,
    -65.38406700000002,
    -67.34044940277052,
    0.5,
    5.375304706843165,
    -63.84800850000001,
    -81.31398117723984,
    0.5,
    5.506409699692998,
    -65.40527700000001,
    -83.29724901083107,
    0.5,
    -71.56684343351532,
    -64.01778950000002,
    38.69541540669117,
    0.3833333333333333,
    -73.31237620018642,
    -65.57919900000002,
    39.639206026366566,
    0.8333333333333333,
    45.92537855664324,
    -64.19585250000003,
    -66.98639974821197,
    0.3833333333333333,
    47.04550974095161,
    -65.76160500000002,
    -68.62021437621713,
    0.8333333333333333,
    2.7957378245436155,
    -64.21655750000001,
    -81.15320830297227,
    0.5,
    -77.2226290860234,
    -64.33250550000001,
    24.807658457317583,
    0.26666666666666666,
    -79.1061078442191,
    -65.90159100000001,
    25.412723297739962,
    0.6666666666666666,
    -70.05786002905091,
    -64.38633850000001,
    40.788739717148985,
    0.3833333333333333,
    49.17649537139146,
    -64.42360750000002,
    -64.4103774610385,
    0.5,
    6.92066550970993,
    -64.44431250000001,
    -80.72459724461343,
    0.3833333333333333,
    7.089462229458953,
    -66.01612500000002,
    -82.69348986033572,
    0.8333333333333333,
    43.630088756679875,
    -64.56440150000002,
    -68.15628392915089,
    0.5,
    46.94753854982933,
    -64.79215650000002,
    -65.6911767682068,
    0.3833333333333333,
    4.353040687185545,
    -64.81286150000001,
    -80.6087442276427,
    0.26666666666666666,
    -70.42338379626561,
    -64.98264250000003,
    39.181996036458656,
    0.5,
    44.685369967665714,
    -65.16070550000002,
    -66.89339126247903,
    0.3833333333333333,
    -68.89520316414993,
    -65.3511915,
    41.232249217097404,
    0.26666666666666666,
    47.932421427807554,
    -65.38846050000001,
    -64.37591035866593,
    0.3833333333333333,
    5.889638957375036,
    -65.40916550000001,
    -80.0273631112845,
    0.5,
    7.404535586070331,
    -66.00546950000002,
    -79.40954900916631,
    0.5,
    -71.0274142665435,
    -66.17525050000002,
    35.96342960384978,
    0.26666666666666666,
    -72.75979022426408,
    -67.78928100000003,
    36.840586423455875,
    0.8333333333333333,
    4.875258717496312,
    -66.37401850000002,
    -79.2978382151691,
    0.5,
    -76.17487484979306,
    -66.48996650000002,
    22.223825535835175,
    0.3833333333333333,
    -78.03279862661726,
    -68.11167300000001,
    22.76587006109945,
    0.5,
    6.381010002908783,
    -66.97032250000001,
    -78.68725589692887,
    0.3833333333333333,
    -69.86860844579788,
    -67.14010350000002,
    36.44186714284912,
    0.3833333333333333,
    3.8783560864112894,
    -67.33887150000001,
    -78.53572667374692,
    0.3833333333333333,
    43.210884384866915,
    -67.6867155,
    -65.33569958682406,
    0.26666666666666666,
    44.26480839425391,
    -69.33761100000001,
    -66.92925323528318,
    0.5,
    -70.10788039040793,
    -67.73640750000003,
    34.84379185964403,
    0.5,
    -75.05434779723761,
    -68.05112350000002,
    21.29119752665498,
    0.5,
    -76.88494164595072,
    -69.710907,
    21.8104950273051,
    0.6666666666666666,
    40.95884639010952,
    -68.05526450000002,
    -66.3948755645076,
    0.3833333333333333,
    9.323316775180318,
    -68.16293050000002,
    -77.35836279912994,
    0.5,
    44.18264825267675,
    -68.28301950000002,
    -64.05270069515302,
    0.3833333333333333,
    45.26027381981521,
    -69.94845900000001,
    -65.61496168771774,
    0.5,
    41.96264860808508,
    -68.65156850000001,
    -65.14233561428529,
    0.26666666666666666,
    10.757879259287037,
    -68.7592345,
    -76.64112035856867,
    0.3833333333333333,
    11.020266558294038,
    -70.436289,
    -78.51041597707035,
    0.5,
    -73.97510705029678,
    -69.01597650000001,
    21.952315359628397,
    0.5,
    -75.77937795396257,
    -70.69929300000001,
    22.487737685472993,
    0.5,
    39.71645919389326,
    -69.02011750000001,
    -66.15324538060096,
    0.3833333333333333,
    40.685153320573576,
    -70.70353500000002,
    -67.76673917037171,
    0.5,
    8.297703619038808,
    -69.1277835,
    -76.61542460906757,
    0.5,
    8.500086634137315,
    -70.813827,
    -78.48409350197167,
    0.8333333333333333,
    42.92870567606542,
    -69.24787250000001,
    -63.869272801570936,
    0.5,
    43.975747277920675,
    -70.93684500000002,
    -65.42705994307266,
    0.8333333333333333,
    -69.12671945548374,
    -69.29756450000002,
    33.71601750935571,
    0.26666666666666666,
    9.722547581141459,
    -69.72408750000001,
    -75.90421144985818,
    0.3833333333333333,
    9.959682887998568,
    -71.42467500000001,
    -77.75553368034252,
    0.8333333333333333,
    43.856373855166616,
    -69.84417650000002,
    -62.576633868503215,
    0.3833333333333333,
    -69.28019492026891,
    -69.89386850000001,
    32.132963805323804,
    0.3833333333333333,
    41.67368780420131,
    -70.2127255,
    -63.646700995885155,
    0.3833333333333333,
    11.121577749738432,
    -70.32039150000001,
    -75.15835730405003,
    0.5,
    -72.76730771971889,
    -70.57713350000002,
    21.005065559214906,
    0.26666666666666666,
    8.704007333958192,
    -70.6889405,
    -75.13148189885423,
    0.26666666666666666,
    42.59476806666264,
    -70.80902950000001,
    -62.363392303621076,
    0.26666666666666666,
    12.493794647278836,
    -70.9166955,
    -74.37844459029716,
    0.26666666666666666,
    40.41816555470234,
    -71.17757850000001,
    -63.384144997538684,
    0.26666666666666666,
    10.092789089984866,
    -71.2852445,
    -74.39069262992717,
    0.5,
    -68.18996179009422,
    -71.45502550000002,
    31.011837527947872,
    0.3833333333333333,
    13.838203666889006,
    -71.5129995,
    -73.56507763053538,
    0.26666666666666666,
    11.454620214673596,
    -71.88154850000001,
    -73.61562528417276,
    0.3833333333333333,
    -68.25562511801623,
    -72.05132950000001,
    29.448279780511136,
    0.5,
    -69.92039646235808,
    -73.80867900000001,
    30.166530506865065,
    0.8333333333333333,
    -66.96701905199492,
    -72.41987850000001,
    31.435730981400525,
    0.26666666666666666,
    12.78848697868525,
    -72.4778525,
    -72.80687551862283,
    0.3833333333333333,
    -70.4795321785303,
    -72.50683950000001,
    22.199535909940746,
    0.3833333333333333,
    -67.03015185543704,
    -73.01618250000001,
    29.883464664655964,
    0.3833333333333333,
    14.093379425827532,
    -73.07415650000003,
    -71.96506050281042,
    0.3833333333333333,
    -68.25661218571511,
    -73.24393750000002,
    26.339820375715895,
    0.3833333333333333,
    17.694709735445528,
    -73.30191150000003,
    -70.93061851432351,
    0.26666666666666666,
    15.36829123127057,
    -73.67046050000002,
    -71.09081867125168,
    0.5,
    15.743127602764973,
    -75.46730100000002,
    -72.82474107786757,
    0.6666666666666666,
    13.048791895332645,
    -74.03900950000002,
    -71.17148113768047,
    0.5,
    -67.02511777884634,
    -74.2087905,
    26.797660798265262,
    0.3833333333333333,
    16.612219479679265,
    -74.26676450000002,
    -70.18480946515778,
    0.26666666666666666,
    14.312266453777132,
    -74.63531350000002,
    -70.30045969062222,
    0.3833333333333333,
    -68.95564839495388,
    -74.66430050000001,
    19.69229316959279,
    0.5,
    -70.63749347775763,
    -76.485381,
    20.172593002997495,
    0.6666666666666666,
    -67.73745277860023,
    -75.6291535,
    20.231022142747513,
    0.26666666666666666,
    -69.38958577320024,
    -77.47376700000001,
    20.724461707204767,
    0.5,
    -64.4853910892704,
    -76.1384965,
    27.60415388638699,
    0.26666666666666666,
    -66.05820550608186,
    -77.99553300000001,
    28.27742593239643,
    0.6666666666666666,
    -67.4993630404676,
    -76.2254575,
    18.733425822493015,
    0.26666666666666666,
    -67.21811878846272,
    -76.82176150000001,
    17.25013308868175,
    0.26666666666666666,
    -68.85758510037645,
    -78.695463,
    17.67086804206423,
    0.5,
    -63.176641919472495,
    -77.10334950000001,
    27.950152712634658,
    0.5,
    -63.09082901047354,
    -77.69965350000001,
    26.45253798317139,
    0.5,
    -65.95927077495638,
    -77.78661450000003,
    17.777593438493607,
    0.3833333333333333,
    -61.60722948305468,
    -79.2608105,
    25.292267974725515,
    0.26666666666666666,
    -63.10984483629992,
    -81.194001,
    25.909152559474915,
    0.5,
    -64.3294263403996,
    -79.34777150000002,
    16.825031640353117,
    0.5,
    -61.421266242485345,
    -79.85711450000001,
    23.826349739387666,
    0.5,
    -61.189289236434156,
    -80.45341850000003,
    22.37283977066513,
    0.3833333333333333,
    -61.62421216157056,
    -81.27747750000002,
    17.71591803161013,
    0.5,
    -63.127241726486915,
    -83.25985500000003,
    18.14801359335672,
    0.6666666666666666,
    -59.78384919398207,
    -81.41827150000002,
    22.67999264778303,
    0.5,
    -60.21634779301171,
    -82.24233050000002,
    18.10017009311843,
    0.3833333333333333,
    -59.799406015825554,
    -82.83863450000003,
    16.709796507733184,
    0.3833333333333333,
    -61.257928113772515,
    -84.85908900000003,
    17.11735252011692,
    0.6666666666666666,
    -58.03904731144053,
    -82.97942850000001,
    21.52461516482355,
    0.3833333333333333,
    -58.77003585386438,
    -83.20718350000003,
    18.44107168070279,
    0.3833333333333333,
    -56.870215953544054,
    -83.34797750000003,
    23.158558009011514,
    0.26666666666666666,
    -57.68555542063659,
    -83.57573250000002,
    20.122108684258333,
    0.5,
    -59.09252018699358,
    -85.61416500000001,
    20.61289182289878,
    0.8333333333333333,
    -57.2838334362549,
    -84.17203650000003,
    18.73649709230805,
    0.5,
    -55.359616738403076,
    -84.31283050000002,
    23.32563123809055,
    0.26666666666666666,
    -55.01848180809749,
    -84.90913450000002,
    21.92786270918694,
    0.26666666666666666,
    -53.81121461569682,
    -85.27768350000002,
    23.441320429046648,
    0.26666666666666666,
    -54.62700461515549,
    -85.50543850000001,
    20.545947987340323,
    0.26666666666666666,
    -55.9593705813788,
    -87.59093700000001,
    21.047068669958378,
    0.5,
    -53.44864320639301,
    -85.87398750000003,
    22.05281014395979,
    0.5,
    -39.91967577387231,
    -91.57614450000001,
    27.159802459223194,
    0.5,
    45.38087167945613,
    -92.50372850000002,
    -10.052975888313995,
    0.5,
    45.38529085608927,
    -92.73148350000001,
    -7.646761850444068,
    0.26666666666666666,
    -38.9702719382652,
    -92.76875250000002,
    24.345473699466176,
    0.26666666666666666,
    -39.75817170016923,
    -92.99650750000002,
    22.098936645510836,
    0.3833333333333333,
    44.38586492298232,
    -93.10003250000001,
    -8.927741513826978,
    0.5,
    45.46844699427458,
    -95.370765,
    -9.145491306847148,
    0.8333333333333333,
    44.317589688448216,
    -93.3277875,
    -6.580345702255312,
    0.3833333333333333,
    45.398506510117684,
    -95.604075,
    -6.740841938895685,
    0.5,
    -38.38729173761788,
    -93.36505650000002,
    22.952291449936258,
    0.5,
    43.336257237621346,
    -93.46858150000001,
    -10.149813102346595,
    0.3833333333333333,
    44.393239121465776,
    -95.74830300000002,
    -10.397369519477,
    0.8333333333333333,
    -39.08648493129163,
    -93.59281150000001,
    20.73783875531335,
    0.26666666666666666,
    44.110985201905336,
    -93.64250350000002,
    1.6517105587152678,
    0.3833333333333333,
    32.315813555088994,
    -93.71704150000002,
    -29.83672153169646,
    0.26666666666666666,
    42.2385251599433,
    -93.8371305,
    -11.310418066490934,
    0.5,
    43.26873309067363,
    -96.12584100000001,
    -11.586281921771201,
    0.8333333333333333,
    38.722037686165486,
    -93.89096350000001,
    -20.06281125547572,
    0.26666666666666666,
    33.42179924663066,
    -93.94479650000004,
    -27.834945135413882,
    0.5,
    -37.728167048654925,
    -93.96136050000001,
    21.57020558867776,
    0.5,
    30.791756415472786,
    -94.08559050000001,
    -30.285227787099423,
    0.26666666666666666,
    -38.33794051974234,
    -94.18911550000003,
    19.39171119402192,
    0.26666666666666666,
    37.36896309121768,
    -94.25951250000001,
    -20.883738308778675,
    0.26666666666666666,
    31.92442551533322,
    -94.31334550000003,
    -28.341657345920428,
    0.5,
    41.98161195058771,
    -94.52039550000003,
    -4.5677913211798025,
    0.26666666666666666,
    43.0055537054801,
    -96.82577100000003,
    -4.679200865598823,
    0.6666666666666666,
    32.93433937337686,
    -94.54110050000003,
    -26.35783054972721,
    0.3833333333333333,
    33.73761594345922,
    -96.84698100000003,
    -27.000704465574216,
    0.6666666666666666,
    42.02067344966949,
    -94.60735650000002,
    1.0661725555203814,
    0.5,
    41.30943916875486,
    -94.69431750000001,
    6.628883297519387,
    0.3833333333333333,
    38.369910242613024,
    -94.71502250000002,
    -16.560197033800957,
    0.26666666666666666,
    23.460102729021692,
    -94.73572750000001,
    -34.52812100287952,
    0.5,
    41.64742258003972,
    -94.74815050000002,
    -2.388680445128929,
    0.26666666666666666,
    42.66321337467484,
    -97.05908100000002,
    -2.4469409437906102,
    0.6666666666666666,
    33.819967151038156,
    -94.76885550000002,
    -24.34131208712494,
    0.26666666666666666,
    40.04520760385731,
    -94.8019835,
    -11.242370632108448,
    0.5,
    33.264344537985764,
    -94.81440650000002,
    24.92262681803284,
    0.5,
    27.72274884032525,
    -94.8226885,
    -30.94402312835569,
    0.26666666666666666,
    41.39651547054503,
    -94.83511150000001,
    3.1710816261157846,
    0.26666666666666666,
    38.67811413681923,
    -94.9427775,
    -14.397850964802876,
    0.3833333333333333,
    34.93519486505803,
    -94.95520050000003,
    21.91957305116178,
    0.5,
    35.78727278859603,
    -97.27118100000003,
    22.45419678411695,
    0.5,
    24.87836775722305,
    -94.9634825,
    -32.86988643437267,
    0.3833333333333333,
    25.485157214716295,
    -97.279665,
    -33.67159098155249,
    0.8333333333333333,
    41.19390299473897,
    -94.97590550000002,
    -0.25564724739600847,
    0.5,
    42.19863233607407,
    -97.29239100000002,
    -0.2618825461129843,
    0.5,
    34.580215216892775,
    -94.99661050000003,
    -22.299738430409057,
    0.3833333333333333,
    -37.900539495678075,
    -95.0131745,
    15.920785230920416,
    0.5,
    31.54595488767533,
    -95.04216150000002,
    26.25387385954055,
    0.3833333333333333,
    32.31536842152107,
    -97.36026300000002,
    26.894212246358613,
    0.5,
    28.897762608824703,
    -95.05044350000001,
    -29.116286364461367,
    0.26666666666666666,
    40.66066585630421,
    -95.06286650000001,
    5.2141432589784555,
    0.3833333333333333,
    41.65238941377505,
    -97.38147300000001,
    5.341317484807199,
    0.6666666666666666,
    37.06273777737405,
    -95.08357150000002,
    -17.403836480787643,
    0.5,
    40.707295550074946,
    -95.11669950000002,
    -3.627008440236043,
    0.3833333333333333,
    32.36204886006316,
    -95.13740450000002,
    -24.87966405686098,
    0.26666666666666666,
    33.151367124942745,
    -97.45782900000002,
    -25.48648513141857,
    0.5,
    38.859325305514254,
    -95.17053250000001,
    -12.245334028382448,
    0.26666666666666666,
    39.80711372759996,
    -97.49176500000002,
    -12.544000712001532,
    0.5,
    33.33765878043026,
    -95.18295550000002,
    23.371587757887067,
    0.5,
    26.184206251367556,
    -95.19123750000001,
    -31.151906381543263,
    0.3833333333333333,
    26.822845428230178,
    -97.51297500000001,
    -31.91170897621505,
    0.5,
    35.2144298802603,
    -95.22436550000002,
    -20.24079465659975,
    0.26666666666666666,
    36.073318413925186,
    -97.54691100000002,
    -20.734472575053402,
    0.5,
    39.81758883970479,
    -95.29062150000001,
    7.189067960170608,
    0.26666666666666666,
    37.40970466295552,
    -95.3113265,
    -15.286944195647427,
    0.3833333333333333,
    23.33145815597531,
    -95.33203150000001,
    -32.93740207422222,
    0.5,
    23.900518110999098,
    -97.65720300000001,
    -33.7407533443252,
    0.5,
    40.30558026392817,
    -95.34445450000001,
    -1.5234240005556219,
    0.26666666666666666,
    33.150078792582704,
    -95.36515950000002,
    -22.89070236111409,
    0.3833333333333333,
    33.95861729971887,
    -97.691139,
    -23.4490121747998,
    0.6666666666666666,
    -36.59480280881424,
    -95.38172350000002,
    16.750309088298845,
    0.3833333333333333,
    31.67761808717117,
    -95.41071050000002,
    24.713366003207902,
    0.26666666666666666,
    27.374767368006598,
    -95.4189925,
    -29.38134794104013,
    0.5,
    39.94353680564488,
    -95.43141550000003,
    3.8450524212873964,
    0.26666666666666666,
    -34.794531991060715,
    -95.5225175,
    19.550315061022474,
    0.3833333333333333,
    -35.64317911279391,
    -97.85233500000001,
    20.027152013730337,
    0.5,
    37.63051866457215,
    -95.53908150000001,
    -13.177769013442367,
    0.5,
    33.32884436307817,
    -95.55150450000002,
    21.82942386340481,
    0.5,
    34.14174300608008,
    -97.88202900000002,
    22.361848835682977,
    0.6666666666666666,
    24.64734073579388,
    -95.55978650000002,
    -31.27717096746589,
    0.5,
    25.24849538788641,
    -97.89051300000001,
    -32.040028795940664,
    0.8333333333333333,
    39.78816433025267,
    -95.57220950000003,
    0.5295049734560455,
    0.26666666666666666,
    33.8133324048736,
    -95.59291450000002,
    -20.88250162392453,
    0.3833333333333333,
    -36.89675058855675,
    -95.60947850000001,
    14.65888952014686,
    0.26666666666666666,
    34.719126677695215,
    -95.69229850000002,
    18.841228079003915,
    0.3833333333333333,
    21.793352952736633,
    -95.70058050000002,
    -32.92224603578046,
    0.5,
    22.32489814670582,
    -98.03474100000001,
    -33.72522764640925,
    0.6666666666666666,
    35.84244049029981,
    -95.83309249999999,
    15.77223720927881,
    0.3833333333333333,
    36.71664635591688,
    -98.170485,
    16.156925921700243,
    0.8333333333333333,
    18.83564022540088,
    -95.84137450000001,
    -34.308529788523536,
    0.5,
    28.19850827245963,
    -95.86622050000003,
    27.054343888799107,
    0.3833333333333333,
    28.886276766909866,
    -98.20442100000002,
    27.71420593486738,
    0.8333333333333333,
    38.26362848437123,
    -95.8869255,
    7.682309442313652,
    0.26666666666666666,
    36.69485348998366,
    -95.97388650000002,
    12.646203479058439,
    0.26666666666666666,
    37.589849916568625,
    -98.31471300000001,
    12.954647466352547,
    0.8333333333333333,
    15.797513496058446,
    -95.9821685,
    -35.43003700787589,
    0.26666666666666666,
    16.182818703279384,
    -98.32319700000001,
    -36.29418425197042,
    0.5,
    34.48805206018104,
    -96.06084750000002,
    17.329554745462453,
    0.26666666666666666,
    20.267510786687975,
    -96.06912950000002,
    -32.82340610941702,
    0.26666666666666666,
    26.394082273485157,
    -96.09397550000001,
    28.048634878473017,
    0.3833333333333333,
    35.47436040489081,
    -96.20164150000001,
    14.291240378966078,
    0.5,
    17.328444236071245,
    -96.20992350000002,
    -34.070533683544106,
    0.3833333333333333,
    28.35737424072747,
    -96.23476950000001,
    25.537307815296263,
    0.26666666666666666,
    33.0610410327579,
    -96.28860250000001,
    18.78558541614518,
    0.5,
    36.19306125082513,
    -96.34243550000001,
    11.208169530407607,
    0.26666666666666666,
    34.17396393043052,
    -96.42939650000002,
    15.841000755236891,
    0.5,
    35.00747524580687,
    -98.78133300000003,
    16.22736662731584,
    0.8333333333333333,
    18.75746198794702,
    -96.4376785,
    -32.639813270013626,
    0.3833333333333333,
    26.60613801248612,
    -96.46252450000001,
    26.54280339654117,
    0.5,
    36.64340453911449,
    -96.48322950000002,
    8.103885119689826,
    0.3833333333333333,
    -32.48458252004452,
    -96.48737050000001,
    18.771384005825038,
    0.5,
    35.024897354650975,
    -96.57019050000001,
    12.840580091641936,
    0.26666666666666666,
    -34.49004094430278,
    -96.5743315,
    14.186655560364592,
    0.5,
    15.844376738015681,
    -96.57847250000002,
    -33.74877776083999,
    0.26666666666666666,
    18.421364346866113,
    -96.6364465,
    32.240908306880044,
    0.3833333333333333,
    -35.85518817071774,
    -96.6612925,
    9.403490759918103,
    0.3833333333333333,
    24.8202444366336,
    -96.6902795,
    27.42938098677466,
    0.3833333333333333,
    35.612110681402584,
    -96.71098450000001,
    9.807582518454213,
    0.26666666666666666,
    12.87587866208509,
    -96.71926650000002,
    -34.59798926789295,
    0.5,
    20.811831199698354,
    -96.7772405,
    30.30608896443783,
    0.3833333333333333,
    -34.60814611819653,
    -96.80208650000002,
    12.208926918778502,
    0.26666666666666666,
    9.131096512475393,
    -96.81036850000002,
    35.5218419057073,
    0.26666666666666666,
    23.007315154464216,
    -96.91803450000002,
    28.195454652123765,
    0.3833333333333333,
    23.56846918262188,
    -99.28188900000002,
    28.88314866802922,
    0.6666666666666666,
    14.387156341119136,
    -96.9470215,
    -33.34232115037478,
    0.26666666666666666,
    14.738062593341553,
    -99.31158300000001,
    -34.15554849550587,
    0.8333333333333333,
    11.91014540499234,
    -96.95116250000002,
    34.29352929242671,
    0.26666666666666666,
    18.92043918864399,
    -97.0049955,
    30.813527126147093,
    0.26666666666666666,
    -34.60493865901149,
    -97.0298415,
    10.253472772233234,
    0.5,
    34.951622786832786,
    -97.07953350000001,
    8.448305450759067,
    0.26666666666666666,
    -31.46919828337213,
    -97.08367450000001,
    17.37455984996039,
    0.3833333333333333,
    11.464492323707619,
    -97.08781550000002,
    -34.05799645013788,
    0.5,
    11.744114087700488,
    -99.45581100000003,
    -34.88867929038514,
    0.5,
    21.17507179282834,
    -97.1457895,
    28.839860297689608,
    0.3833333333333333,
    21.69153695850708,
    -99.51519900000001,
    29.543271524462522,
    0.6666666666666666,
    9.971841080873132,
    -97.17891750000001,
    34.26727307484506,
    0.26666666666666666,
    23.231484187896772,
    -97.2865835,
    26.702891946847767,
    0.5,
    33.879266190316216,
    -97.3072885,
    10.045523987501209,
    0.3833333333333333,
    34.70558975593369,
    -99.680637,
    10.290536767684166,
    0.5,
    12.960606488006718,
    -97.3155705,
    -32.85008436400984,
    0.26666666666666666,
    19.33131644616416,
    -97.3735445,
    29.361857246515942,
    0.3833333333333333,
    19.802811969241333,
    -99.748509,
    30.078000106187062,
    0.5,
    -33.308273542948655,
    -97.3983905,
    11.024430443229893,
    0.5,
    -34.12067045863033,
    -99.77396100000001,
    11.293318990625744,
    0.5,
    3.418999846320719,
    -97.49363350000002,
    34.65151500322221,
    0.3833333333333333,
    -1.2235582236190359,
    -97.5805945,
    34.553669954144986,
    0.3833333333333333,
    7.213705885724889,
    -97.59715850000002,
    -33.766591849343214,
    0.3833333333333333,
    -33.211853975272966,
    -97.6261455,
    9.139698864731471,
    0.5,
    6.196905768384918,
    -97.63442750000002,
    33.86063423276353,
    0.26666666666666666,
    -5.7787786691775676,
    -97.66755550000002,
    33.83902990257068,
    0.5,
    -5.91972449037702,
    -100.04969100000002,
    34.66437209531631,
    0.8333333333333333,
    13.263660525490348,
    -97.68826050000001,
    31.599124907957233,
    0.5,
    1.6146497453973503,
    -97.7213885,
    34.137204935843144,
    0.26666666666666666,
    -2.9302976263117153,
    -97.80834950000003,
    33.79890159320382,
    0.5,
    8.76030391007844,
    -97.82491350000002,
    -32.72566269948673,
    0.3833333333333333,
    8.973969859104743,
    -100.21088700000001,
    -33.523849594596165,
    0.8333333333333333,
    15.64076850050057,
    -97.82905450000003,
    30.037777570082877,
    0.26666666666666666,
    4.375170860225816,
    -97.86218250000002,
    33.48547059965742,
    0.3833333333333333,
    11.410012686774023,
    -97.91601550000003,
    31.617892783855954,
    0.3833333333333333,
    -0.12812126717798894,
    -97.9491435,
    33.51677934344681,
    0.3833333333333333,
    -0.13124617613354964,
    -100.338147,
    34.33426176645771,
    0.5,
    5.955560156566619,
    -97.96570750000001,
    -32.934436115500056,
    0.26666666666666666,
    6.100817721360928,
    -100.35511500000001,
    -33.7377150451464,
    0.8333333333333333,
    7.038291296215556,
    -98.00297650000002,
    32.60835288342636,
    0.5,
    7.209956937586667,
    -100.39329300000001,
    33.403678563509935,
    0.6666666666666666,
    -4.561339632081114,
    -98.03610450000002,
    32.947564708577175,
    0.26666666666666666,
    13.809818007462196,
    -98.05680950000001,
    30.192327871190678,
    0.3833333333333333,
    14.146642836912493,
    -100.44843900000002,
    30.928726111951427,
    0.5,
    2.6070030977771874,
    -98.0899375,
    32.9998988043035,
    0.5,
    -1.8025123459124353,
    -98.17689850000001,
    32.79440768124238,
    0.26666666666666666,
    7.475855552663115,
    -98.19346250000002,
    -31.930880523668694,
    0.26666666666666666,
    16.050464644119042,
    -98.19760350000001,
    28.58389190723801,
    0.26666666666666666,
    -31.698444111151627,
    -98.22244950000004,
    8.0613075956783,
    0.26666666666666666,
    5.255180855981608,
    -98.2307315,
    32.25727961477578,
    0.26666666666666666,
    -6.11048506326803,
    -98.26385950000002,
    32.00471080100292,
    0.26666666666666666,
    11.999008019360328,
    -98.28456450000002,
    30.22571442657165,
    0.26666666666666666,
    -31.456590089739546,
    -98.34253850000002,
    -7.526864307406846,
    0.5,
    -32.174942753694815,
    -98.39637150000001,
    -0.5939355440154522,
    0.3833333333333333,
    -32.95969745500444,
    -100.79628300000002,
    -0.6084217767963169,
    0.8333333333333333,
    -31.39016935024176,
    -98.45020450000003,
    6.296040586854303,
    0.3833333333333333,
    -31.544367991027684,
    -98.4833325,
    -4.83856308639953,
    0.5,
    -7.571754223147074,
    -98.49161450000003,
    30.975733020666993,
    0.5,
    -31.39942099368143,
    -98.6241265,
    -2.187157601443463,
    0.26666666666666666,
    -32.16526053011269,
    -101.029593,
    -2.2405029087957424,
    0.6666666666666666,
    3.606611347416712,
    -98.70280550000003,
    -31.018932380368113,
    0.3833333333333333,
    3.6945774778415097,
    -101.11019100000003,
    -31.775491706718554,
    0.6666666666666666,
    -30.559201633271513,
    -98.71108750000002,
    -6.299367039980769,
    0.3833333333333333,
    -8.939390830967756,
    -98.71936950000001,
    29.86635233994395,
    0.3833333333333333,
    -31.028220624961918,
    -98.76492050000002,
    0.40697478713430146,
    0.5,
    -30.049770998102588,
    -98.81875350000003,
    7.020743882796495,
    0.26666666666666666,
    -30.529263169818293,
    -98.85188150000002,
    -3.700707934697107,
    0.5,
    -6.350076581997176,
    -98.86016350000003,
    30.062771415091365,
    0.26666666666666666,
    -6.504956498631254,
    -101.27138700000003,
    30.796009742288717,
    0.5,
    -30.274627852728152,
    -98.99267550000002,
    -1.1501003132389147,
    0.5,
    -26.103177389701518,
    -99.01338050000001,
    15.244678977400332,
    0.3833333333333333,
    -25.921121578008815,
    -99.11276450000003,
    -14.905736959493856,
    0.5,
    -29.80276302291535,
    -99.1334695,
    1.3327288143183393,
    0.5,
    -30.52965968201085,
    -101.551359,
    1.3652343951553718,
    0.6666666666666666,
    -28.346347168143932,
    -99.1665975,
    -8.938465611952118,
    0.26666666666666666,
    -23.47229616313712,
    -99.19972550000003,
    -18.05246239587898,
    0.26666666666666666,
    -26.63726642418777,
    -99.25355850000003,
    -12.514503087774255,
    0.26666666666666666,
    -29.122868346833993,
    -99.27426350000003,
    3.728910133743811,
    0.3833333333333333,
    -29.833182208951897,
    -101.69558700000003,
    3.8198591613960993,
    0.6666666666666666,
    3.9480107024604814,
    -99.29910950000001,
    -29.009062187534823,
    0.5,
    -28.525419376879917,
    -99.30739150000001,
    -6.462821971774321,
    0.3833333333333333,
    -8.92333972284819,
    -99.31567350000003,
    27.824389129539455,
    0.5,
    -9.14098215511278,
    -101.73800700000002,
    28.503032766845294,
    0.6666666666666666,
    -24.48282068080273,
    -99.34051950000001,
    -15.794882145232169,
    0.5,
    -25.079962648627188,
    -101.76345900000003,
    -16.18012317316466,
    0.8333333333333333,
    -27.130649389348623,
    -99.39435250000002,
    -10.105255059521,
    0.5,
    -25.275984533386435,
    -99.48131350000001,
    -13.491423022895809,
    0.5,
    -25.892471961030004,
    -101.90768700000001,
    -13.82048212101522,
    0.6666666666666666,
    -28.49619151653508,
    -99.50201850000002,
    2.1773856981725817,
    0.26666666666666666,
    5.278946455328151,
    -99.52686450000003,
    -27.999314136607214,
    0.26666666666666666,
    -23.004768308691848,
    -99.56827450000002,
    -16.563966105859215,
    0.5,
    -23.565860218659942,
    -101.99676900000001,
    -16.967965279172855,
    0.8333333333333333,
    -24.625670973476314,
    -99.60968450000001,
    13.744551935823255,
    0.5,
    -25.226297094780616,
    -102.03918900000001,
    14.079784909867724,
    0.6666666666666666,
    -25.85058479864488,
    -99.62210750000003,
    -11.162821678523228,
    0.26666666666666666,
    -27.730534402850036,
    -99.64281250000002,
    4.444435152208874,
    0.3833333333333333,
    -20.367399251110324,
    -99.65523550000002,
    -19.27248583305764,
    0.3833333333333333,
    2.8977682194643064,
    -99.66765850000003,
    -27.845761140138276,
    0.26666666666666666,
    2.968445493109777,
    -102.09857700000003,
    -28.524926045995308,
    0.6666666666666666,
    -23.865951739833246,
    -99.70906850000003,
    -14.350736267322771,
    0.3833333333333333,
    -27.765249786697414,
    -99.72977350000002,
    0.699147281331043,
    0.26666666666666666,
    -26.20733543115267,
    -99.76290150000001,
    -8.829760976399417,
    0.26666666666666666,
    -18.788963368414723,
    -99.8829905,
    -19.69235103580264,
    0.5,
    -19.24723076764435,
    -102.31916100000001,
    -20.172652280578315,
    0.8333333333333333,
    -15.806514268583784,
    -99.96995150000001,
    -21.76208925550815,
    0.26666666666666666,
    -23.034250554890956,
    -99.97823350000002,
    13.827572187263392,
    0.26666666666666666,
    -24.94546512555506,
    -99.99065650000001,
    -9.849771945010625,
    0.3833333333333333,
    -17.204260856928673,
    -100.1107455,
    -19.98193356011825,
    0.3833333333333333,
    5.380285949480292,
    -100.12316850000002,
    -25.764884583914373,
    0.5,
    -25.165678961209476,
    -100.1314505,
    -7.603081492880804,
    0.26666666666666666,
    -9.762361631039614,
    -100.13973250000001,
    24.375313233130896,
    0.5,
    -10.000468012284482,
    -102.582165,
    24.969833068085308,
    0.8333333333333333,
    -18.410078730891524,
    -100.25153950000002,
    -18.114178259096168,
    0.3833333333333333,
    -18.859105041401076,
    -102.69669900000001,
    -18.55598748492778,
    0.8333333333333333,
    -15.621843456249499,
    -100.33850050000001,
    -20.138742498972796,
    0.5,
    -19.418106977644182,
    -100.3923335,
    -16.178446180939044,
    0.5,
    -19.891719342952577,
    -102.84092700000001,
    -16.57304242925463,
    0.5,
    -12.623757815257939,
    -100.42546150000001,
    -21.743804775929313,
    0.5,
    -19.743402800024125,
    -100.48757650000002,
    15.161485416430802,
    0.26666666666666666,
    -11.360016384002863,
    -100.59524250000001,
    21.65578073220586,
    0.26666666666666666,
    -11.637089954344397,
    -103.04878500000001,
    22.183970506162098,
    0.8333333333333333,
    -11.072682553620837,
    -100.65321650000003,
    -21.534886446667514,
    0.26666666666666666,
    -19.719610649664034,
    -100.7153315,
    13.600903707870351,
    0.26666666666666666,
    5.349456520163778,
    -100.71947250000002,
    -23.33231234277085,
    0.3833333333333333,
    5.4799310694360654,
    -103.17604500000002,
    -23.90139313161892,
    0.8333333333333333,
    -12.499179693422647,
    -100.79401050000003,
    -20.044040992709405,
    0.3833333333333333,
    -9.986021409404525,
    -100.96379150000001,
    20.591692678248663,
    0.5,
    -12.428839860151898,
    -101.0507525,
    18.7535325716101,
    0.26666666666666666,
    4.3006466561930115,
    -101.08802150000002,
    -21.912142126093226,
    0.3833333333333333,
    -8.097957814998459,
    -101.10872650000003,
    -20.709276418424157,
    0.26666666666666666,
    -8.295468981217933,
    -103.57479300000003,
    -21.214380721312548,
    0.6666666666666666,
    -14.558924209518507,
    -101.13771350000002,
    16.63208522264748,
    0.3833333333333333,
    -14.914019921945787,
    -103.60448700000002,
    17.037745837834002,
    0.8333333333333333,
    -0.49908616136959943,
    -101.14185450000004,
    -22.079443070520437,
    0.26666666666666666,
    -16.344208536495934,
    -101.2246745,
    14.277876056493112,
    0.26666666666666666,
    -16.742847769093395,
    -103.69356900000001,
    14.626116935919773,
    0.8333333333333333,
    2.4050007102104036,
    -101.22881550000002,
    -21.549211332455812,
    0.26666666666666666,
    -9.494219332229834,
    -101.24952050000002,
    -19.3860780662719,
    0.5,
    -12.747124144689115,
    -101.27850750000003,
    17.251097635000544,
    0.3833333333333333,
    -6.694104233694836,
    -101.33648150000002,
    -20.088108689186818,
    0.3833333333333333,
    -14.644883570839678,
    -101.36546850000002,
    15.099496878109985,
    0.3833333333333333,
    0.5985491336817865,
    -101.36960950000002,
    -21.006418870242314,
    0.5,
    -10.923845707359447,
    -101.41930150000002,
    17.66976239834311,
    0.26666666666666666,
    -16.194815181924366,
    -101.45242950000002,
    12.749828813393444,
    0.5,
    -16.589810674166422,
    -103.92687900000001,
    13.060800247866455,
    0.8333333333333333,
    3.325873174902921,
    -101.45657050000001,
    -20.320642091306723,
    0.26666666666666666,
    -8.060197912569064,
    -101.47727550000002,
    -18.8367033131414,
    0.26666666666666666,
    -12.910162115782715,
    -101.50626250000002,
    15.723613211513987,
    0.3833333333333333,
    -2.674597305223473,
    -101.65119750000001,
    -19.424371317262302,
    0.3833333333333333,
    -2.73983138583868,
    -104.13049500000001,
    -19.898136471341868,
    0.5,
    -4.1071256735804536,
    -101.79199150000001,
    -18.4105298719331,
    0.26666666666666666,
    -1.574738478348459,
    -101.87895250000001,
    -18.320067173117863,
    0.5,
    -0.7364786812122947,
    -103.4939425,
    -2.426332615040727,
    0.5,
    2.031370937344226,
    -103.49808350000002,
    1.2024429058947423,
    0.26666666666666666,
    -2.1250117491697686,
    -103.50222450000001,
    0.4466246942179791,
    0.5,
    1.1444522219278335,
    -103.50636550000003,
    -1.5962941746834638,
    0.26666666666666666,
    1.1723656907553417,
    -106.03091100000003,
    -1.6352281789440362,
    0.5,
    0.20672147059699653,
    -103.5105065,
    1.7198649211640646,
    0.26666666666666666,
    -1.1106976506280042,
    -103.51464750000001,
    -0.9537969803003298,
    0.26666666666666666,
    1.1334588760252062,
    -103.51878850000001,
    -0.036379508302479664,
    0.3833333333333333,
    1.1611042144648454,
    -106.04363700000002,
    -0.03726681338302795,
    0.8333333333333333,
    -0.46835312433467124,
    -103.52292950000002,
    0.45753479534459074,
    0.26666666666666666
]